export default {
  activateButton: 'Activate',
  archiveButton: 'Archive',
  exportButton: 'Export',
  editProjectButton: 'Edit Project',
  addResourceUsageButton: 'Add Resource',
  addResourceUsageModalTitle: 'Add Resource - {{ projectName }}',
  updateProjectModalTitle: 'Edit Project',
  info: {
    titles: {
      hoursBlocks: {
        monthlyBudget: 'Monthly Budget',
        monthlyPlanned: 'Monthly Planned',
        vacancyIncluded: 'Vacancy Included',
        totalBudget: 'Total Budget',
        totalProjectBudget: 'Total Project Budget',
      },
      generalProjectInfo: {
        productDirector: 'Product Director',
        teamLead: 'Team Lead',
        projectManager: 'Project Manager',
      },
    },
    hoursText: 'hours',
    hours_one: '{{ count }} hour',
    hours_other: '{{ count }} hours',
    updateBudgetFormTitle: 'Change Budgeted',
    updateBudgetForm: {
      input: {
        labelForMonthBudget: 'Monthly amount',
        labelForTotalBudget: 'Total amount',
      },
      buttons: {
        changeAmount: 'Change Amount',
        cancel: 'Cancel',
      },
      notAvailable: 'You do not have permission to perform this action',
      warningForTimebound: 'You cannot change the budget of timebound projects',
    },
  },
  filters: {
    plannedResources_one: '{{ count }} planned resource',
    plannedResources_other: '{{ count }} planned resources',
    vacancies_one: '{{ count }} vacancy',
    vacancies_other: '{{ count }} vacancies',
  },
  table: {
    headers: {
      mainColumnHeader: 'Resources/Total',
      plan: 'Plan',
      actual: 'Actual',
      allocation: 'Allocation',
    },
  },
  updateProjectForm: {
    buttons: {
      saveChanges: 'Save Changes',
      cancel: 'Cancel',
    },
    fields: {
      name: {
        label: 'Project Name',
      },
      description: {
        label: 'Project Description',
      },
      periodType: {
        label: 'Project Type',
        values: {
          onGoing: 'Ongoing',
          timebound: 'Timebound',
        },
      },
      billable: {
        label: 'Billable',
      },
      taskRequired: {
        label: 'Task Required',
      },
      groups: {
        label: 'Hierarchy',
      },
      technologies: {
        label: 'Project Stack',
      },
    },
  },
  updatePlannedHoursForm: {
    title: 'Planned Hours Update',
    period: 'Period',
    buttons: {
      update: 'Update',
      cancel: 'Cancel',
    },
    fields: {
      plannedHours: {
        label: 'Planed Hours',
      },
      startDate: {
        label: 'Start Date',
      },
      endDate: {
        label: 'End Date',
      },
    },
  },
  tabs: {
    dashboard: 'Dashboard',
    overview: 'Overview',
    planning: 'Planning',
    budgets: 'Budgets',
    tasks: 'Tasks',
    notes: 'Notes',
    actionItems: 'Action items',
    activities: 'Activities',
    allocationChart: 'Allocation Chart',
  },
  projectTasks: {
    createModalTitle: 'Create',
    createProjectTaskForm: {
      fields: {
        name: {
          label: 'Name',
        },
      },
      buttons: {
        create: 'Create',
        cancel: 'Cancel',
      },
    },
    addTaskButton: 'Add Task',
    tasksList: {
      noTasks: 'No Tasks',
    },
    task: {
      buttons: {
        enable: 'Enable',
        disable: 'Disable',
        rename: 'Rename',
      },
      renameModalTitle: 'Rename',
      updateProjectTaskForm: {
        fields: {
          name: {
            label: 'Name',
          },
        },
        buttons: {
          save: 'Save',
          cancel: 'Cancel',
        },
      },
      disabled: 'Disabled',
    },
  },
  projectBudgetMonths: {
    table: {
      headers: {
        startDate: 'Start Date',
        budgetHours: 'Budget, hrs',
      },
      actions: {
        buttons: {
          edit: 'Edit',
        },
        editModalTitle: 'Change Budget',
        updateProjectMonthBudgetForm: {
          buttons: {
            save: 'Save',
            cancel: 'Cancel',
          },
        },
      },
    },
  },
  vacancy: 'Vacancy',
  exportTitle: 'Hours by Project report',
  createResourceUsageAndVacancyButton: 'Allocate',
};
