import { SxStyles } from 'theme';

const styles: SxStyles = {
  nameField: {
    paddingTop: '8px',
  },
  name: {
    width: '400px',
    marginBottom: '32px',
  },
  placeholder: {
    color: 'text.gray',
  },
  saveButton: {
    marginRight: '10px',
  },
};

export default styles;
