import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
  },
  total: {
    display: 'flex',
  },
  totalHours: {
    marginRight: '24px',
  },
  totalTitle: {
    color: 'text.gray',
    marginRight: '8px',
  },
  divider: {
    width: '2px',
    backgroundColor: 'text.gray',
    margin: '0 8px',
  },
};

export default styles;
