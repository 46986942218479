import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    maxWidth: 427,
  },
  select: {
    width: '100%',
    marginBottom: '11px',
  },
  dates: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '11px',
  },
  endDateSwitch: {
    marginRight: 0,
  },
  groupFields: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePickerField: {
    width: '160px',
  },
  input: {
    width: '85px',
    marginBottom: '32px',
  },
  addButton: {
    marginRight: '10px',
  },
  selectPlaceholder: {
    color: 'text.gray',
  },
  datePicker: {
    marginRight: '11px',
  },
};

export default styles;
