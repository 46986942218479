import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    padding: '16px',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default styles;
