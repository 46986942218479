import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    backgroundColor: 'background.default',
  },
  outerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '100vh',
  },
  innerWrapper: {
    display: 'flex',
    maxWidth: '768px',
    width: '100%',
    minHeight: '536px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: 'background.white',
    padding: '48px 64px',
    maxWidth: '488px',
    width: '100%',
    border: theme => `1px solid ${theme.palette.border.main}`,
    borderRadius: '0 12px 12px 0',
  },
};

export default styles;
