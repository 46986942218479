import { SxStyles } from 'theme';

const styles: SxStyles = {
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '28px',
  },
  dateRangePickerBlock: {
    display: 'flex',
  },
};

export default styles;
