export enum SmartFilterSectionRelation {
  interval = 'interval',
  single = 'single',
  multiple = 'multiple',
  status = 'status',
  billable = 'billable',
  endDate = 'endDate',
}

export enum SlotVariant {
  project = 'project',
  resource = 'resource',
  group = 'group',
  utilization = 'utilization',
  workload = 'workload',
  allocation = 'allocation',
  state = 'state',
  actualHours = 'actualHours',
  plannedHours = 'plannedHours',
  description = 'description',
  trackable = 'trackable',
  trackableWithBlankCheckbox = 'trackableWithBlankCheckbox',
  technology = 'technology',
  billable = 'billable',
  approvalState = 'approvalState',
  vacationRequestState = 'vacationRequestState',
  user = 'user',
  calendar = 'calendar',
  endDate = 'endDate',
  task = 'task',
  tag = 'tag',
}

export enum SmartFilterDescriptionState {
  all = 'all',
  active = 'active',
  archived = 'archived',
}

export enum SlotField {
  project = 'projectIdsIn',
  resource = 'resourceIdsIn',
  group = 'groupIdsIn',
  technology = 'technologyIdsIn',
  state = 'stateEq',
  description = 'descriptionCont',
  trackable = 'trackables',
  trackableBlank = 'trackableBlank',
  billable = 'billableEq',
  user = 'userIdsIn',
  calendar = 'calendarIdsIn',
  endDate = 'endDateNull',
  task = 'taskIdsIn',
  tag = 'tagIdsIn',
}

export enum SmartFilterDescriptionBillable {
  all = 'all',
  billable = 'billable',
  nonBillable = 'non-billable',
}

export enum SmartFilterDescriptionApprovalState {
  all = 'all',
  onApproval = 'onApproval',
  approved = 'approved',
  completed = 'completed',
}

export enum SmartFilterDescriptionVacationRequestState {
  all = 'all',
  indefinite = 'indefinite',
  confirmed = 'confirmed',
  declined = 'declined',
}

export enum SmartFilterDescriptionEndDate {
  all = 'all',
  withEndDate = 'withEndDate',
  withoutEndDate = 'withoutEndDate',
}

export enum ConditionState {
  active = 'active',
  archived = 'archived',
  both = 'both',
}
