import i18n from 'locales/i18n';

import { ConditionState } from 'components/common/SmartFilter/enums';

import { UserType } from './enums';
import { IUser } from './types';

export const USER_TYPE_OPTIONS = [
  { id: UserType.user, name: i18n.t('common:user') },
  { id: UserType.admin, name: i18n.t('common:admin') },
  { id: UserType.superAdmin, name: i18n.t('common:superAdmin') },
];

export const USER_STATE_OPTIONS = [
  { id: ConditionState.active, name: i18n.t('common:active') },
  { id: ConditionState.archived, name: i18n.t('common:archived') },
];

export const isActive = (user: IUser): boolean => {
  return user.state === ConditionState.active;
};

export const isAdmin = (user: IUser) => user.userType === UserType.admin;
export const isSuperAdmin = (user: IUser) => user.userType === UserType.superAdmin;
export const isUser = (user: IUser) => user.userType === UserType.user;
