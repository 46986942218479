import { FC } from 'react';

import { CircularProgress } from '@mui/material';

import Div from 'components/common/Div';

import styles from './styles';

const Loader: FC = () => {
  return (
    <Div sx={styles.progress}>
      <CircularProgress />
    </Div>
  );
};

export default Loader;
