import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { Button, Tabs, Typography } from '@mui/material';
import { format } from 'date-fns';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';

import CreateResourceUsageAndVacancy from 'components/common/CreateResourceUsageAndVacancy';
import DateRangePicker from 'components/common/DateRangePicker';
import Div from 'components/common/Div';
import ExportButton from 'components/common/ExportButton';
import LinkTab from 'components/common/LinkTab';
import Loader from 'components/common/Loader';
import TabPanel from 'components/common/TabPanel';

import { ModalContext } from 'contexts/ModalContext';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { useLazyGetExportQuery } from 'domain/export/apiSlice';
import { useGetProjectAllocationsQuery } from 'domain/project/allocation/apiSlice';
import { useDeleteProjectMutation, useActivateProjectMutation } from 'domain/project/apiSlice';
import { isProjectActive } from 'domain/project/service';
import {
  loadingGetReportHoursByProjectParameters,
  useGetReportHoursByProjectQuery,
  useCreateCsvReportHoursByProjectMutation,
  useCreateXlsxReportHoursByProjectMutation,
} from 'domain/report/hoursByProject/apiSlice';

import { GroupByPeriodFilter } from 'enums/GroupByPeriodFilter';
import { MeasurementUnitFilter } from 'enums/MeasurementUnitFilter';
import { HoursByProjectTab } from 'enums/reportHoursByProject';

import { useNotifications } from 'hooks/useNotifications';

import { getCurrentYear } from 'utils/calendar';
import { getDateRange, RangeType } from 'utils/dateRangePicker';
import { convertDateToUTCString, getTimeRanges } from 'utils/dateTime';
import { generateBackendErrorMessages } from 'utils/error';
import { fileDownload } from 'utils/file';
import { getFullName } from 'utils/person';

import AllocationTable from '../Allocation/components/AllocationTable';
import { getColoredAccessors } from '../Allocation/components/AllocationTable/service';
import TableHeaderMonth from '../Vacations/components/Calendar/components/TableHeaderMonth';

import Filters from './components/Filters';
import Info from './components/Info';
import ProjectBudgetMonths from './components/ProjectBudgetMonths';
import ProjectTasks from './components/ProjectTasks';
import Table from './components/Table';
import UpdateProjectForm from './components/UpdateProjectForm';
import { ReportAllocationChartTableColumnAccessor } from './enums';
import { getMonthsHeadersData } from './service';
import styles from './styles';
import TableBodyTotalCell from './TableBodyTotalCell';
import TableNameCell from './TableNameCell';
import { IHoursByProjectFilters } from './types';

const HoursByProject: FC = () => {
  const [year] = useState(getCurrentYear());

  const { t } = useTranslation('reportHoursByProject');

  const { data: currentUser } = useGetCurrentUserQuery();

  const timeRanges = getTimeRanges(currentUser?.user?.weekStartsDay ?? 1);

  const [filters, setFilters] = useState<IHoursByProjectFilters>({
    measurementUnit: MeasurementUnitFilter.hours,
    startDate: timeRanges.startOfMonth,
    endDate: timeRanges.endOfMonth,
    groupByPeriod: GroupByPeriodFilter.week,
  });

  const {
    camelizedQuery: { projectId },
    pathname,
    location,
  } = useRouter();

  const {
    data: allocationsDataByProject,
    isLoading: allocationsDataByProjectDataLoading,
    isError: allocationsDataByProjectDataError,
  } = useGetProjectAllocationsQuery({
    projectId,
    startDate: convertDateToUTCString(filters?.startDate),
    endDate: convertDateToUTCString(filters?.endDate),
  });

  const [currenTabName, setCurrentTabName] = useState<string>(HoursByProjectTab.planning);

  const { openModal } = useContext(ModalContext);

  const reportHoursByProjectQueryParameters = loadingGetReportHoursByProjectParameters({
    projectId,
    groupByPeriod: filters.groupByPeriod,
    startDate: convertDateToUTCString(filters.startDate),
    endDate: convertDateToUTCString(filters.endDate),
  });

  const onDateRangeChange = newDateRange => {
    setFilters({
      ...filters,
      startDate: newDateRange?.startDate,
      endDate: newDateRange?.endDate,
    });
  };

  const {
    data: reportHoursByProject,
    isSuccess: isSuccessReportHoursByProjectLoading,
    isLoading: isReportHoursByProjectLoading,
    isFetching: isReportHoursByProjectFetching,
    isError: isReportHoursByProjectLoadingError,
  } = useGetReportHoursByProjectQuery(reportHoursByProjectQueryParameters);

  const monthsData = useMemo(() => {
    return getMonthsHeadersData(year);
  }, [year]);

  const weekendsAccessors = useMemo(() => monthsData.flatMap(monthData => monthData.weekendAccessors), [year]);
  const lastDayOfMonthAccessors = useMemo(() => monthsData.map(monthsData => monthsData.lastDayAccessor), [year]);
  const resources = allocationsDataByProject?.chart?.resources ?? [];

  const RESOURCES_LIST_COLUMNS = useMemo(() => {
    const stickyHeaders = [
      {
        Header: '',
        accessor: ReportAllocationChartTableColumnAccessor.resourcesEmpty,
        columns: [
          {
            Header: (
              <Typography sx={styles.resourceHeaderTitle} variant="body2">
                {allocationsDataByProject?.projectName}
              </Typography>
            ),
            accessor: ReportAllocationChartTableColumnAccessor.resourcesEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportAllocationChartTableColumnAccessor.resources,
                minWidth: '250px',
              },
            ],
          },
        ],
      },
      {
        Header: '',
        accessor: ReportAllocationChartTableColumnAccessor.carryoverPreviousEmpty,
        columns: [
          {
            Header: (
              <Typography sx={styles.resourceHeaderTitle} variant="body2">
                Role
              </Typography>
            ),
            accessor: ReportAllocationChartTableColumnAccessor.carryoverPreviousEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportAllocationChartTableColumnAccessor.carryoverPrevious,
                minWidth: '180px',
              },
            ],
          },
        ],
      },
    ];

    const monthHeaders = monthsData
      .filter(monthItem => monthItem.title === format(filters.endDate, 'MMMM').toLowerCase())
      .map(monthItem => {
        return {
          Header: '',
          accessor: `${monthItem.title}-empty`,
          columns: [
            {
              Header: <TableHeaderMonth title={monthItem.title} lastDayOfMonth={monthItem.daysCount} year={year} />,
              accessor: monthItem.title,
              columns: Array.from({ length: monthItem.daysCount })
                .fill(0)
                .map((_, index) => {
                  const date = index + 1;

                  return {
                    Header: (
                      <Typography sx={styles.dateHeaderTitle} variant="h6">
                        {date}
                      </Typography>
                    ),
                    accessor: `${monthItem.title}-${date}`,
                    minWidth: '35px',
                    width: '35px',
                  };
                }),
            },
          ],
        };
      });

    return [...stickyHeaders, ...monthHeaders];
  }, [resources]);

  const tableData = useMemo(() => {
    return resources.map(resource => {
      const fullName = getFullName(resource);

      return {
        [ReportAllocationChartTableColumnAccessor.resources]: <TableNameCell fullName={fullName} />,
        [ReportAllocationChartTableColumnAccessor.carryoverPrevious]: (
          <TableBodyTotalCell role={resource.technologies} />
        ),
      };
    });
  }, [resources]);

  useEffect(() => {
    if (location.hash) {
      setCurrentTabName(HoursByProjectTab[location.hash.slice(1)]);
    }
    if (reportHoursByProject) {
      setIsActive(isProjectActive(reportHoursByProject.report.project.state));
    }
  }, [reportHoursByProject]);

  const [isActive, setIsActive] = useState(
    reportHoursByProject ? isProjectActive(reportHoursByProject.report.project.state) : false,
  );

  const [createReportHoursByProjectCsv, { isLoading: isCreateReportHoursByProjectCsvLoading }] =
    useCreateCsvReportHoursByProjectMutation();

  const [exportReportHoursByProjectCsv, { isLoading: isExportReportHoursByProjectCsvLoading }] =
    useLazyGetExportQuery();

  const [createReportHoursByProjectXlsx, { isLoading: isCreateReportHoursByProjectXlsxLoading }] =
    useCreateXlsxReportHoursByProjectMutation();

  const [exportReportHoursByProjectXlsx, { isLoading: isExportReportHoursByProjectXlsxLoading }] =
    useLazyGetExportQuery();

  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const handleAddResourceUsageClick = () => {
    const { project } = reportHoursByProject.report;

    openModal({
      title: t('addResourceUsageModalTitle', { projectName: project.name }),
      content: <CreateResourceUsageAndVacancy projectId={project.id} groupId={project.groupId} />,
    });
  };

  const handleEditProjectClick = () => {
    openModal({
      title: t('updateProjectModalTitle'),
      content: <UpdateProjectForm projectId={Number(projectId)} />,
    });
  };

  const handleTabChange = (_event: React.SyntheticEvent, newIndex: string) => {
    setCurrentTabName(newIndex);
  };

  const handleCsvDownload = async () => {
    try {
      const {
        export: { id: exportId },
      } = await createReportHoursByProjectCsv({
        projectId,
        formData: {
          groupByPeriod: filters.groupByPeriod,
          startDate: convertDateToUTCString(filters.startDate),
          endDate: convertDateToUTCString(filters.endDate),
        },
      }).unwrap();

      const reportHoursByProjectCsv = await exportReportHoursByProjectCsv(exportId).unwrap();

      fileDownload(reportHoursByProjectCsv);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const tabLabel = 'projectInfo';

  const handleXlsxDownload = async () => {
    try {
      const {
        export: { id: exportId },
      } = await createReportHoursByProjectXlsx({
        projectId,
        formData: {
          groupByPeriod: filters.groupByPeriod,
          startDate: convertDateToUTCString(filters.startDate),
          endDate: convertDateToUTCString(filters.endDate),
        },
      }).unwrap();

      const reportHoursByProjectXlsx = await exportReportHoursByProjectXlsx(exportId).unwrap();

      fileDownload(reportHoursByProjectXlsx);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const isDisabled =
    isCreateReportHoursByProjectCsvLoading ||
    isExportReportHoursByProjectCsvLoading ||
    isCreateReportHoursByProjectXlsxLoading ||
    isExportReportHoursByProjectXlsxLoading;

  const [deleteProject, { isLoading: isDeleteProjectLoading }] = useDeleteProjectMutation();
  const [activateProject, { isLoading: isActivateProjectLoading }] = useActivateProjectMutation();

  const isDataLoading = isActivateProjectLoading || isDeleteProjectLoading;

  if (isReportHoursByProjectLoading) {
    return <Loader />;
  }

  const handleDeleteProject = async () => {
    try {
      await deleteProject(Number(projectId)).unwrap();
      showSuccessNotification();
      setIsActive(false);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleActivateProject = async () => {
    try {
      await activateProject(Number(projectId)).unwrap();
      showSuccessNotification();
      setIsActive(true);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const {
    canUpdateProject,
    canArchiveProject,
    canAddResource,
    canUpdateBudgets,
    canAddTasks,
    canUpdateTasks,
    canUpdateManagementTeam,
  } = reportHoursByProject.report.project.permissions;

  const coloredAccessors = getColoredAccessors(resources);

  return (
    <>
      <Div sx={styles.titleBlock}>
        <Typography variant="h1">{reportHoursByProject?.report?.project?.name}</Typography>
        <Div>
          {canArchiveProject && (
            <Button
              sx={MUISx(
                styles.button,
                { condition: isActive, sx: styles.deleteButton },
                { condition: !isActive, sx: styles.activateButton },
              )}
              disabled={isDataLoading}
              variant="outlined"
              onClick={isActive ? handleDeleteProject : handleActivateProject}
            >
              {isActive ? t('archiveButton') : t('activateButton')}
            </Button>
          )}
          {canUpdateProject && (
            <Button sx={styles.button} variant="outlined" onClick={handleEditProjectClick}>
              {t('editProjectButton')}
            </Button>
          )}
          {canAddResource && (
            <Button sx={styles.createButton} onClick={handleAddResourceUsageClick}>
              {t('addResourceUsageButton')}
            </Button>
          )}
          <ExportButton isDisabled={isDisabled} onCsvDownload={handleCsvDownload} onXlsxDownload={handleXlsxDownload} />
        </Div>
      </Div>
      <Div sx={styles.container}>
        <Div sx={styles.tabHeadersContainer}>
          <Tabs value={currenTabName} onChange={handleTabChange} aria-label="tabs" textColor="inherit">
            <LinkTab
              label={t('tabs.dashboard')}
              value={HoursByProjectTab.dashboard}
              blockLabel={tabLabel}
              to={{ path: pathname, hash: HoursByProjectTab.dashboard }}
            />
            <LinkTab
              label={t('tabs.overview')}
              value={HoursByProjectTab.overview}
              blockLabel={tabLabel}
              to={{ path: pathname, hash: HoursByProjectTab.overview }}
            />
            <LinkTab
              label={t('tabs.planning')}
              value={HoursByProjectTab.planning}
              blockLabel={tabLabel}
              to={{ path: pathname, hash: HoursByProjectTab.planning }}
            />
            <LinkTab
              label={t('tabs.budgets')}
              value={HoursByProjectTab.budgets}
              blockLabel={tabLabel}
              to={{ path: pathname, hash: HoursByProjectTab.budgets }}
            />
            <LinkTab
              label={t('tabs.tasks')}
              value={HoursByProjectTab.tasks}
              blockLabel={tabLabel}
              to={{ path: pathname, hash: HoursByProjectTab.tasks }}
            />
            <LinkTab
              label={t('tabs.notes')}
              value={HoursByProjectTab.notes}
              blockLabel={tabLabel}
              to={{ path: pathname, hash: HoursByProjectTab.notes }}
            />
            <LinkTab
              label={t('tabs.actionItems')}
              value={HoursByProjectTab.actionItems}
              blockLabel={tabLabel}
              to={{ path: pathname, hash: HoursByProjectTab.actionItems }}
            />
            <LinkTab
              label={t('tabs.activities')}
              value={HoursByProjectTab.activities}
              blockLabel={tabLabel}
              to={{ path: pathname, hash: HoursByProjectTab.activities }}
            />
            <LinkTab
              label={t('tabs.allocationChart')}
              value={HoursByProjectTab.allocationChart}
              blockLabel={tabLabel}
              to={{ path: pathname, hash: HoursByProjectTab.allocationChart }}
            />
          </Tabs>
        </Div>
        <TabPanel value={currenTabName} name={HoursByProjectTab.dashboard} tabGroupName={tabLabel}>
          {t('tabs.dashboard')}
        </TabPanel>
        <TabPanel value={currenTabName} name={HoursByProjectTab.overview} tabGroupName={tabLabel}>
          {t('tabs.overview')}
        </TabPanel>
        <TabPanel value={currenTabName} name={HoursByProjectTab.planning} tabGroupName={tabLabel}>
          {isSuccessReportHoursByProjectLoading && (
            <Info
              project={reportHoursByProject.report.project}
              canUpdateBudgets={canUpdateBudgets}
              canUpdateManagementTeam={canUpdateManagementTeam}
            />
          )}

          <Filters filters={filters} setFilters={setFilters} project={reportHoursByProject.report.project} />

          {isSuccessReportHoursByProjectLoading && (
            <Table
              isError={isReportHoursByProjectLoadingError}
              data={reportHoursByProject}
              isLoading={isReportHoursByProjectFetching}
              groupByPeriod={filters.groupByPeriod}
            />
          )}
        </TabPanel>
        <TabPanel value={currenTabName} name={HoursByProjectTab.budgets} tabGroupName={tabLabel}>
          <ProjectBudgetMonths projectId={Number(projectId)} canUpdateBudgets={canUpdateBudgets} />
        </TabPanel>
        <TabPanel value={currenTabName} name={HoursByProjectTab.tasks} tabGroupName={tabLabel}>
          <ProjectTasks projectId={Number(projectId)} canAddTasks={canAddTasks} canUpdateTasks={canUpdateTasks} />
        </TabPanel>
        <TabPanel value={currenTabName} name={HoursByProjectTab.notes} tabGroupName={tabLabel}>
          {t('tabs.notes')}
        </TabPanel>
        <TabPanel value={currenTabName} name={HoursByProjectTab.actionItems} tabGroupName={tabLabel}>
          {t('tabs.actionItems')}
        </TabPanel>
        <TabPanel value={currenTabName} name={HoursByProjectTab.activities} tabGroupName={tabLabel}>
          {t('tabs.activities')}
        </TabPanel>
        <TabPanel value={currenTabName} name={HoursByProjectTab.allocationChart} tabGroupName={tabLabel}>
          <Div>
            <Div sx={styles.root}>
              <h3>{allocationsDataByProject?.meta?.totalCount} Resources</h3>
              <DateRangePicker
                currentDateRange={getDateRange(filters.startDate, filters.endDate, RangeType.month)}
                onDateRangeChange={onDateRangeChange}
              />
            </Div>
            <AllocationTable
              data={tableData}
              columns={RESOURCES_LIST_COLUMNS}
              weekendsAccessors={weekendsAccessors}
              coloredAccessors={coloredAccessors}
              lastDayOfMonthAccessors={lastDayOfMonthAccessors}
              isLoading={allocationsDataByProjectDataLoading}
              isError={allocationsDataByProjectDataError}
            />
          </Div>
        </TabPanel>
      </Div>
    </>
  );
};

export default HoursByProject;
