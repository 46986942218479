import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    flexGrow: '1',
    backgroundColor: 'background.white',
    border: '1px solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    padding: '24px',
  },
  tabHeaders: {
    textTransform: 'capitalize',
    '&:first-of-type': {
      paddingLeft: '2px',
    },
  },
  tabHeadersContainer: {
    borderBottom: '1px solid',
    borderColor: 'border.lightGray',
  },
};

export default styles;
