import { SxStyles } from 'theme';

const styles: SxStyles = {
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '40px',
  },
  smartFilter: {
    marginBottom: '36px',
  },
  container: {
    backgroundColor: 'background.white',
    borderRadius: '6px',
  },
  tabHeadersContainer: {
    borderBottom: '1px solid',
    borderColor: 'border.lightGray',
    marginBottom: '40px',
  },
};

export default styles;
