import { SxStyles } from 'theme';

const styles: SxStyles = {
  contentText: {
    display: 'flex',
    marginRight: '25px',
  },
  accessLevelItem: {
    cursor: 'pointer',
    marginBottom: '20px',
    '&:hover div': {
      visibility: 'visible',
    },
  },
  actions: {
    visibility: 'hidden',
  },
  deleteButton: {
    color: 'text.darkRed',
  },
  technologiesList: {
    display: 'flex',
  },
};

export default styles;
