import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  select: {
    paddingTop: '5px',
  },
  buttonsContainer: {
    width: '380px',
    marginTop: '32px',
    display: 'flex',
    gap: '12px',
  },
  datePickerField: {
    width: '188px',
  },
  datePicker: {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'space-between',
  },
};

export default styles;
