import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingTop: '8px',
  },
  select: {
    width: '400px',
    marginBottom: '32px',
    textTransform: 'capitalize',
  },
  selectPlaceholder: {
    color: 'text.gray',
  },
  permissionValue: {
    textTransform: 'capitalize',
  },
  saveButton: {
    marginRight: '10px',
  },
};

export default styles;
