import { FC, useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import { getReportTrackedTimeEntriesGrouping } from 'components/pages/Report/TrackedTimeEntries/service';

import {
  getReportTrackedTimeEntriesGroupedByProject,
  getReportTrackedTimeEntriesGroupedByUser,
  IResourceTrackedTimeEntriesGrouped,
} from 'domain/report/trackedTimeEntry/service';

import ReportTrackedTimeEntriesGroupList from './components/ReportTrackedTimeEntriesGroupList';
import ReportTrackedTimeEntriesNotGroupList from './components/ReportTrackedTimeEntriesNotGroupList';
import styles from './styles';
import { IReportTrackedTimeEntriesListProps } from './types';

const ReportTrackedTimeEntriesList: FC<IReportTrackedTimeEntriesListProps> = props => {
  const { trackedTimeEntries, onLoadMore, hasMoreItems, isFetching, group, onEntrySelect, selectedEntryIds } = props;

  const { t } = useTranslation('reportTrackedTimeEntries');

  const [groupedTrackedTimeEntries, setGroupedTrackedTimeEntries] = useState<IResourceTrackedTimeEntriesGrouped>({});

  const { isNotGrouping, isGroupingByUser, isGroupingByProject } = getReportTrackedTimeEntriesGrouping(group);

  useEffect(() => {
    if (isNotGrouping) {
      setGroupedTrackedTimeEntries({});
    }

    if (isGroupingByUser) {
      const trackedTimeEntriesGroupedByUser = getReportTrackedTimeEntriesGroupedByUser(trackedTimeEntries);
      setGroupedTrackedTimeEntries(trackedTimeEntriesGroupedByUser);
    }

    if (isGroupingByProject) {
      const trackedTimeEntriesGroupedByProject = getReportTrackedTimeEntriesGroupedByProject(trackedTimeEntries);
      setGroupedTrackedTimeEntries(trackedTimeEntriesGroupedByProject);
    }
  }, [group, trackedTimeEntries]);

  return (
    <>
      {isNotGrouping ? (
        <ReportTrackedTimeEntriesNotGroupList trackedTimeEntries={trackedTimeEntries} />
      ) : (
        <ReportTrackedTimeEntriesGroupList
          groupedTrackedTimeEntries={groupedTrackedTimeEntries}
          group={group}
          onEntrySelect={onEntrySelect}
          selectedEntryIds={selectedEntryIds}
        />
      )}

      {hasMoreItems &&
        (isFetching ? (
          <Loader />
        ) : (
          <Div sx={styles.loadMore}>
            <Button onClick={onLoadMore}>{t('loadMoreButton')}</Button>
          </Div>
        ))}
    </>
  );
};

export default ReportTrackedTimeEntriesList;
