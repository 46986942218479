import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    margin: '32px 24px',
  },
  header: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '40px',
  },
  actionEdit: {
    cursor: 'pointer',
    color: 'primary.blue',
  },
};

export default styles;
