import { SxStyles } from 'theme';

const styles: SxStyles = {
  input: {
    width: '360px',
  },
  passwordInput: {
    marginBottom: '16px',
  },
  passwordConfirmationInput: {
    marginBottom: '40px',
  },
  button: {
    width: '240px',
    textTransform: 'none',
    backgroundColor: 'button.main',
  },
};

export default styles;
