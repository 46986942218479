import { useMemo, useState, useContext, useEffect } from 'react';

import { PercentOutlined, EventAvailable, ExpandCircleDownOutlined, SellOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import CreateReportFilterForm from 'components/common/ReportFiltersButton/CreateReportFilterForm';

import { ModalContext } from 'contexts/ModalContext';

import { ReportType } from 'domain/report/filter/enums';

import { FilterPredicate } from 'enums/FilterPredicate';

import { isPresent } from 'utils/data';
import { capitalizeFirstLetter } from 'utils/string';

import Slot from './components/Slot';
import { SlotVariant, SmartFilterSectionRelation } from './enums';
import {
  APPROVAL_STATUS_COMPARISONS,
  BLANK_COMPARISON,
  MULTIPLE_VALUES_COMPARISONS,
  NAME_CONTAINS_COMPARISON,
  STATUS_COMPARISONS,
  END_DATE_COMPARISONS,
  VACATION_REQUEST_STATUS_COMPARISONS,
} from './relations';
import {
  getSmartFilterInitialValue,
  getSmartFilterComparisonValue,
  smartFilterInitialValues,
  isNeedUpdateComparison,
} from './service';
import styles from './styles';
import { ISmartFilterProps, ISlot, ISmartFilter, IMultipleSlot } from './types';

const PREDICATE_FOR_IN_COMPARISON = 'Ids';

let FILTER_WITH_ENTER = false;

function SmartFilter<T>(props: ISmartFilterProps<T>): JSX.Element {
  const { smartFilter, onSmartFilterChange, filters, reportType, onReset = () => {} } = props;

  const { t } = useTranslation('smartFilter');
  const isTrackedTimeEntries = reportType === ReportType.trackedTimeEntries;

  const slotsConfig: Array<ISlot> = useMemo(() => {
    const statusLabel = t('sections.status.label');
    return [
      {
        id: nanoid(),
        name: SlotVariant.resource,
        label: t('sections.members.label'),
        renderIcon: () => <Icon name="team" />,
        relation: SmartFilterSectionRelation.multiple,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.resource),
        comparison: MULTIPLE_VALUES_COMPARISONS,
      },
      {
        id: nanoid(),
        name: SlotVariant.user,
        label: t('sections.users.label'),
        renderIcon: () => <Icon name="team" />,
        relation: SmartFilterSectionRelation.multiple,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.user),
        comparison: MULTIPLE_VALUES_COMPARISONS,
      },
      {
        id: nanoid(),
        name: SlotVariant.technology,
        label: t('sections.technology.label'),
        renderIcon: () => <Icon name="briefcase" />,
        relation: SmartFilterSectionRelation.multiple,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.technology),
        comparison: MULTIPLE_VALUES_COMPARISONS,
      },
      {
        id: nanoid(),
        name: SlotVariant.project,
        label: t('sections.project.label'),
        renderIcon: () => <Icon name="projects" />,
        relation: SmartFilterSectionRelation.multiple,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.project),
        comparison: MULTIPLE_VALUES_COMPARISONS,
      },

      {
        id: nanoid(),
        name: SlotVariant.trackable,
        label: t('sections.trackable.label'),
        renderIcon: () => <Icon name="projects" />,
        relation: SmartFilterSectionRelation.multiple,
        value: getSmartFilterInitialValue(
          smartFilter,
          isTrackedTimeEntries ? SlotVariant.trackableWithBlankCheckbox : SlotVariant.trackable,
        ),
        comparison: MULTIPLE_VALUES_COMPARISONS,
        useBlank: isTrackedTimeEntries,
      },
      {
        id: nanoid(),
        name: SlotVariant.group,
        label: t('sections.hierarchy.label'),
        renderIcon: () => <Icon name="hierarchy" />,
        relation: SmartFilterSectionRelation.multiple,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.group),
        comparison: MULTIPLE_VALUES_COMPARISONS,
      },
      {
        id: nanoid(),
        name: SlotVariant.calendar,
        label: t('sections.calendars.label'),
        renderIcon: () => <Icon name="calendar" />,
        relation: SmartFilterSectionRelation.multiple,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.calendar),
        comparison: MULTIPLE_VALUES_COMPARISONS,
      },

      {
        id: nanoid(),
        name: SlotVariant.task,
        label: t('sections.task.label'),
        renderIcon: () => <SellOutlined />,
        relation: SmartFilterSectionRelation.multiple,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.task),
        comparison: MULTIPLE_VALUES_COMPARISONS,
      },
      {
        id: nanoid(),
        name: SlotVariant.tag,
        label: t('sections.tag.label'),
        renderIcon: () => <ExpandCircleDownOutlined />,
        relation: SmartFilterSectionRelation.multiple,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.tag),
        comparison: MULTIPLE_VALUES_COMPARISONS,
      },
      {
        id: nanoid(),
        name: SlotVariant.description,
        label: t('sections.description.label'),
        renderIcon: () => <Icon name="text" />,
        relation: SmartFilterSectionRelation.interval,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.description),
        comparison: NAME_CONTAINS_COMPARISON,
      },
      {
        id: nanoid(),
        name: SlotVariant.utilization,
        label: t('sections.utilization.label'),
        renderIcon: () => <PercentOutlined />,
        relation: SmartFilterSectionRelation.interval,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.utilization),
        comparison: getSmartFilterComparisonValue(smartFilter, SlotVariant.utilization),
      },
      {
        id: nanoid(),
        name: SlotVariant.workload,
        label: t('sections.workload.label'),
        renderIcon: () => <PercentOutlined />,
        relation: SmartFilterSectionRelation.interval,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.workload),
        comparison: getSmartFilterComparisonValue(smartFilter, SlotVariant.workload),
      },
      {
        id: nanoid(),
        name: SlotVariant.allocation,
        label: t('sections.allocation.label'),
        renderIcon: () => <Icon name="allocation" />,
        relation: SmartFilterSectionRelation.interval,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.allocation),
        comparison: getSmartFilterComparisonValue(smartFilter, SlotVariant.allocation),
      },
      {
        id: nanoid(),
        name: SlotVariant.actualHours,
        label: t('sections.actualHours.label'),
        renderIcon: () => <Icon name="hours" />,
        relation: SmartFilterSectionRelation.interval,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.actualHours),
        comparison: getSmartFilterComparisonValue(smartFilter, SlotVariant.actualHours),
      },
      {
        id: nanoid(),
        name: SlotVariant.plannedHours,
        label: t('sections.plannedHours.label'),
        renderIcon: () => <Icon name="hours" />,
        relation: SmartFilterSectionRelation.interval,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.plannedHours),
        comparison: getSmartFilterComparisonValue(smartFilter, SlotVariant.plannedHours),
      },
      {
        id: nanoid(),
        name: SlotVariant.state,
        label: statusLabel,
        renderIcon: () => <Icon name="myActionItems" />,
        relation: SmartFilterSectionRelation.status,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.state),
        comparison: STATUS_COMPARISONS.at(0),
      },
      {
        id: nanoid(),
        name: SlotVariant.billable,
        label: t('sections.billable.label'),
        renderIcon: () => <Icon name="billable" />,
        relation: SmartFilterSectionRelation.billable,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.billable),
        comparison: STATUS_COMPARISONS.at(0),
      },
      {
        id: nanoid(),
        name: SlotVariant.approvalState,
        label: statusLabel,
        renderIcon: () => <Icon name="myActionItems" />,
        relation: SmartFilterSectionRelation.status,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.approvalState),
        comparison: APPROVAL_STATUS_COMPARISONS.at(0),
      },
      {
        id: nanoid(),
        name: SlotVariant.vacationRequestState,
        label: statusLabel,
        renderIcon: () => <Icon name="myActionItems" />,
        relation: SmartFilterSectionRelation.status,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.vacationRequestState),
        comparison: VACATION_REQUEST_STATUS_COMPARISONS.at(0),
      },
      {
        id: nanoid(),
        name: SlotVariant.endDate,
        label: t('sections.endDate.label'),
        renderIcon: () => <EventAvailable />,
        relation: SmartFilterSectionRelation.endDate,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.endDate),
        comparison: END_DATE_COMPARISONS.at(0),
      },
      {
        id: nanoid(),
        name: SlotVariant.endDate,
        label: t('sections.endDate.label'),
        renderIcon: () => <EventAvailable />,
        relation: SmartFilterSectionRelation.endDate,
        value: getSmartFilterInitialValue(smartFilter, SlotVariant.endDate),
        comparison: END_DATE_COMPARISONS.at(0),
      },
    ];
  }, []);

  const availableSlots = slotsConfig.filter(slot => filters.includes(slot.name as ISmartFilter));

  const [slots, setSlots] = useState<Array<ISlot>>(availableSlots);

  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    setSlots(
      availableSlots.map(slot => ({
        ...slot,
        value: getSmartFilterInitialValue(smartFilter, slot.name as SlotVariant),
        comparison: isNeedUpdateComparison(slot.name as SlotVariant)
          ? getSmartFilterComparisonValue(smartFilter, slot.name as SlotVariant)
          : slot.comparison,
      })),
    );
  }, [smartFilter]);

  useEffect(() => {
    FILTER_WITH_ENTER && handleFilterUpdate();
  }, [slots]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleFilterUpdate = () => {
    const filter = slots.reduce<T>((accumulator, slot) => {
      if (_.isNil(slot.value) || _.isEmpty(slot.value)) {
        return accumulator;
      }

      if (slot.name === SlotVariant.approvalState || slot.name === SlotVariant.vacationRequestState) {
        return {
          ...accumulator,
          [`${SlotVariant.state}${capitalizeFirstLetter(slot.comparison.predicate)}`]: (slot.value as IMultipleSlot)
            .name,
        };
      }

      if ((slot.value as IMultipleSlot).blank || (Array.isArray(slot.value) && slot.value.at(0)?.blank)) {
        return {
          ...accumulator,
          [`${slot.name}${capitalizeFirstLetter(BLANK_COMPARISON.predicate)}`]: true,
        };
      }

      if (Array.isArray(slot.value) && !_.isNil(slot.value.at(0)?.entireObject)) {
        return {
          ...accumulator,
          [`${slot.name}s`]: slot.value.map(({ entireObject }) => ({ ...entireObject })),
        };
      }

      const idsPredicate = slot.comparison.predicate === FilterPredicate.in ? PREDICATE_FOR_IN_COMPARISON : '';

      return {
        ...accumulator,
        [`${slot.name}${idsPredicate}${capitalizeFirstLetter(slot.comparison.predicate)}`]: Array.isArray(slot.value)
          ? slot.value.map(({ id }) => id)
          : slot.value.name,
      };
    }, {} as T);

    if (!_.isEqual(smartFilter, filter)) {
      onSmartFilterChange(filter);
      onReset();
      FILTER_WITH_ENTER = false;
    }
  };

  const handleFiltersClear = () => {
    onSmartFilterChange(null);
    setSlots(
      availableSlots.map(slot => ({
        ...slot,
        value: smartFilterInitialValues[slot.name],
      })),
    );
    onReset();
  };

  const handleSlotPatch = (slotId: string, patch: Partial<ISlot>, filterWithEnter = false) => {
    const slotIndex = slots.findIndex(slot => slot.id === slotId);
    FILTER_WITH_ENTER = filterWithEnter;
    if (slotIndex !== -1) {
      const slot = slots[slotIndex];
      const patchedSlot = { ...slot, ...patch };
      setSlots(previousSlot => previousSlot.map(element => (element.id === patchedSlot.id ? patchedSlot : element)));
    }
  };

  const handleCreateReportFilterModalOpen = () => {
    openModal({
      title: t('reportFilter.saveReportFilterButton'),
      content: <CreateReportFilterForm filters={smartFilter} reportType={reportType} />,
    });
  };
  return (
    <>
      {isPresent(smartFilter) && (
        <Div sx={styles.buttons}>
          {isPresent(reportType) && (
            <Button variant="text" sx={styles.createReportFilterButton} onClick={handleCreateReportFilterModalOpen}>
              {t('reportFilter.saveReportFilterButton')}
            </Button>
          )}
          <Button variant="text" sx={styles.clearButton} onClick={handleFiltersClear}>
            {t('clearButton')}
          </Button>
        </Div>
      )}
      <Div sx={styles.root}>
        <Typography sx={styles.title} variant="heading5" component="p">
          {`${t('title')}:`}
        </Typography>

        {slots.map(slot => {
          return <Slot key={slot.id} slot={slot} onSlotPatch={handleSlotPatch} onFilterUpdate={handleFilterUpdate} />;
        })}
      </Div>
    </>
  );
}

export default SmartFilter;
