import { SxStyles } from 'theme';

const styles: SxStyles = {
  progress: {
    textAlign: 'center',
    paddingTop: '32px',
    paddingBottom: '32px',
  },
};

export default styles;
