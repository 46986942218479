import _ from 'lodash';

import { ITimeTrackerTrackable } from 'domain/timeTracker/trackable/types';

import { FilterPredicate } from 'enums/FilterPredicate';

import { capitalizeFirstLetter } from 'utils/string';

import { SlotField, SlotVariant } from './enums';
import { INTERVAL_COMPARISONS } from './relations';
import { ISlotValue, ISmartFilter } from './types';

const prepareIdsFilter = (smartFilter, field) => {
  const ids = (smartFilter[field] ?? []) as Array<string>;
  return ids.map(id => ({ id: Number(id) })) as ISlotValue;
};

export const prepareValueForStateFilter = smartFilter => {
  if (smartFilter.stateEq) {
    return { id: null, name: smartFilter.stateEq };
  }
  return null;
};

export const prepareValueForBillableFilter = smartFilter => {
  if (!_.isNil(smartFilter.billableEq)) {
    const name = smartFilter.billableEq === true;
    return { id: null, name };
  }
  return null;
};

export const prepareValueForEndDateFilter = smartFilter => {
  if (smartFilter.endDateNull) {
    return { id: null, name: smartFilter.endDateNull };
  }
  return null;
};

export const prepareValueForDescriptionFilter = smartFilter => {
  if (smartFilter.descriptionCont) {
    return { id: null, name: smartFilter.descriptionCont };
  }
  if (smartFilter.descriptionBlank) {
    return { id: null, name: null, blank: true };
  }
  return null;
};

export const getSmartFilterInitialValue = (smartFilter: unknown, slotVariant: SlotVariant) => {
  if (_.isNil(smartFilter)) {
    return smartFilterInitialValues[slotVariant];
  }

  const mapSlotVariantToInitialValue = {
    [SlotVariant.state]: prepareValueForStateFilter(smartFilter),
    [SlotVariant.approvalState]: prepareValueForStateFilter(smartFilter),
    [SlotVariant.vacationRequestState]: prepareValueForStateFilter(smartFilter),
    [SlotVariant.trackable]: prepareValueForTrackableFilter(smartFilter),
    [SlotVariant.trackableWithBlankCheckbox]: prepareValueForTrackableFilter(smartFilter),
    [SlotVariant.description]: prepareValueForDescriptionFilter(smartFilter),
    [SlotVariant.group]: prepareIdsFilter(smartFilter, SlotField.group),
    [SlotVariant.project]: prepareIdsFilter(smartFilter, SlotField.project),
    [SlotVariant.resource]: prepareIdsFilter(smartFilter, SlotField.resource),
    [SlotVariant.user]: prepareIdsFilter(smartFilter, SlotField.user),
    [SlotVariant.calendar]: prepareIdsFilter(smartFilter, SlotField.calendar),
    [SlotVariant.technology]: prepareIdsFilter(smartFilter, SlotField.technology),
    [SlotVariant.actualHours]: prepareValueForPredicateField(smartFilter, SlotVariant.actualHours),
    [SlotVariant.plannedHours]: prepareValueForPredicateField(smartFilter, SlotVariant.plannedHours),
    [SlotVariant.allocation]: prepareValueForPredicateField(smartFilter, SlotVariant.allocation),
    [SlotVariant.utilization]: prepareValueForPredicateField(smartFilter, SlotVariant.utilization),
    [SlotVariant.workload]: prepareValueForPredicateField(smartFilter, SlotVariant.workload),
    [SlotVariant.billable]: prepareValueForBillableFilter(smartFilter),
    [SlotVariant.endDate]: prepareValueForEndDateFilter(smartFilter),
    [SlotVariant.task]: prepareIdsFilter(smartFilter, SlotField.task),
    [SlotVariant.tag]: prepareIdsFilter(smartFilter, SlotField.tag),
  };

  return mapSlotVariantToInitialValue[slotVariant];
};

const generateFieldsWithPredicates = (field: SlotVariant) => {
  return Object.keys(FilterPredicate).map(predicate => `${field}${capitalizeFirstLetter(predicate)}`);
};

const getPredicateField = (smartFilter, field: SlotVariant) => {
  const predicateFields = generateFieldsWithPredicates(field);
  const smartFilterFields = Object.keys(smartFilter || {});
  return _.head(_.intersection(predicateFields, smartFilterFields));
};

export const prepareValueForPredicateField = (smartFilter, field: SlotVariant) => {
  const predicateField = getPredicateField(smartFilter, field);
  if (predicateField) {
    const value = smartFilter[predicateField];
    return { id: null, name: Number(value) };
  }
  return null;
};

const prepareComparison = (comparison: string, fieldName: string) => {
  const predicate = comparison.replace(fieldName, '').toLowerCase();
  return INTERVAL_COMPARISONS.find(comparison => comparison.predicate === predicate);
};

export const getSmartFilterComparisonValue = (smartFilter, field: SlotVariant) => {
  const fieldName = getPredicateField(smartFilter, field as ISmartFilter);
  const value = getSmartFilterInitialValue(smartFilter, field);

  return value
    ? prepareComparison(fieldName, field)
    : INTERVAL_COMPARISONS.find(comparison => comparison.predicate === FilterPredicate.eq);
};

export const isNeedUpdateComparison = (field: SlotVariant) => {
  return SlotVariants.has(field);
};

export const pickFieldsForSmartFilter = (smartFilter, filters: Array<ISmartFilter>) => {
  const mapFilterToFilterField = {
    project: SlotField.project,
    resource: SlotField.resource,
    user: SlotField.user,
    calendar: SlotField.calendar,
    group: SlotField.group,
    state: SlotField.state,
    description: SlotField.description,
    trackable: SlotField.trackable,
    trackableBlank: SlotField.trackableBlank,
    billable: SlotField.billable,
    endDate: SlotField.endDate,
    task: SlotField.task,
    tag: SlotField.tag,
    technology: SlotField.technology,
    utilization: getPredicateField(smartFilter, SlotVariant.utilization),
    allocation: getPredicateField(smartFilter, SlotVariant.allocation),
    actualHours: getPredicateField(smartFilter, SlotVariant.actualHours),
    plannedHours: getPredicateField(smartFilter, SlotVariant.plannedHours),
    workload: getPredicateField(smartFilter, SlotVariant.workload),
  };

  const filtersToPick = _.intersection(filters, Object.keys(mapFilterToFilterField));
  const fieldsToPick = Object.values(_.pick(mapFilterToFilterField, filtersToPick));

  return _.pick(smartFilter, fieldsToPick);
};

export const prepareValueForTrackableFilter = smartFilter => {
  if (smartFilter.trackableBlank) {
    return [{ id: null, name: '', blank: true, entireObject: null }];
  }

  const trackables = smartFilter.trackables ?? [];

  return trackables.map((trackable: ITimeTrackerTrackable) => {
    return {
      id: Number(trackable.id),
      name: trackable.name,
      entireObject: {
        id: Number(trackable.id),
        name: trackable.name,
        billable: trackable.billable,
        type: trackable.type,
        category: 'project',
      },
    };
  });
};

export const SlotVariants = new Set([
  SlotVariant.actualHours,
  SlotVariant.plannedHours,
  SlotVariant.allocation,
  SlotVariant.utilization,
  SlotVariant.workload,
]);

export const smartFilterInitialValues = {
  group: [],
  project: [],
  resource: [],
  user: [],
  calendar: [],
  trackable: [],
  trackableWithBlankCheckbox: [],
  technology: [],
  task: [],
  tag: [],
  state: null,
  actualHours: null,
  plannedHours: null,
  allocation: null,
  workload: null,
  description: null,
  utilization: null,
  billable: null,
  endDate: null,
};
