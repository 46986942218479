import { SxStyles } from 'theme';

const styles: SxStyles = {
  box: {
    maxHeight: '300px',
    overflow: 'auto',
    paddingLeft: '24px',
    paddingRight: '16px',
    paddingTop: '8px',
    marginBottom: '16px',
  },
  checkboxLabel: {
    color: 'text.blackLight',
  },
};

export default styles;
