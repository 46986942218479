import { SxStyles } from 'theme';

const styles: SxStyles = {
  technologiesAutocomplete: {
    paddingTop: '6px',
    width: '400px',
    marginBottom: '30px',
  },
  saveButton: {
    marginRight: '10px',
  },
};

export default styles;
