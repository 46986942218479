import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    marginBottom: '44px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',
    backgroundColor: 'background.gray',
    marginBottom: '8px',
  },
  workingHours: {
    color: 'text.lightGray',
  },
};

export default styles;
