import { FC, useContext, useState } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { Alert, IconButton, Menu, MenuItem, Typography, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import appRoutes from 'routes/appRoutes';

import UpdateUserForm from 'components/common/UpdateUserForm';

import { ModalContext } from 'contexts/ModalContext';

import { useCreateCurrentUserImpersonationMutation } from 'domain/currentUser/impersonation/apiSlice';
import { useActivateUserMutation, useDeleteUserMutation } from 'domain/user/apiSlice';
import { isActive } from 'domain/user/service';
import { IUser } from 'domain/user/types';

import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useNotifications } from 'hooks/useNotifications';

import { apiContainer, store } from 'store';

import { generateBackendErrorMessages } from 'utils/error';

import styles from './styles';
import IUserActionsMenuProps from './types';

const UserActionsMenu: FC<IUserActionsMenuProps> = props => {
  const { user } = props;

  const { t } = useTranslation(['common', 'users']);

  const { openModal } = useContext(ModalContext);
  const [userActoinsMenu, setUserActionsMenu] = useState<null | HTMLElement>(null);
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { getConfirmation } = useConfirmDialog();

  const [userDelete, { isLoading: isUserDeleteLoading, isError: isUserDeleteLoadingError }] = useDeleteUserMutation();
  const [userActivate, { isLoading: isUserActivateLoading, isError: isUserActivateLoadingError }] =
    useActivateUserMutation();
  const [
    userCreateImpersonation,
    { isLoading: isUserCreateImpersonationLoading, isError: isUserCreateImpersonationLoadingError },
  ] = useCreateCurrentUserImpersonationMutation();

  const isUserActionsActionsMenuOpen = Boolean(userActoinsMenu);

  const handleUserActionsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserActionsMenu(event.currentTarget);
  };

  const handleUserActionsMenuClose = () => {
    setUserActionsMenu(null);
  };

  const handleUserUpdateModalOpen = (user: IUser) => {
    openModal({
      title: t('users:userActions.editModal.title'),
      fullScreen: false,
      content: <UpdateUserForm user={user} />,
    });
    handleUserActionsMenuClose();
  };

  const handleUserDeactivate = async (userId: number) => {
    if (
      await getConfirmation({
        title: t('users:userActions.userDeactivateConfirmation.title'),
        message: t('users:userActions.userDeactivateConfirmation.message'),
      })
    ) {
      try {
        await userDelete(userId).unwrap();
        showSuccessNotification();
      } catch (error) {
        const errors = generateBackendErrorMessages(error);
        for (const message of errors) {
          showErrorNotification(message);
        }
      }
    }
    handleUserActionsMenuClose();
  };

  const handleUserActivate = async (userId: number) => {
    try {
      await userActivate(userId).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      handleUserActionsMenuClose();
    }
  };

  const handleUserImpersonate = async (userId: number) => {
    const impersonation = {
      subjectId: userId,
    };

    try {
      await userCreateImpersonation({ impersonation }).unwrap();
      store.dispatch(apiContainer.util.resetApiState());
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      handleUserActionsMenuClose();
    }
  };

  const isDataLoading = isUserDeleteLoading || isUserActivateLoading || isUserCreateImpersonationLoading;
  const isDataLoadingError =
    isUserDeleteLoadingError || isUserActivateLoadingError || isUserCreateImpersonationLoadingError;

  if (isDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const { canEditUser, canImpersonateUser, canArchiveUser, canViewUser } = user.permissions;
  const tooltipTitle = t('users:userActions.menuItems.notAvailable');
  return (
    <>
      <IconButton
        id={`actions-button-${user.id}`}
        aria-controls={isUserActionsActionsMenuOpen ? `actions-menu-${user.id}` : undefined}
        aria-expanded={isUserActionsActionsMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleUserActionsMenuClick}
      >
        <MoreHorizOutlined sx={styles.horizonIcon} />
      </IconButton>
      <Menu
        id={`actions-menu-${user.id}`}
        anchorEl={userActoinsMenu}
        open={isUserActionsActionsMenuOpen}
        onClose={handleUserActionsMenuClose}
        MenuListProps={{
          'aria-labelledby': `actions-button-${user.id}`,
        }}
      >
        <Tooltip title={tooltipTitle} disableHoverListener={canViewUser}>
          <span>
            <MenuItem
              disabled={isDataLoading || !canViewUser}
              divider
              component={Link}
              to={appRoutes.userProfile.path(user.id)}
            >
              <Typography variant="subtitle2">{t('users:userActions.menuItems.view.title')}</Typography>
            </MenuItem>{' '}
          </span>
        </Tooltip>
        <Tooltip title={tooltipTitle} disableHoverListener={canEditUser}>
          <span>
            <MenuItem disabled={isDataLoading || !canEditUser} onClick={() => handleUserUpdateModalOpen(user)} divider>
              <Typography variant="subtitle2">{t('users:userActions.menuItems.edit.title')}</Typography>
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={tooltipTitle} disableHoverListener={canImpersonateUser}>
          <span>
            <MenuItem
              disabled={isDataLoading || !canImpersonateUser}
              onClick={() => handleUserImpersonate(user.id)}
              divider
            >
              <Typography variant="subtitle2">{t('users:userActions.menuItems.impersonate.title')}</Typography>
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={tooltipTitle} disableHoverListener={canArchiveUser}>
          <span>
            {isActive(user) ? (
              <MenuItem
                disabled={isDataLoading || !canArchiveUser}
                onClick={() => handleUserDeactivate(user.id)}
                divider
              >
                <Typography variant="subtitle2">{t('users:userActions.menuItems.deactivate.title')}</Typography>
              </MenuItem>
            ) : (
              <MenuItem disabled={isDataLoading || !canArchiveUser} onClick={() => handleUserActivate(user.id)} divider>
                <Typography variant="subtitle2">{t('users:userActions.menuItems.activate.title')}</Typography>
              </MenuItem>
            )}
          </span>
        </Tooltip>
      </Menu>
    </>
  );
};

export default UserActionsMenu;
