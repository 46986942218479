import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, ClickAwayListener, Menu, MenuItem, Popover, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Calendar from 'components/common/Calendar';
import { CalendarInteractionMode } from 'components/common/Calendar/enums';
import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import YearSwitcher from 'components/common/YearSwitcher';

import { useUpdateHolidayCalendarDayMutation } from 'domain/holiday/calendar/day/apiSlice';
import { DayType } from 'domain/holiday/calendar/day/enums';
import {
  initialValues,
  formatAttributesToSubmit,
  buildValidationSchema,
  IUpdateHolidayCalendarDayFormData,
} from 'domain/holiday/calendar/day/schemas/update';
import {
  loadingGetHolidayCalendarsYearsParameters,
  useGetHolidayCalendarsYearsQuery,
  useLazyGetHolidayCalendarYearQuery,
} from 'domain/holiday/calendar/year/apiSlice';
import { isHolidayDay, isWorkingDay, isWeekendDay } from 'domain/holiday/calendar/year/service';
import { ICalendarDay } from 'domain/holiday/calendar/year/types';

import { useNotifications } from 'hooks/useNotifications';

import { getCurrentYear, getWeekStartsOn } from 'utils/calendar';
import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import CalendarList from './components/CalendarList';
import styles from './styles';

const WorkingCalendars: FC = () => {
  const [year, setYear] = useState(getCurrentYear());
  const [selectedYearId, setSelectedYearId] = useState<number | null>(null);
  const { t } = useTranslation('adminPanel');
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [dayActionsMenu, setDayActionsMenu] = useState<null | HTMLElement>(null);
  const [clickedDayDetails, setClickedDayDetails] = useState<ICalendarDay | null>(null);
  const isDayActionsMenuOpen = Boolean(dayActionsMenu);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

  const {
    isError: isGetHolidayCalendarsYearsError,
    data: holidayCalendarsYears,
    isSuccess: holidayCalendarsYearsSuccess,
  } = useGetHolidayCalendarsYearsQuery(
    loadingGetHolidayCalendarsYearsParameters({
      yearEq: year,
    }),
  );

  const [
    getHolidayCalendarYear,
    { isError: isGetHolidayCalendarYearError, data: holidayCalendarYear, isSuccess: isGetHolidayCalendarYearSuccess },
  ] = useLazyGetHolidayCalendarYearQuery();

  const [updateHolidayCalendarDay, { isLoading: isUpdateHolidayCalendarDayLoading }] =
    useUpdateHolidayCalendarDayMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IUpdateHolidayCalendarDayFormData>({
    defaultValues: initialValues(clickedDayDetails),
    resolver: yupResolver(buildValidationSchema()),
  });

  useEffect(() => {
    if (holidayCalendarsYearsSuccess && !_.isEmpty(holidayCalendarsYears?.years)) {
      const yearId = selectedYearId ?? _.head(holidayCalendarsYears?.years).id;
      setSelectedYearId(yearId);
    }
  }, [holidayCalendarsYears]);

  useEffect(() => {
    if (selectedYearId) {
      void getHolidayCalendarYear(selectedYearId);
    }
  }, [selectedYearId]);

  const handleYearSelect = (yearId: number | null) => {
    setSelectedYearId(yearId);
  };

  const handleDayActionsClick = (event: React.MouseEvent<HTMLButtonElement>, dayDetails: ICalendarDay) => {
    setDayActionsMenu(event.currentTarget);
    setClickedDayDetails(dayDetails);
  };

  const handleDayActionsClose = () => {
    setDayActionsMenu(null);
    setClickedDayDetails(null);
  };

  const handleUpdateHolidayCalendarDay = async (formData: IUpdateHolidayCalendarDayFormData) => {
    try {
      handleDayActionsClose();
      setIsFormVisible(false);
      await updateHolidayCalendarDay(formatAttributesToSubmit(formData, clickedDayDetails.id));
      showSuccessNotification();
      setValue('description', '');
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  if (isGetHolidayCalendarsYearsError || isGetHolidayCalendarYearError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  if (!isGetHolidayCalendarYearSuccess) {
    return <Loader />;
  }

  const isDayWeekend = isWeekendDay(clickedDayDetails);
  const isDayHoliday = isHolidayDay(clickedDayDetails);
  const isDayWorking = isWorkingDay(clickedDayDetails);

  return (
    <Div sx={styles.container}>
      <Div sx={styles.wrapper}>
        <Div sx={styles.yearInfo}>
          <Div sx={styles.yearSwitcher}>
            <YearSwitcher currentYear={year} onYearChange={year => setYear(year)} />
          </Div>
          <CalendarList
            years={holidayCalendarsYears?.years}
            selectedYearId={selectedYearId}
            onYearSelect={handleYearSelect}
          />
        </Div>
        <Div sx={styles.calendar}>
          {_.isEmpty(holidayCalendarsYears?.years) ? (
            <Typography>{t('workingCalendars.noCalendars', { yearNumber: year })}</Typography>
          ) : (
            <Calendar
              year={year}
              interactionMode={CalendarInteractionMode.singleDay}
              monthsInfo={holidayCalendarYear?.year?.months}
              holidays={holidayCalendarYear?.year?.days}
              weekStartsOn={getWeekStartsOn()}
              onDayClick={handleDayActionsClick}
            />
          )}
        </Div>
        <Menu anchorEl={dayActionsMenu} open={isDayActionsMenuOpen} onClose={handleDayActionsClose}>
          {!isDayHoliday && (
            <MenuItem
              onClick={() => {
                setIsFormVisible(true);
              }}
            >
              <Typography variant="subtitle1">{t('workingCalendars.setAsHoliday')}</Typography>
            </MenuItem>
          )}
          {!isDayWorking && (
            <MenuItem
              onClick={() =>
                handleUpdateHolidayCalendarDay({
                  dayType: DayType.working,
                  description: null,
                })
              }
            >
              <Typography variant="subtitle1">{t('workingCalendars.setAsWorkingDay')}</Typography>
            </MenuItem>
          )}
          {!isDayWeekend && (
            <MenuItem
              onClick={() =>
                handleUpdateHolidayCalendarDay({
                  dayType: DayType.weekend,
                  description: null,
                })
              }
            >
              <Typography variant="subtitle1">{t('workingCalendars.setAsWeekend')}</Typography>
            </MenuItem>
          )}
        </Menu>
        <Popover
          open={isDayActionsMenuOpen && isFormVisible}
          anchorEl={dayActionsMenu}
          onClose={() => {}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: { marginTop: '100px' },
          }}
        >
          <ClickAwayListener onClickAway={() => setIsFormVisible(false)}>
            <Div sx={styles.form}>
              <TextField
                inputProps={{
                  tabIndex: 0,
                }}
                sx={styles.holidayName}
                autoFocus
                error={Boolean(errors.description)}
                helperText={buildErrorMessage(t, errors.description)}
                placeholder={t('workingCalendars.typeHolidayName')}
                {...register('description')}
              />
              <Button
                variant="text"
                disabled={isUpdateHolidayCalendarDayLoading}
                onClick={handleSubmit(handleUpdateHolidayCalendarDay)}
              >
                <Typography variant="subtitle3">{t('workingCalendars.save')}</Typography>
              </Button>
              <Button
                variant="text"
                disabled={isUpdateHolidayCalendarDayLoading}
                sx={styles.deleteButton}
                onClick={() => setIsFormVisible(false)}
              >
                <Typography variant="subtitle3">{t('workingCalendars.cancel')}</Typography>
              </Button>
            </Div>
          </ClickAwayListener>
        </Popover>
      </Div>
    </Div>
  );
};

export default WorkingCalendars;
