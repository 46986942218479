import { SxStyles } from 'theme';

const styles: SxStyles = {
  changeBudgetButtonBlock: {
    display: 'flex',
  },
  changeBudgetButton: {
    padding: 0,
    marginRight: '4px',
  },
  changeBudgetButtonHours: {
    minWidth: '30px',
    color: 'text.primary',
    borderBottomWidth: '1px',
    borderBottomStyle: 'dashed',
    borderBottomColor: 'border.blue',
  },
  changeBudgetButtonHoursEmpty: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
