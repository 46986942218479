import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingTop: '10px',
  },
  input: {
    minWidth: '400px',
    width: '100%',
    marginBottom: '32px',
  },
  placeholder: {
    color: 'text.gray',
  },
  groupFields: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePicker: {
    marginTop: '16px',
    marginBottom: '32px',
  },
  startDate: {
    marginRight: '16px',
  },
  updateButton: {
    marginRight: '16px',
  },
};

export default styles;
