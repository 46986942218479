import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IGroup } from 'domain/group/types';
import { IProjectResponse } from 'domain/project/apiSlice';
import { PeriodType } from 'domain/project/enums';
import { ITechnology } from 'domain/technology/types';

import { IUpdateProjectFormData, IUpdateProjectFormDataToSubmit } from './update';

const requiredValidation = 'forms:validation:required';

export const buildValidationFields = () => ({
  name: yup
    .string()
    .max(30, i18n.t('forms:validation:maxSymbols', { count: 30 }))
    .required(i18n.t(requiredValidation))
    .default(''),
  description: yup
    .string()
    .max(300, i18n.t('forms:validation:maxSymbols', { count: 300 }))
    .default(''),
  groups: yup.array().of(yup.mixed<IGroup>()).required(i18n.t(requiredValidation)).default([]),
  periodType: yup.mixed<PeriodType>().required(i18n.t(requiredValidation)).default(PeriodType.ongoing),
  technologies: yup.array().of(yup.mixed<ITechnology>()).required(i18n.t(requiredValidation)).default([]),
  billable: yup.boolean().default(true),
  taskRequired: yup.boolean().default(false),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (values: IProjectResponse) => {
  if (_.isNil(values)) {
    return buildValidationSchema().getDefault();
  }

  const { name, description, periodType, billable, taskRequired, groups, technologies } = values.project;

  return {
    name,
    description: description ?? '',
    groups,
    periodType,
    technologies,
    billable,
    taskRequired,
  };
};

export const formatAttributesToSubmit = (
  values: IUpdateProjectFormData,
  projectId: number,
): IUpdateProjectFormDataToSubmit => {
  const { name, description, groups, technologies, periodType, billable, taskRequired } = values;

  const technologyIds = technologies.map(technology => technology.id);
  const groupIds = groups.map(group => group.id);

  return {
    projectId,
    formData: {
      name,
      description,
      periodType,
      billable,
      taskRequired,
      technologyIds,
      groupIds,
    },
  };
};
