import { SxStyles } from 'theme';

const styles: SxStyles = {
  paper: {
    width: '580px',
    padding: '56px 40px 40px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyingType: {
    marginTop: '56px',
  },
  copyingTypeTitle: {
    marginBottom: '24px',
  },
  usersTitle: {
    marginBottom: '24px',
  },
  users: {
    overflow: 'scroll',
  },
  buttons: {
    marginTop: 'auto',
    paddingTop: '48px',
  },
  copyButton: {
    marginRight: '12px',
  },
  closeButton: {
    color: 'text.gray',
    opacity: 0.5,

    '&:hover': {
      opacity: 1,
    },
  },
};

export default styles;
