import { FC } from 'react';

import { Typography } from '@mui/material';

import styles from './styles';
import { IReportVacationsCalendarTableHeaderTotalCellProps } from './types';

const TableHeaderTotalCell: FC<IReportVacationsCalendarTableHeaderTotalCellProps> = props => {
  const { title } = props;

  return (
    <Typography sx={styles.root} variant="h6">
      {title}
    </Typography>
  );
};

export default TableHeaderTotalCell;
