import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    gap: '40px',
    minHeight: '100%',
  },
  specificationsTreeContainer: {
    marginTop: '20px',
  },
};

export default styles;
