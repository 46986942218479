import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '8px 16px',
    marginBottom: '20px',
  },
  starIcon: {
    marginRight: '8px',
    color: 'primary.blue',
    width: '18px',
    height: '18px',
  },
  description: {
    maxWidth: '400px',
    minWidth: '250px',
    marginLeft: '16px',
  },
  buttons: {
    display: 'flex',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '8px',
  },
  project: {
    marginLeft: '10px',
    '& .MuiTypography-root': {
      maxWidth: '100px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  tagsButton: {
    marginLeft: '10px',
    display: 'inline-block',
    borderColor: 'border.whiteBlue',
    backgroundColor: 'border.whiteBlue',
    borderRadius: '6px',
    border: 'none',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  duration: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '10px',
    marginTop: '5px',
  },
};

export default styles;
