import { FC } from 'react';

import { Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import {
  isVacationResourceApproved,
  isVacationResourceDeclined,
  makeNewVacation,
  makeOnApprovalVacations,
} from 'domain/resource/vacation/service';

import { usePermissions } from 'hooks/usePermissions';

import Vacation from './components/Vacation';
import styles from './styles';
import { IOnApprovalProps } from './types';

const Vacations: FC<IOnApprovalProps> = props => {
  const { vacations, startSelection, endSelection, resourceId, daysInfo } = props;
  const { isRoleFeatureAllowed } = usePermissions();
  const canApproveVacation = isRoleFeatureAllowed('canApproveVacation');
  const { t } = useTranslation('userProfile');

  const newVacation = makeNewVacation(startSelection, endSelection);
  const onApprovalVacations = makeOnApprovalVacations(vacations, newVacation);
  const approvedVacations = vacations.filter(vacation => isVacationResourceApproved(vacation));
  const declinedVacations = vacations.filter(vacation => isVacationResourceDeclined(vacation));

  return (
    <Div>
      {!_.isEmpty(onApprovalVacations) && (
        <>
          <Typography sx={styles.header} variant="body1">
            {t('vacation.onApproval')}
          </Typography>
          {onApprovalVacations.map(vacation => (
            <Vacation key={vacation.id} vacation={vacation} resourceId={resourceId} daysInfo={daysInfo} />
          ))}
        </>
      )}

      {!_.isEmpty(approvedVacations) && canApproveVacation && (
        <>
          <Typography sx={styles.header} variant="body1">
            {t('vacation.approved')}
          </Typography>
          {approvedVacations.map(vacation => (
            <Vacation key={vacation.id} vacation={vacation} resourceId={resourceId} daysInfo={daysInfo} />
          ))}
        </>
      )}

      {!_.isEmpty(declinedVacations) && (
        <>
          <Typography sx={styles.header} variant="body1">
            {t('vacation.declined')}
          </Typography>
          {declinedVacations.map(vacation => (
            <Vacation key={vacation.id} vacation={vacation} resourceId={resourceId} daysInfo={daysInfo} />
          ))}
        </>
      )}
    </Div>
  );
};

export default Vacations;
