import { FC } from 'react';

import Div from 'components/common/Div';
import YearSwitcher from 'components/common/YearSwitcher';

import { getCurrentYear } from 'utils/calendar';

import TableLegend from './components/TableLegend';
import styles from './styles';
import { ITableVacationsInfo } from './types';

const Info: FC<ITableVacationsInfo> = props => {
  const { year, setYear } = props;

  const isDisabled = year === getCurrentYear() + 1;

  return (
    <Div sx={styles.root}>
      <TableLegend />
      <YearSwitcher isDisabledNext={isDisabled} currentYear={year} onYearChange={year => setYear(year)} />
    </Div>
  );
};

export default Info;
