import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    padding: '0 24px 24px 24px',
  },
  items: {
    border: theme => `1px solid ${theme.palette.border.main}`,
    borderTop: 'none',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
};

export default styles;
