import { SxStyles } from 'theme';

const styles: SxStyles = {
  endDatePicker: {
    paddingTop: '8px',
  },
  buttons: {
    marginTop: '32px',
  },
  removeButton: {
    marginRight: '10px',
  },
};

export default styles;
