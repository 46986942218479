import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    position: 'relative',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    backgroundColor: 'background.white',
  },
  rootTable: {
    overflowX: 'auto',
    position: 'relative',
  },
  table: {
    width: 'auto',
    borderCollapse: 'separate',
  },
  tableLoading: {
    display: 'none',
  },
  withoutBorderHeaderRow: {
    borderBottom: 'none',
  },
  thirdHeaderRow: {
    borderBottom: theme => `3px solid ${theme.palette.border.main}`,
  },
  column: {
    padding: '8px',
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
    color: 'text.gray',
  },
  lastColumn: {
    borderRight: 'none',
  },
  lastRow: {
    borderBottom: 'none',
  },
  resourceHeaderCell: {
    textAlign: 'center',
  },
  leftStickyGroup: {
    position: 'sticky',
    left: 0,
    top: 0,
    backgroundColor: 'background.white',
  },
  monthHeaderColumn: {
    textAlign: 'center',
    borderRightWidth: '3px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
  },
  dayColumn: {
    textAlign: 'center',
  },
  dayColumnCompleted: {
    backgroundColor: 'background.lightDarkGreen',
  },
  dayColumnApproved: {
    backgroundColor: 'background.mint',
  },
  dayColumnRequested: {
    backgroundColor: 'background.orange',
  },
  dayColumnCurrent: {
    backgroundColor: 'background.lightBlue',
  },
  dayHeaderColumnCurrent: {
    backgroundColor: 'primary.blue',
    color: 'text.white',
  },
  dayWeekendColumn: {
    backgroundColor: 'border.lightGray',
  },
  vacationColumn: {
    backgroundColor: 'background.lightOrange',
  },
  firstDayVacationColumn: {
    borderLeftColor: 'border.orange',
    borderLeftWidth: '2px',
    borderLeftStyle: 'solid',
  },
  lastDayVacationColumn: {
    borderRightColor: 'border.orange',
    borderRightWidth: '2px',
    borderRightStyle: 'solid',
  },
  dayLastOfMonthColumn: {
    borderRightWidth: '3px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
    '&:last-pf-type': {
      borderRight: 'none',
    },
  },
};

export default styles;
