import { SxStyles } from 'theme';

const styles: SxStyles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '36px',
  },
  smartFilter: {
    marginBottom: '36px',
    marginTop: '16px',
  },
  container: {
    marginTop: '16px',
  },
  resourceColumn: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  titleColumn: {
    textTransform: 'capitalize',
  },
  clearIcon: {
    color: 'text.red',
    fontSize: '20px',
  },
  editIcon: {
    color: 'text.gray',
    fontSize: '20px',
  },
  activeIcon: {
    fontSize: '20px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default styles;
