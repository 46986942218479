import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    gap: '40px',
    minHeight: '100%',
  },
  forbiddenWordsContainer: {
    marginTop: '20px',
    flexGrow: '1',
  },
  deleteButton: {
    color: 'text.red',
  },
  textContainer: {
    cursor: 'pointer',
    padding: '0 8px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'background.gray',
      transition: 'background-color 0.2s linear',
    },
    '&:hover div[data-actions=item-actions]': {
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0.2s linear, opacity 0.2s linear',
    },
  },
  listPointer: {
    width: '5px',
    height: '5px',
    color: 'text.lightGray',
  },
  listItemContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '40px',
    gap: '6px',
    padding: '4px 0',
  },
  deleteWord: {
    backgroundColor: 'background.gray',
    transition: 'background-color 0.2s linear',
    padding: '8px',
    borderRadius: '4px',
    gap: '0 8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    visibility: 'hidden',
  },
};

export default styles;
