import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    maxWidth: '280px',
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'background.black',
    borderRadius: '12px 0 0 12px',
    display: 'flex',
    justifyContent: 'center',
  },
  logoBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
  },
  imageBlock: {
    marginBottom: '46px',
    width: '120px',
    height: '120px',
    backgroundImage: 'url("/images/bigDbpLogo.png")',
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '6px',
  },
  title: {
    color: 'primary.contrastText',
    marginRight: '4px',
  },
  xBlock: {
    width: '19px',
    height: '19px',
  },
  xIcon: {
    width: '19px',
    height: '19px',
  },
  divider: {
    height: '2px',
    width: '100%',
    background: 'linear-gradient(90deg, rgba(0,90,240,1) 0%, rgba(0,171,255,1) 100%)',
  },
};

export default styles;
