import _ from 'lodash';

import { IReportTrackedTimeEntry } from 'domain/report/trackedTimeEntry/types';

import { GroupingOption } from 'enums/GroupingOption';

const mapGroupingOptionToEntryIdKey = {
  [GroupingOption.user]: 'resourceId',
  [GroupingOption.project]: 'trackableId',
};

export const getEntryId = (
  reportTrackedTimeEntriesGroup: Array<IReportTrackedTimeEntry>,
  groupingOption: GroupingOption,
): number => {
  const firstEntry = reportTrackedTimeEntriesGroup.at(0);
  return _.get(firstEntry, [mapGroupingOptionToEntryIdKey[groupingOption]]);
};
