import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '816px',
    paddingTop: '8px',
    gap: '16px',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  nameField: {
    width: 'calc(50% - 16px)',
  },
  periodTypeField: {
    width: 'calc(25% - 16px)',
  },
  monthlyBudgetField: {
    width: 'calc(25% - 16px)',
  },
  descriptionField: {
    width: '100%',
  },
  field: {
    width: '400px',
  },
  technologiesField: {
    width: '100%',
  },
  groupsField: {
    width: '100%',
  },
  shortField: {
    width: '192px',
  },
  placeholder: {
    color: 'text.gray',
  },
  buttons: {
    marginTop: '32px',
  },
  createProjectButton: {
    marginRight: '10px',
  },
  clearButton: {
    width: '24px',
    height: '24px',
  },
  clearIcon: {
    width: '17px',
    height: '17px',
    stroke: theme => theme.palette.primary.gray,
  },
  switches: {
    paddingLeft: '11px',
  },
};

export default styles;
