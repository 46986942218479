import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
  },
  divider: {
    margin: '0 30px',
    width: '3px',
  },
  applyButton: {
    width: '195px',
    height: '40px',
  },
};

export default styles;
