import { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import styles from './styles';

const VacationRequestItemsHeader: FC = () => {
  const { t } = useTranslation('reportVacations');

  return (
    <Div sx={styles.root}>
      <Div sx={styles.expand} />
      <Div sx={styles.date}>
        <Typography variant="subtitle2">{t('vacationRequests.requestDate')}</Typography>
      </Div>
      <Div sx={styles.resource}>
        <Typography variant="subtitle2">{t('vacationRequests.resource')}</Typography>
      </Div>
      <Div sx={styles.specialization}>
        <Typography variant="subtitle2">{t('vacationRequests.specialization')}</Typography>
      </Div>
      <Div sx={styles.vacationRange}>
        <Typography variant="subtitle2">{t('vacationRequests.vacationRange')}</Typography>
      </Div>
      <Div sx={styles.buttons}></Div>
    </Div>
  );
};

export default VacationRequestItemsHeader;
