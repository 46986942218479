import { SxStyles } from 'theme';

const styles: SxStyles = {
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    gap: '40px',
    minHeight: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  settingBody: {
    display: 'flow',
  },
  settingName: {
    borderBottom: '1px',
    padding: '8px',
    text: 'bold',
    fontSize: '18px',
  },
  settingValue: {
    borderBottom: '1px',
    fontSize: '15px',
  },
};

export default styles;
