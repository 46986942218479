import { SxStyles } from 'theme';

const styles: SxStyles = {
  header: {
    marginBottom: '32px',
  },
  container: {
    backgroundColor: 'background.white',
    border: '1px solid',
    borderRadius: '8px',
    borderColor: 'border.main',
    padding: '16px',
  },
  tabHeaders: {
    textTransform: 'capitalize',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'text.black',
  },
  tabHeadersContainer: {
    borderBottom: '1px solid',
    borderColor: 'border.blue',
  },
  tabContentContainer: {
    padding: '32px 40px 0 40px',
    minHeight: '100%',
  },
};

export default styles;
