import { useMemo } from 'react';

import camelize from 'camelize';
import { parse, stringify } from 'qs';
import { useParams, useLocation, useNavigate, matchPath } from 'react-router-dom';

import { IUseRouter } from './types';

const buildPath = (basePath: string, parameters: Record<string, unknown>): string => {
  return `${basePath}${stringify(parameters, { skipNulls: true, addQueryPrefix: true })}`;
};

const useRouter = (): IUseRouter => {
  const parameters = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const replaceLocation = (path: string) => navigate(path, { replace: true });

  return useMemo(() => {
    const camelizedQuery: Params = camelize({
      ...parse(location.search, { ignoreQueryPrefix: true }),
      ...parameters,
    });
    const queryStringUpdate = (parameters: Record<string, unknown>): void => {
      replaceLocation(buildPath(location.pathname, parameters));
    };
    const queryStringClear = (): void => {
      replaceLocation(buildPath(location.pathname, {}));
    };
    return {
      push: navigate,
      replaceLocation,
      pathname: location.pathname,
      camelizedQuery,
      matchPath,
      location,
      navigate,
      parameters,
      buildPath,
      queryStringUpdate,
      queryStringClear,
    };
  }, [parameters, location, navigate]);
};

export default useRouter;
