import { SxStyles } from 'theme';

const styles: SxStyles = {
  row: {
    display: 'flex',
    margin: '0px 20px 20px 24px',
    height: '56px',
    width: '500px',
    borderRadius: '8px',
  },
};

export default styles;
