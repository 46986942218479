import _ from 'lodash';

import { getFullName } from 'utils/person';

import { IReportTrackedTimeEntry } from './types';

export interface IResourceTrackedTimeEntriesGrouped {
  [name: string]: Array<IReportTrackedTimeEntry>;
}

export const getReportTrackedTimeEntriesGroupedByUser = (
  reportTrackedTimeEntries: Array<IReportTrackedTimeEntry>,
): IResourceTrackedTimeEntriesGrouped => {
  const flattenReports = reportTrackedTimeEntries.flatMap(element => Object.values(element)).flat();
  return _.groupBy(flattenReports, entry => getFullName(_.get(entry, ['user'])));
};

export const getReportTrackedTimeEntriesGroupedByProject = (
  reportTrackedTimeEntries: Array<IReportTrackedTimeEntry>,
): IResourceTrackedTimeEntriesGrouped => {
  const flattenReports = reportTrackedTimeEntries.flatMap(element => Object.values(element)).flat();
  return _.groupBy(flattenReports, 'trackableName');
};

export const getReportTotalTrackedTimeEntriesGroupDuration = (
  reportTrackedTimeEntries: Array<IReportTrackedTimeEntry>,
): number =>
  reportTrackedTimeEntries.reduce((total, reportTrackedTimeEntry) => (total += reportTrackedTimeEntry.duration), 0);

export const sortByDate = <T>(collection: T[]) => _.sortBy(collection, entry => _.get(entry, ['date']));
