import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    maxWidth: '90px',
    width: '100%',
    color: 'text.whiteBlue',
    opacity: 0.75,
    margin: '0 10px',
  },
  avatar: {
    width: '32px',
    height: '32px',
  },
};

export default styles;
