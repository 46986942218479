/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /rails/mailers(.:format) */
export function railsMailersPath(options?: object): string {
  return "/" + "rails" + "/" + "mailers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/properties(.:format) */
export function railsInfoPropertiesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/routes(.:format) */
export function railsInfoRoutesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "routes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info(.:format) */
export function railsInfoPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /sidekiq */
export function sidekiqWebPath(options?: object): string {
  return "/" + "sidekiq" + $buildOptions(options, []);
}

/** /api-docs */
export function rswagUiPath(options?: object): string {
  return "/" + "api-docs" + $buildOptions(options, []);
}

/** /api-docs */
export function rswagApiPath(options?: object): string {
  return "/" + "api-docs" + $buildOptions(options, []);
}

/** /auth/google_oauth2/callback(.:format) */
export function authGoogleOauth2CallbackPath(options?: object): string {
  return "/" + "auth" + "/" + "google_oauth2" + "/" + "callback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /auth/google_oauth2/logout(.:format) */
export function authGoogleOauth2LogoutPath(options?: object): string {
  return "/" + "auth" + "/" + "google_oauth2" + "/" + "logout" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/groups(.:format) */
export function apiV1MobileGroupsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "groups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/resources(.:format) */
export function apiV1MobileResourcesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "resources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/tasks(.:format) */
export function apiV1MobileTasksPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "tasks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/technologies(.:format) */
export function apiV1MobileTechnologiesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "technologies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/accounts/google/sign_in(.:format) */
export function apiV1MobileAccountsGoogleSignInPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "accounts" + "/" + "google" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/accounts/google/callback(.:format) */
export function apiV1MobileAccountsGoogleCallbackPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "accounts" + "/" + "google" + "/" + "callback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/accounts/google/exchange_token(.:format) */
export function apiV1MobileAccountsGoogleExchangeTokenPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "accounts" + "/" + "google" + "/" + "exchange_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/accounts/google/access_token(.:format) */
export function apiV1MobileAccountsGoogleAccessTokenPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "accounts" + "/" + "google" + "/" + "access_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/accounts/sign_out(.:format) */
export function apiV1MobileAccountsSignOutPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "accounts" + "/" + "sign_out" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/accounts/token/google/refresh_token(.:format) */
export function apiV1MobileAccountsTokenGoogleRefreshTokenPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "accounts" + "/" + "token" + "/" + "google" + "/" + "refresh_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/exports/:id(.:format) */
export function apiV1MobileExportPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "exports" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/mobile/user/profile(.:format) */
export function apiV1MobileUserProfilePath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "user" + "/" + "profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/user/projects/:project_id/tasks(.:format) */
export function apiV1MobileUserProjectTasksPath(project_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "user" + "/" + "projects" + "/" + project_id + "/" + "tasks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id", "format"]);
}

/** /api/v1/mobile/user/projects(.:format) */
export function apiV1MobileUserProjectsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "user" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/user/tracked_hours(.:format) */
export function apiV1MobileUserTrackedHoursPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "user" + "/" + "tracked_hours" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/user/tracked_hours/:id(.:format) */
export function apiV1MobileUserTrackedHourPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "user" + "/" + "tracked_hours" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/mobile/user/trackables(.:format) */
export function apiV1MobileUserTrackablesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "user" + "/" + "trackables" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/user/tags(.:format) */
export function apiV1MobileUserTagsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "user" + "/" + "tags" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/user/vacations/:id/approve(.:format) */
export function approveApiV1MobileUserVacationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "user" + "/" + "vacations" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/mobile/user/vacations(.:format) */
export function apiV1MobileUserVacationsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "user" + "/" + "vacations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/user/vacations/:id(.:format) */
export function apiV1MobileUserVacationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "user" + "/" + "vacations" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/mobile/reports/hours_by_projects(.:format) */
export function apiV1MobileReportsHoursByProjectsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "hours_by_projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/reports/hours_by_hierarchy(.:format) */
export function apiV1MobileReportsHoursByHierarchyIndexPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "hours_by_hierarchy" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/reports/hours_by_projects/create_csv(.:format) */
export function createCsvApiV1MobileReportsHoursByProjectsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "hours_by_projects" + "/" + "create_csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/reports/hours_by_projects/create_xlsx(.:format) */
export function createXlsxApiV1MobileReportsHoursByProjectsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "hours_by_projects" + "/" + "create_xlsx" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/reports/hours_by_resources/create_csv(.:format) */
export function createCsvApiV1MobileReportsHoursByResourcesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "hours_by_resources" + "/" + "create_csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/reports/hours_by_resources/create_xlsx(.:format) */
export function createXlsxApiV1MobileReportsHoursByResourcesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "hours_by_resources" + "/" + "create_xlsx" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/reports/hours_by_resources(.:format) */
export function apiV1MobileReportsHoursByResourcesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "hours_by_resources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/reports/tracked_time_entries/create_csv(.:format) */
export function createCsvApiV1MobileReportsTrackedTimeEntriesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "tracked_time_entries" + "/" + "create_csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/reports/tracked_time_entries/create_xlsx(.:format) */
export function createXlsxApiV1MobileReportsTrackedTimeEntriesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "tracked_time_entries" + "/" + "create_xlsx" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/reports/tracked_time_entries(.:format) */
export function apiV1MobileReportsTrackedTimeEntriesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "tracked_time_entries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/reports/filters(.:format) */
export function apiV1MobileReportsFiltersPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "filters" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/mobile/reports/filters/:id(.:format) */
export function apiV1MobileReportsFilterPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "mobile" + "/" + "reports" + "/" + "filters" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/reports/hours_by_projects/create_csv(.:format) */
export function createCsvApiV1ReportsHoursByProjectsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "hours_by_projects" + "/" + "create_csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/hours_by_projects/create_xlsx(.:format) */
export function createXlsxApiV1ReportsHoursByProjectsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "hours_by_projects" + "/" + "create_xlsx" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/hours_by_projects/:id/create_csv(.:format) */
export function createCsvApiV1ReportsHoursByProjectPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "hours_by_projects" + "/" + id + "/" + "create_csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/reports/hours_by_projects/:id/create_xlsx(.:format) */
export function createXlsxApiV1ReportsHoursByProjectPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "hours_by_projects" + "/" + id + "/" + "create_xlsx" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/reports/hours_by_projects(.:format) */
export function apiV1ReportsHoursByProjectsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "hours_by_projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/hours_by_projects/:id(.:format) */
export function apiV1ReportsHoursByProjectPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "hours_by_projects" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/reports/vacations(.:format) */
export function apiV1ReportsVacationsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "vacations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/vacations_requests/:vacations_request_id/projects(.:format) */
export function apiV1ReportsVacationsRequestProjectsPath(vacations_request_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "vacations_requests" + "/" + vacations_request_id + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["vacations_request_id", "format"]);
}

/** /api/v1/reports/vacations_requests/:vacations_request_id/specializations(.:format) */
export function apiV1ReportsVacationsRequestSpecializationsPath(vacations_request_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "vacations_requests" + "/" + vacations_request_id + "/" + "specializations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["vacations_request_id", "format"]);
}

/** /api/v1/reports/vacations_requests(.:format) */
export function apiV1ReportsVacationsRequestsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "vacations_requests" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/users_migrations(.:format) */
export function apiV1ReportsUsersMigrationsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "users_migrations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["start_date", 'page', 'per_page']);
}

/** /api/v1/reports/users_migrations/:id(.:format) */
export function apiV1ReportsUsersMigrationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "users_migrations" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/reports/hours_by_resources/create_csv(.:format) */
export function createCsvApiV1ReportsHoursByResourcesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "hours_by_resources" + "/" + "create_csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/hours_by_resources/create_xlsx(.:format) */
export function createXlsxApiV1ReportsHoursByResourcesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "hours_by_resources" + "/" + "create_xlsx" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/hours_by_resources(.:format) */
export function apiV1ReportsHoursByResourcesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "hours_by_resources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/tracked_time_entries/tasks(.:format) */
export function apiV1ReportsTrackedTimeEntriesTasksPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "tracked_time_entries" + "/" + "tasks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/tracked_time_entries/create_csv(.:format) */
export function createCsvApiV1ReportsTrackedTimeEntriesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "tracked_time_entries" + "/" + "create_csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/tracked_time_entries/create_xlsx(.:format) */
export function createXlsxApiV1ReportsTrackedTimeEntriesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "tracked_time_entries" + "/" + "create_xlsx" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/tracked_time_entries/users_list(.:format) */
export function usersListApiV1ReportsTrackedTimeEntriesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "tracked_time_entries" + "/" + "users_list" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/tracked_time_entries(.:format) */
export function apiV1ReportsTrackedTimeEntriesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "tracked_time_entries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/allocations/create_csv(.:format) */
export function createCsvApiV1ReportsAllocationsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "allocations" + "/" + "create_csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/allocations/create_xlsx(.:format) */
export function createXlsxApiV1ReportsAllocationsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "allocations" + "/" + "create_xlsx" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/allocations(.:format) */
export function apiV1ReportsAllocationsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "allocations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/filters(.:format) */
export function apiV1ReportsFiltersPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "filters" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/reports/filters/:id(.:format) */
export function apiV1ReportsFilterPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "reports" + "/" + "filters" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/exports/:id(.:format) */
export function apiV1ExportPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "exports" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/current_user/impersonation(.:format) */
export function apiV1CurrentUserImpersonationPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "current_user" + "/" + "impersonation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/current_user/permissions(.:format) */
export function apiV1CurrentUserPermissionsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "current_user" + "/" + "permissions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/current_user(.:format) */
export function apiV1CurrentUserPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "current_user" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/users/:id/activate(.:format) */
export function activateApiV1UserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/users/:user_id/hours_per_weeks(.:format) */
export function apiV1UserHoursPerWeeksPath(user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + user_id + "/" + "hours_per_weeks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id", "format"]);
}

/** /api/v1/users/:user_id/hours_per_weeks/:id(.:format) */
export function apiV1UserHoursPerWeekPath(user_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + user_id + "/" + "hours_per_weeks" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id", "id", "format"]);
}

/** /api/v1/users/:user_id/expected_billable_time_percents(.:format) */
export function apiV1UserExpectedBillableTimePercentsPath(user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + user_id + "/" + "expected_billable_time_percents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id", "format"]);
}

/** /api/v1/users/:user_id/expected_billable_time_percents/:id(.:format) */
export function apiV1UserExpectedBillableTimePercentPath(user_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + user_id + "/" + "expected_billable_time_percents" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id", "id", "format"]);
}

/** /api/v1/users/:user_id/profile/projects(.:format) */
export function apiV1UserProfileProjectsPath(user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + user_id + "/" + "profile" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id", "format"]);
}

/** /api/v1/users/:user_id/profile/stack(.:format) */
export function apiV1UserProfileStackIndexPath(user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + user_id + "/" + "profile" + "/" + "stack" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id", "format"]);
}

/** /api/v1/users/:user_id/profile/access_levels(.:format) */
export function apiV1UserProfileAccessLevelsPath(user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + user_id + "/" + "profile" + "/" + "access_levels" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id", "format"]);
}

/** /api/v1/users/:user_id/profile/service_accesses/:id/activate(.:format) */
export function activateApiV1UserProfileServiceAccessPath(user_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + user_id + "/" + "profile" + "/" + "service_accesses" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id", "id", "format"]);
}

/** /api/v1/users/:user_id/profile/service_accesses(.:format) */
export function apiV1UserProfileServiceAccessesPath(user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + user_id + "/" + "profile" + "/" + "service_accesses" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id", "format"]);
}

/** /api/v1/users/:user_id/profile/service_accesses/:id(.:format) */
export function apiV1UserProfileServiceAccessPath(user_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + user_id + "/" + "profile" + "/" + "service_accesses" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id", "id", "format"]);
}

/** /api/v1/users(.:format) */
export function apiV1UsersPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/users/:id(.:format) */
export function apiV1UserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/companies(.:format) */
export function apiV1CompaniesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/companies/:id(.:format) */
export function apiV1CompanyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "companies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/company/settings(.:format) */
export function apiV1CompanySettingsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "company" + "/" + "settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/company/new(.:format) */
export function newApiV1CompanyPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "company" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/company/edit(.:format) */
export function editApiV1CompanyPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "company" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/projects/:id/activate(.:format) */
export function activateApiV1ProjectPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "projects" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/projects/:project_id/budgets/months(.:format) */
export function apiV1ProjectBudgetsMonthsPath(project_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "projects" + "/" + project_id + "/" + "budgets" + "/" + "months" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id", "format"]);
}

/** /api/v1/projects/:project_id/budgets/months/:id(.:format) */
export function apiV1ProjectBudgetsMonthPath(project_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "projects" + "/" + project_id + "/" + "budgets" + "/" + "months" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id", "id", "format"]);
}

/** /api/v1/projects/:project_id/tasks/:id/activate(.:format) */
export function activateApiV1ProjectTaskPath(project_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "projects" + "/" + project_id + "/" + "tasks" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id", "id", "format"]);
}

/** /api/v1/projects/:project_id/tasks(.:format) */
export function apiV1ProjectTasksPath(project_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "projects" + "/" + project_id + "/" + "tasks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id", "format"]);
}

/** /api/v1/projects/:project_id/tasks/:id(.:format) */
export function apiV1ProjectTaskPath(project_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "projects" + "/" + project_id + "/" + "tasks" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id", "id", "format"]);
}

/** /api/v1/projects/:project_id/allocations(.:format) */
export function apiV1ProjectAllocationsPath(project_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "projects" + "/" + project_id + "/" + "allocations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id", "format"]);
}

/** /api/v1/projects(.:format) */
export function apiV1ProjectsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/projects/:id(.:format) */
export function apiV1ProjectPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "projects" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/countries/:id/activate(.:format) */
export function activateApiV1CountryPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "countries" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/countries/:country_id/holidays(.:format) */
export function apiV1CountryHolidaysPath(country_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "countries" + "/" + country_id + "/" + "holidays" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["country_id", "format"]);
}

/** /api/v1/countries/:country_id/holidays/:id(.:format) */
export function apiV1CountryHolidayPath(country_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "countries" + "/" + country_id + "/" + "holidays" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["country_id", "id", "format"]);
}

/** /api/v1/countries(.:format) */
export function apiV1CountriesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "countries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/countries/:id(.:format) */
export function apiV1CountryPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "countries" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/groups/:id/activate(.:format) */
export function activateApiV1GroupPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "groups" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/groups(.:format) */
export function apiV1GroupsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "groups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/groups/:id(.:format) */
export function apiV1GroupPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "groups" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/resources/:id/activate(.:format) */
export function activateApiV1ResourcePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/resources/:resource_id/usages/:usage_id/technologies(.:format) */
export function apiV1ResourceUsageTechnologiesPath(resource_id: ScalarType, usage_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "usages" + "/" + usage_id + "/" + "technologies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "usage_id", "format"]);
}

/** /api/v1/resources/:resource_id/usages/:usage_id/technologies/:id(.:format) */
export function apiV1ResourceUsageTechnologyPath(resource_id: ScalarType, usage_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "usages" + "/" + usage_id + "/" + "technologies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "usage_id", "id", "format"]);
}

/** /api/v1/resources/:resource_id/usages/:usage_id/workload_plans(.:format) */
export function apiV1ResourceUsageWorkloadPlansPath(resource_id: ScalarType, usage_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "usages" + "/" + usage_id + "/" + "workload_plans" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "usage_id", "format"]);
}

/** /api/v1/resources/:resource_id/usages(.:format) */
export function apiV1ResourceUsagesPath(resource_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "usages" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "format"]);
}

/** /api/v1/resources/:resource_id/usages/:id(.:format) */
export function apiV1ResourceUsagePath(resource_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "usages" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "id", "format"]);
}

/** /api/v1/resources/:resource_id/abilities(.:format) */
export function apiV1ResourceAbilitiesPath(resource_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "abilities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "format"]);
}

/** /api/v1/resources/:resource_id/abilities/:id(.:format) */
export function apiV1ResourceAbilityPath(resource_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "abilities" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "id", "format"]);
}

/** /api/v1/resources/:resource_id/vacations/:vacation_id/approvements/:id/approve(.:format) */
export function approveApiV1ResourceVacationPath(resource_id: ScalarType, vacation_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "vacations" + "/" + vacation_id + "/" + "approvements" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "id", "format"]);
}

/** /api/v1/resources/:resource_id/vacations/:vacation_id/approvements/:id/decline(.:format) */
export function declineApiV1ResourceVacationPath(resource_id: ScalarType, vacation_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "vacations" + "/" + vacation_id + "/" + "approvements" + "/" + id + "/" + "decline" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "id", "format"]);
}

/** /api/v1/resources/:resource_id/vacations(.:format) */
export function apiV1ResourceVacationsPath(resource_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "vacations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "format"]);
}

/** /api/v1/resources/:resource_id/vacations/:id(.:format) */
export function apiV1ResourceVacationPath(resource_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "vacations" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "id", "format"]);
}

/** /api/v1/resources/:resource_id/favorite_time_entries(.:format) */
export function apiV1ResourceFavoriteTimeEntriesPath(resource_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "favorite_time_entries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "format"]);
}

/** /api/v1/resources/:resource_id/favorite_time_entries/:id(.:format) */
export function apiV1ResourceFavoriteTimeEntryPath(resource_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "favorite_time_entries" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "id", "format"]);
}

/** /api/v1/resources/:resource_id/tracked_time_entries/create_csv(.:format) */
export function createCsvApiV1ResourceTrackedTimeEntriesPath(resource_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "tracked_time_entries" + "/" + "create_csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "format"]);
}

/** /api/v1/resources/:resource_id/tracked_time_entries/create_xlsx(.:format) */
export function createXlsxApiV1ResourceTrackedTimeEntriesPath(resource_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "tracked_time_entries" + "/" + "create_xlsx" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "format"]);
}

/** /api/v1/resources/:resource_id/tracked_time_entries/:tracked_time_entry_id/tags(.:format) */
export function apiV1ResourceTrackedTimeEntryTagsPath(resource_id: ScalarType, tracked_time_entry_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "tracked_time_entries" + "/" + tracked_time_entry_id + "/" + "tags" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "tracked_time_entry_id", "format"]);
}

/** /api/v1/resources/:resource_id/tracked_time_entries/:tracked_time_entry_id/tags/:id(.:format) */
export function apiV1ResourceTrackedTimeEntryTagPath(resource_id: ScalarType, tracked_time_entry_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "tracked_time_entries" + "/" + tracked_time_entry_id + "/" + "tags" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "tracked_time_entry_id", "id", "format"]);
}

/** /api/v1/resources/:resource_id/tracked_time_entries(.:format) */
export function apiV1ResourceTrackedTimeEntriesPath(resource_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "tracked_time_entries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "format"]);
}

/** /api/v1/resources/:resource_id/tracked_time_entries/:id(.:format) */
export function apiV1ResourceTrackedTimeEntryPath(resource_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + resource_id + "/" + "tracked_time_entries" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_id", "id", "format"]);
}

/** /api/v1/resources(.:format) */
export function apiV1ResourcesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/resources/:id(.:format) */
export function apiV1ResourcePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "resources" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/technologies(.:format) */
export function apiV1TechnologiesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "technologies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/technologies/:id(.:format) */
export function apiV1TechnologyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "technologies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/phases(.:format) */
export function apiV1PhasesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "phases" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/phases/:id(.:format) */
export function apiV1PhasePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "phases" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/audits/create_csv(.:format) */
export function createCsvApiV1AuditsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "audits" + "/" + "create_csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/audits/create_xlsx(.:format) */
export function createXlsxApiV1AuditsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "audits" + "/" + "create_xlsx" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/audits(.:format) */
export function apiV1AuditsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "audits" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/access_levels/:access_level_id/technologies(.:format) */
export function apiV1AccessLevelTechnologiesPath(access_level_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "access_levels" + "/" + access_level_id + "/" + "technologies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["access_level_id", "format"]);
}

/** /api/v1/access_levels/:access_level_id/technologies/:id(.:format) */
export function apiV1AccessLevelTechnologyPath(access_level_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "access_levels" + "/" + access_level_id + "/" + "technologies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["access_level_id", "id", "format"]);
}

/** /api/v1/access_levels(.:format) */
export function apiV1AccessLevelsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "access_levels" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/access_levels/:id(.:format) */
export function apiV1AccessLevelPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "access_levels" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/tracking_types/:id/activate(.:format) */
export function activateApiV1TrackingTypePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "tracking_types" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/tracking_types(.:format) */
export function apiV1TrackingTypesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "tracking_types" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/tracking_types/:id(.:format) */
export function apiV1TrackingTypePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "tracking_types" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/vacancies/:id/activate(.:format) */
export function activateApiV1VacancyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "vacancies" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/vacancies/:vacancy_id/technologies(.:format) */
export function apiV1VacancyTechnologiesPath(vacancy_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "vacancies" + "/" + vacancy_id + "/" + "technologies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["vacancy_id", "format"]);
}

/** /api/v1/vacancies/:vacancy_id/technologies/:id(.:format) */
export function apiV1VacancyTechnologyPath(vacancy_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "vacancies" + "/" + vacancy_id + "/" + "technologies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["vacancy_id", "id", "format"]);
}

/** /api/v1/vacancies(.:format) */
export function apiV1VacanciesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "vacancies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/vacancies/:id(.:format) */
export function apiV1VacancyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "vacancies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/time_tracker/forbidden_words(.:format) */
export function apiV1TimeTrackerForbiddenWordsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "time_tracker" + "/" + "forbidden_words" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/time_tracker/forbidden_words/:id(.:format) */
export function apiV1TimeTrackerForbiddenWordPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "time_tracker" + "/" + "forbidden_words" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/time_tracker/trackables(.:format) */
export function apiV1TimeTrackerTrackablesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "time_tracker" + "/" + "trackables" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/holiday/calendars/years(.:format) */
export function apiV1HolidayCalendarsYearsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "holiday" + "/" + "calendars" + "/" + "years" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/holiday/calendars/years/:id(.:format) */
export function apiV1HolidayCalendarsYearPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "holiday" + "/" + "calendars" + "/" + "years" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/holiday/calendars/days/:id(.:format) */
export function apiV1HolidayCalendarsDayPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "holiday" + "/" + "calendars" + "/" + "days" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/holiday/calendars/:id/activate(.:format) */
export function activateApiV1HolidayCalendarPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "holiday" + "/" + "calendars" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/holiday/calendars(.:format) */
export function apiV1HolidayCalendarsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "holiday" + "/" + "calendars" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/holiday/calendars/:id(.:format) */
export function apiV1HolidayCalendarPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "holiday" + "/" + "calendars" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/tags/:id/activate(.:format) */
export function activateApiV1TagPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "tags" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/tags(.:format) */
export function apiV1TagsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "tags" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/tags/:id(.:format) */
export function apiV1TagPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "tags" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/trackables/billable_logs(.:format) */
export function apiV1TrackablesBillableLogsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "trackables" + "/" + "billable_logs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/trackables/billable_logs/:id(.:format) */
export function apiV1TrackablesBillableLogPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "trackables" + "/" + "billable_logs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/v1/services(.:format) */
export function apiV1ServicesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "services" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/services/:id(.:format) */
export function apiV1ServicePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "services" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /rails/action_mailbox/postmark/inbound_emails(.:format) */
export function railsPostmarkInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "postmark" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/relay/inbound_emails(.:format) */
export function railsRelayInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "relay" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/sendgrid/inbound_emails(.:format) */
export function railsSendgridInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "sendgrid" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundHealthCheckPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mailgun/inbound_emails/mime(.:format) */
export function railsMailgunInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mailgun" + "/" + "inbound_emails" + "/" + "mime" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails(.:format) */
export function railsConductorInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/new(.:format) */
export function newRailsConductorInboundEmailPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format) */
export function editRailsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id(.:format) */
export function railsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format) */
export function newRailsConductorInboundEmailSourcePath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources(.:format) */
export function railsConductorInboundEmailSourcesPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format) */
export function railsConductorInboundEmailReroutePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "reroute" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id", "format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format) */
export function railsConductorInboundEmailIncineratePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "incinerate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id", "format"]);
}

/** /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format) */
export function railsServiceBlobPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "redirect" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id", "filename", "format"]);
}

/** /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format) */
export function railsServiceBlobProxyPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "proxy" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id", "filename", "format"]);
}

/** /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "redirect" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id", "variation_key", "filename", "format"]);
}

/** /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationProxyPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "proxy" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id", "variation_key", "filename", "format"]);
}

/** /rails/active_storage/disk/:encoded_key/*filename(.:format) */
export function railsDiskServicePath(encoded_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_key", "filename", "format"]);
}

/** /rails/active_storage/disk/:encoded_token(.:format) */
export function updateRailsDiskServicePath(encoded_token: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_token", "format"]);
}

/** /rails/active_storage/direct_uploads(.:format) */
export function railsDirectUploadsPath(options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "direct_uploads" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}
