import { FC } from 'react';

import { Typography } from '@mui/material';

import { FIRST_DAY_OF_MONTH } from 'utils/calendar';
import { capitalizeFirstLetter } from 'utils/string';

import styles from './styles';
import { IReportVacationsCalendarTableHeaderMonth } from './types';

const TableHeaderMonth: FC<IReportVacationsCalendarTableHeaderMonth> = props => {
  const { title, lastDayOfMonth, year } = props;

  const header = `${FIRST_DAY_OF_MONTH} - ${lastDayOfMonth} ${capitalizeFirstLetter(title)} ${year}`;

  return (
    <Typography variant="body2" sx={styles.root}>
      {header}
    </Typography>
  );
};

export default TableHeaderMonth;
