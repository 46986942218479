export default {
  title: 'Users',
  createUserModalTitle: 'Add User',
  buttons: {
    createUser: 'Create User',
  },
  tabs: {
    users: 'List of Users',
    resources: 'Resources Migration',
  },
  search: {
    placeholder: 'Search',
  },
  form: {
    sectionTitles: {
      personalInformation: 'Personal Information',
      userSettings: 'User Settings',
    },
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    emailLabel: 'Email',
    phoneNumberLabel: 'Phone Number',
    typeLabel: 'User Type',
    groupLabel: 'Home Location',
    birthLabel: 'Date of Birth',
    physicalLocationLabel: 'Physical Location',
    hoursPerWeekLabel: 'Hours per Week',
    expectedBillableTimePercentLabel: 'Expected Billable Time Percent',
    workingDaysCalendar: 'Working Days Calendar',
    stateLabel: 'State',
    employmentStartDateLabel: 'Start Date',
    billableStartDateLabel: 'Start Billable Date',
    endDateLabel: 'End Date',
    specializationLabel: 'Specialization',
    buttons: {
      addUser: 'Add User',
      save: 'Save',
      cancel: 'Cancel',
    },
  },
  table: {
    headers: {
      name: 'Name',
      email: 'Email',
      specialization: 'Specialization',
      physicalLocation: 'Physical Location',
      userType: 'User Type',
      homeLocation: 'Home Location',
      state: 'State',
      workingDaysCalendar: 'Working Days Calendar',
      resource: 'Resource',
      hierarchy: 'Hierarchy',
      receiptDate: 'Receipt Date',
      eliminationDate: 'Elimination Date',
    },
  },
  userActions: {
    editModal: {
      title: 'Edit User',
    },
    userDeactivateConfirmation: {
      title: 'Delete User',
      message: 'Are you sure you want to delete the user?',
    },
    menuItems: {
      view: {
        title: 'View',
      },
      edit: {
        title: 'Edit',
      },
      deactivate: {
        title: 'Archive',
      },
      activate: {
        title: 'Activate',
      },
      impersonate: {
        title: 'Impersonate',
      },
      notAvailable: 'You do not have permission to perform this action',
    },
  },
};
