import { SxStyles } from 'theme';

const styles: SxStyles = {
  hasEndDate: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '17px',
  },
  switchEndDate: {
    marginRight: 0,
  },
  fields: {
    display: 'flex',
    marginBottom: '32px',
    paddingTop: '10px',
  },
  datePickerField: {
    width: '160px',
    marginRight: '16px',
  },
  input: {
    width: '75px',
  },
  changeAlloCationButton: {
    marginRight: '10px',
  },
  placeholder: {
    color: 'text.gray',
  },
};

export default styles;
