import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    color: 'button.main',
  },
  iconBlock: {
    marginRight: '4px',
    width: '24px',
    height: '24px',
  },
  text: {
    textTransform: 'uppercase',
    color: 'button.main',
  },
  leftArrowIcon: {
    marginRight: '12px',
  },
  rightArrowIcon: {
    marginLeft: '12px',
  },
};

export default styles;
