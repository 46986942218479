import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expand: {
    width: '5%',
  },
  date: {
    width: '10%',
    padding: '16px',
    color: 'text.blackLight',
  },
  resource: {
    width: '25%',
    padding: '16px',
    color: 'text.blackLight',
  },
  specialization: {
    width: '20%',
    padding: '16px',
    color: 'text.blackLight',
  },
  vacationRange: {
    width: '20%',
    padding: '16px',
    color: 'text.blackLight',
  },
  buttons: {
    width: '20%',
    padding: '16px',
  },
};

export default styles;
