import { SxStyles } from 'theme';

const styles: SxStyles = {
  addButton: {
    paddingX: '20px',
    paddingY: '8px',
  },
};

export default styles;
