import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '24px',
    gap: '1em',
  },
  infoBlock: {
    display: 'flex',
  },
  today: {
    marginRight: '24px',
    marginLeft: '24px',
  },
  title: {
    color: 'text.gray',
    marginRight: '8px',
  },
};

export default styles;
