import { FC } from 'react';

import { Alert, TableHead, Table as MUITable, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';
import { usePagination, useTable } from 'react-table';

import Div from 'components/common/Div';
import TableSkeleton from 'components/common/TableSkeleton';

import { getColumnColor, getColumns } from './service';
import styles from './styles';
import { IReportVacationsCalendarTableProps } from './types';

const Table: FC<IReportVacationsCalendarTableProps> = props => {
  const {
    data = [],
    columns = [],
    isLoading = false,
    isError = false,
    coloredAccessors,
    weekendsAccessors,
    lastDayOfMonthAccessors,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    usePagination,
  );

  const { t } = useTranslation('table');

  const isTableDataEmpty = page.length === 0;
  const isDataEmpty = isTableDataEmpty && !isLoading;

  if (isError) {
    return <Alert severity="error">{t('errorText')}</Alert>;
  }

  return (
    <Div sx={styles.root}>
      <Div sx={styles.rootTable}>
        <MUITable sx={MUISx(styles.table, { condition: isLoading, sx: styles.tableLoading })} {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, headerGroupIndex) => {
              const isFirstHeaderRow = headerGroupIndex === 0;
              const isSecondHeaderRow = headerGroupIndex === 1;
              const isThirdHeaderRow = headerGroupIndex === 2;

              return (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, columnIndex, columns) => {
                    const { id, originalId, width, minWidth, maxWidth } = column;

                    const columnAccessor = originalId ?? id;

                    const isFirstHeaderColumn = columnIndex === 0;
                    const isLastHeaderColumn = columns.length - 1 === columnIndex;

                    const {
                      isResourcesColumn,
                      isResourcesEmptyColumn,
                      isResourcesEmptyDepthColumn,
                      isCarryoverPreviousColumn,
                      isAvailableColumn,
                      isScheduledColumn,
                      isCarryoverNextColumn,
                      isEmptyCarryoverPreviousColumn,
                      isEmptyAvailableColumn,
                      isEmptyScheduledColumn,
                      isEmptyCarryoverNextColumn,
                      isEmptyDepthCarryoverPreviousColumn,
                      isEmptyDepthAvailableColumn,
                      isEmptyDepthScheduledColumn,
                      isEmptyDepthCarryoverNextColumn,
                      isMonthHeaderColumn,
                      isDayColumn,
                      isWeekendColumn,
                      isLastDayOfMonthColumn,
                    } = getColumns(columnAccessor, weekendsAccessors, lastDayOfMonthAccessors);

                    const isHeaderYearColumn =
                      isCarryoverPreviousColumn || isAvailableColumn || isScheduledColumn || isCarryoverNextColumn;

                    const isEmptyHeaderYearColumn =
                      isEmptyCarryoverPreviousColumn ||
                      isEmptyAvailableColumn ||
                      isEmptyScheduledColumn ||
                      isEmptyCarryoverNextColumn;

                    const isEmptyDepthHeaderYearColumn =
                      isEmptyDepthCarryoverPreviousColumn ||
                      isEmptyDepthAvailableColumn ||
                      isEmptyDepthScheduledColumn ||
                      isEmptyDepthCarryoverNextColumn;

                    const isHeaderLeftStickyGroup =
                      isResourcesColumn ||
                      isResourcesEmptyColumn ||
                      isResourcesEmptyDepthColumn ||
                      isHeaderYearColumn ||
                      isEmptyHeaderYearColumn ||
                      isEmptyDepthHeaderYearColumn;

                    const isFirstHeaderCell = isSecondHeaderRow && isFirstHeaderColumn;

                    const isCarryoverPreviousHeaderGroup =
                      isCarryoverPreviousColumn ||
                      isEmptyCarryoverPreviousColumn ||
                      isEmptyDepthCarryoverPreviousColumn;
                    const isAvailableHeaderGroup =
                      isAvailableColumn || isEmptyAvailableColumn || isEmptyDepthAvailableColumn;
                    const isScheduledHeaderGroup =
                      isScheduledColumn || isEmptyScheduledColumn || isEmptyDepthScheduledColumn;
                    const isCarryoverNextHeaderGroup =
                      isCarryoverNextColumn || isEmptyCarryoverNextColumn || isEmptyDepthCarryoverNextColumn;

                    return (
                      <TableCell
                        sx={MUISx(
                          styles.column,
                          { condition: isLastHeaderColumn, sx: styles.lastColumn },
                          { condition: isFirstHeaderCell, sx: styles.resourceHeaderCell },
                          { condition: isEmptyDepthHeaderYearColumn, sx: styles.headerYearColumn },
                          { condition: isFirstHeaderRow || isSecondHeaderRow, sx: styles.withoutBorderHeaderRow },
                          { condition: isThirdHeaderRow, sx: styles.thirdHeaderRow },
                          { condition: isHeaderLeftStickyGroup, sx: styles.leftStickyGroup },
                          {
                            condition: isCarryoverPreviousHeaderGroup,
                            sx: styles.carryoverPreviousColumnSticky,
                          },
                          {
                            condition: isAvailableHeaderGroup,
                            sx: styles.availableColumnSticky,
                          },
                          {
                            condition: isScheduledHeaderGroup,
                            sx: styles.scheduledColumnSticky,
                          },
                          {
                            condition: isCarryoverNextHeaderGroup,
                            sx: styles.carryoverNextColumnSticky,
                          },
                          {
                            condition: isMonthHeaderColumn,
                            sx: styles.monthHeaderColumn,
                          },
                          {
                            condition: isDayColumn,
                            sx: styles.dayColumn,
                          },
                          {
                            condition: isWeekendColumn,
                            sx: styles.dayWeekendColumn,
                          },
                          {
                            condition: isLastDayOfMonthColumn,
                            sx: styles.dayLastOfMonthColumn,
                          },
                        )}
                        {...column.getHeaderProps({
                          style: {
                            width: width ?? 'auto',
                            minWidth: minWidth ?? 'auto',
                            maxWidth: maxWidth ?? 'auto',
                          },
                        })}
                      >
                        {column.render('Header')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, rowIndex, rows) => {
              prepareRow(row);

              const isLastBodyRow = rowIndex === rows.length - 1;
              const resourceRowFullName = row.original.resources.props.fullName;

              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex, cells) => {
                    const { id: columnAccessor, width, minWidth, maxWidth } = cell.column;

                    const isLastBodyColumn = cells.length - 1 === cellIndex;

                    const {
                      isResourcesColumn,
                      isCarryoverPreviousColumn,
                      isAvailableColumn,
                      isScheduledColumn,
                      isCarryoverNextColumn,
                      isDayColumn,
                      isWeekendColumn,
                      isLastDayOfMonthColumn,
                    } = getColumns(columnAccessor, weekendsAccessors, lastDayOfMonthAccessors);

                    const { isCompleted, isApproved, isRequested } = getColumnColor(
                      coloredAccessors,
                      columnAccessor,
                      resourceRowFullName,
                    );

                    const isBodyYearColumn =
                      isCarryoverPreviousColumn || isAvailableColumn || isScheduledColumn || isCarryoverNextColumn;

                    const isBodyLeftStickyGroup = isResourcesColumn || isBodyYearColumn;

                    return (
                      <TableCell
                        sx={MUISx(
                          styles.column,
                          { condition: isLastBodyColumn, sx: styles.lastColumn },
                          { condition: isLastBodyRow, sx: styles.lastRow },
                          { condition: isBodyYearColumn, sx: styles.bodyYearColumn },
                          { condition: isBodyLeftStickyGroup, sx: styles.leftStickyGroup },

                          { condition: isBodyYearColumn, sx: styles.newSticky },
                          { condition: isCarryoverPreviousColumn, sx: styles.carryoverPreviousColumnSticky },
                          { condition: isAvailableColumn, sx: styles.availableColumnSticky },
                          { condition: isScheduledColumn, sx: styles.scheduledColumnSticky },
                          { condition: isCarryoverNextColumn, sx: styles.carryoverNextColumnSticky },
                          {
                            condition: isDayColumn,
                            sx: styles.dayColumn,
                          },
                          {
                            condition: isApproved,
                            sx: styles.dayColumnApproved,
                          },
                          {
                            condition: isRequested,
                            sx: styles.dayColumnRequested,
                          },
                          {
                            condition: isCompleted,
                            sx: styles.dayColumnCompleted,
                          },
                          {
                            condition: isWeekendColumn,
                            sx: styles.dayWeekendColumn,
                          },
                          {
                            condition: isLastDayOfMonthColumn,
                            sx: styles.dayLastOfMonthColumn,
                          },
                        )}
                        {...cell.getCellProps({
                          style: {
                            width: width ?? 'auto',
                            minWidth: minWidth ?? 'auto',
                            maxWidth: maxWidth ?? 'auto',
                          },
                        })}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MUITable>

        {isLoading && <TableSkeleton perPage={pageSize} />}
        {isDataEmpty && <Typography textAlign="center">{t('noData')}</Typography>}
      </Div>
    </Div>
  );
};

export default Table;
