import { SxStyles } from 'theme';

const styles: SxStyles = {
  selectionBlock: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  buttonGroup: {
    marginLeft: '48px',
  },
  icon: {
    width: '20px',
    heiht: '20px',
    color: 'primary.gray',
    marginLeft: '8px',
    marginRight: '8px',
  },
  iconActive: {
    color: 'primary.blue',
  },
};

export default styles;
