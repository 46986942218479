import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    maxWidth: {
      mobile: '55px',
      desktop: '196px',
    },
    width: '100%',
    height: '100vh',
    backgroundColor: 'background.black',
    padding: '16px 8px',
    position: 'relative',
  },
  content: {
    overflowY: 'auto',
    height: 'calc(100% - 45px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    color: 'text.whiteBlue',
    marginBottom: '16px',
    paddingLeft: '8px',
    textDecoration: 'none',
  },
  logoBlock: {
    marginRight: '24px',
  },
  notProductionTitle: {
    color: 'primary.main',
  },
  divider: {
    backgroundColor: 'border.whiteBlue',
    opacity: '0.25',
  },
  footerDivider: {
    margin: '16px 0',
  },
  dividerHidden: {
    display: 'none',
  },
  userBadgeBlock: {
    marginLeft: {
      mobile: '4px',
      desktop: '8px',
    },
  },
};

export default styles;
