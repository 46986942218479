import { SxStyles } from 'theme';

const styles: SxStyles = {
  input: {
    paddingTop: '5px',
    marginBottom: '16px',
    width: '380px',
  },
  buttonsContainer: {
    marginTop: '32px',
    display: 'flex',
    gap: '12px',
  },
  autocomplete: {
    marginBottom: '16px',
  },
  placeholder: {
    color: 'text.gray',
  },
};

export default styles;
