import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  createAdditionalAccessButton: {
    marginRight: '10px',
  },
  select: {
    width: '400px',
    marginBottom: '16px',
    textTransform: 'capitalize',
  },
  selectPlaceholder: {
    color: 'text.gray',
  },
  buttons: {
    marginTop: '16px',
  },
  permissionValue: {
    textTransform: 'capitalize',
  },
};

export default styles;
