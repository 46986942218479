import { FC, useMemo } from 'react';

import { List, ListItem, Typography } from '@mui/material';
import MUISx from 'mui-sx';

import Div from 'components/common/Div';

import { getResourceTotalTrackedTimeEntriesGroupDuration } from 'domain/resource/trackedTimeEntry/service';

import { formatDateTime, getHoursAndMinutesStringFromMinutes } from 'utils/dateTime';

import ResourceTrackedTimeEntry from './components/ResourceTrackedTimeEntry';
import styles from './styles';
import { IResourceTrackedTimeEntriesGroupProps } from './types';

const ResourceTrackedTimeEntriesGroup: FC<IResourceTrackedTimeEntriesGroupProps> = props => {
  const { resourceTrackedTimeEntriesGroupByDate, groupDate, resourceId, onCopyTimeTrackerSettings } = props;

  const totalEntriesDuration = useMemo(
    () => getResourceTotalTrackedTimeEntriesGroupDuration(resourceTrackedTimeEntriesGroupByDate),
    [resourceTrackedTimeEntriesGroupByDate],
  );
  const totalEntriesHoursView = useMemo(
    () => getHoursAndMinutesStringFromMinutes(totalEntriesDuration),
    [totalEntriesDuration],
  );

  const groupHeaderDate = formatDateTime(groupDate);
  const resourceTrackedEntriesSorted = resourceTrackedTimeEntriesGroupByDate.sort((entries1, entries2) =>
    Math.sign(entries2.id - entries1.id),
  );
  return (
    <ListItem sx={styles.root}>
      <Div sx={styles.header}>
        <Typography sx={styles.headerTitle} variant="body1">
          {groupHeaderDate}
        </Typography>
        <Typography sx={MUISx(styles.headerTitle, styles.totalEntriesHours)} variant="h4" component="p">
          {totalEntriesHoursView}
        </Typography>
      </Div>

      <List sx={styles.group}>
        {resourceTrackedEntriesSorted.map(resourceTrackedTimeEntry => {
          return (
            <ResourceTrackedTimeEntry
              key={resourceTrackedTimeEntry.id}
              resourceTrackedTimeEntry={resourceTrackedTimeEntry}
              onCopyTimeTrackerSettings={onCopyTimeTrackerSettings}
              resourceId={resourceId}
            />
          );
        })}
      </List>
    </ListItem>
  );
};

export default ResourceTrackedTimeEntriesGroup;
