import { SxStyles } from 'theme';

const styles: SxStyles = {
  technologiesAutocomplete: {
    paddingTop: '6px',
    width: '100%',
    maxWidth: 427,
    marginBottom: '30px',
  },
  saveButton: {
    marginRight: '10px',
  },
  dates: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '11px',
  },
  endDateSwitch: {
    marginRight: 0,
  },
  groupFields: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePickerField: {
    width: '160px',
  },
  selectPlaceholder: {
    color: 'text.gray',
  },
  datePicker: {
    marginRight: '11px',
  },
  input: {
    width: '85px',
    marginBottom: '32px',
  },
};

export default styles;
