import { SxStyles } from 'theme';

const styles: SxStyles = {
  searchIcon: {
    color: 'text.gray',
    marginRight: '8px',
  },
  checkbox: {
    padding: '16px 10px',
  },
  checkboxLabel: {
    color: 'text.blackLight',
  },
};

export default styles;
