import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    boxSizing: 'border-box',
    width: '369px',
    backgroundColor: 'background.white',
    border: '1px solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    padding: '24px',
    minHeight: '80vh',
    flexShrink: 0,
  },
  userNameContainer: {
    padding: '16px 0',
    borderBottom: '1px solid',
    borderColor: 'border.gray',
    marginBottom: '16px',
  },
  infoContainer: {
    marginTop: '8px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  iconContainer: {
    width: '24px',
    height: '24px',
    color: 'border.main',
  },
  subtitle: {
    color: 'text.primary',
    opacity: '0.64',
    marginRight: '8px',
    textTransform: 'uppercase',
  },
  calendar: {
    width: '18px',
    height: '16px',
  },
  contactContainer: {
    marginTop: '24px',
    wordWrap: 'break-word',
  },
  avatarPlaceholder: {
    width: '321px',
    height: '321px',
  },
};

export default styles;
