import _ from 'lodash';

import { ReportVacationsCalendarTableColumnAccessor } from 'components/pages/Report/Vacations/components/Calendar/enums';

import { IReportVacationResource } from 'domain/report/vacation/types';
import { ResourseVacationState } from 'domain/resource/vacation/enums';

import { Month } from 'enums/Months';

import { getFullName } from 'utils/person';

import { IReportVacationsCalendarTableColoredAccessorData } from './types';

export const getColumns = (
  columnAccessor: string,
  weekendsAccessors: Array<string>,
  lastDayOfMonthAccessors: Array<string>,
) => {
  return {
    isResourcesColumn: columnAccessor === ReportVacationsCalendarTableColumnAccessor.resources,
    isResourcesEmptyColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.resourcesEmpty}`,
    isResourcesEmptyDepthColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.resourcesEmptyDepth}`,

    isCarryoverPreviousColumn: columnAccessor === ReportVacationsCalendarTableColumnAccessor.carryoverPrevious,
    isAvailableColumn: columnAccessor === ReportVacationsCalendarTableColumnAccessor.available,
    isScheduledColumn: columnAccessor === ReportVacationsCalendarTableColumnAccessor.scheduled,
    isCarryoverNextColumn: columnAccessor === ReportVacationsCalendarTableColumnAccessor.carryoverNext,

    isEmptyCarryoverPreviousColumn:
      columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.carryoverPreviousEmpty}`,
    isEmptyAvailableColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.availableEmpty}`,
    isEmptyScheduledColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.scheduledEmpty}`,
    isEmptyCarryoverNextColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.carryoverNextEmpty}`,

    isEmptyDepthCarryoverPreviousColumn:
      columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.carryoverPreviousEmptyDepth}`,
    isEmptyDepthAvailableColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.availableEmptyDepth}`,
    isEmptyDepthScheduledColumn: columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.scheduledEmptyDepth}`,
    isEmptyDepthCarryoverNextColumn:
      columnAccessor === `${ReportVacationsCalendarTableColumnAccessor.carryoverNextEmptyDepth}`,
    isMonthHeaderColumn: Object.values(Month).includes(
      columnAccessor.slice(
        0,
        columnAccessor.includes('-empty') ? columnAccessor.indexOf('-') : columnAccessor.length,
      ) as Month,
    ),
    isDayColumn: !_.isNil(Object.values(Month).find(month => columnAccessor.startsWith(`${month}-`))),
    isWeekendColumn: weekendsAccessors.includes(columnAccessor),
    isLastDayOfMonthColumn: lastDayOfMonthAccessors.includes(columnAccessor),
  };
};

export const getColoredAccessors = (
  resources: Array<IReportVacationResource>,
): Array<IReportVacationsCalendarTableColoredAccessorData> => {
  return resources
    .filter(resource => !_.isEmpty(resource.cellsData))
    .flatMap(resource => {
      return resource.cellsData.map(data => {
        for (const [key, value] of Object.entries(data)) {
          return {
            date: key,
            type: value.type,
            workload: value.workload,
            resourceFullName: getFullName(resource),
          };
        }
      });
    });
};

const getColumnColorState = (
  currentAccessors,
  resourceRowFullName: string,
  state: ResourseVacationState,
  percentage: number,
) => {
  return !_.isEmpty(
    currentAccessors.filter(
      coloredAccessor =>
        coloredAccessor.type === state &&
        coloredAccessor.workload === percentage &&
        coloredAccessor.resourceFullName === resourceRowFullName,
    ),
  );
};

export const getColumnColor = (coloredAccessors, resourceRowFullName: string) => {
  const isHoliday = getColumnColorState(coloredAccessors, resourceRowFullName, 'vacation', 0);
  const isWorking25 = getColumnColorState(coloredAccessors, resourceRowFullName, 'working', 25);
  const isWorking50 = getColumnColorState(coloredAccessors, resourceRowFullName, 'working', 50);
  const isWorking75 = getColumnColorState(coloredAccessors, resourceRowFullName, 'working', 75);
  const isWorking = getColumnColorState(coloredAccessors, resourceRowFullName, 'working', 100);

  return {
    working25: !_.isEmpty(coloredAccessors) && isWorking25,
    working50: !_.isEmpty(coloredAccessors) && isWorking50,
    working75: !_.isEmpty(coloredAccessors) && isWorking75,
    working: !_.isEmpty(coloredAccessors) && isWorking,
    holiday: !_.isEmpty(coloredAccessors) && isHoliday,
  };
};
