import { SxStyles } from 'theme';

const styles: SxStyles = {
  tableRoot: {
    position: 'relative',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    overflowX: 'auto',
    backgroundColor: 'background.white',
    height: 'fit-content',
    maxHeight: '85vh',
  },
  root: {
    backgroundColor: 'background.white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    maxHeight: '95vh',
  },
  table: {
    borderCollapse: 'separate',
  },
  tableLoading: {
    display: 'none',
  },
  headerHidden: {
    visibility: 'hidden',
    '& th': {
      padding: 0,
    },
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'background.white',
    zIndex: 'fab',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-20px 0 0 -20px',
  },
  singleRow: {
    borderBottom: 'none',
  },
  paginationWrapper: {
    alignItems: 'center',
    margin: '15px 0 15px',
  },
  pageNumbers: {
    flexGrow: 1,
  },
  perPage: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    marginRight: '9px',
  },
  selector: {
    width: '104px',
  },
  paginationButton: {
    alignItems: 'center',
    gap: '8px',
    color: 'text.500',
  },
  rowWithOpacity: {
    opacity: 0.4,
  },
  cell: {
    textAlign: 'right',
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
    padding: '8px',
  },
  row: {
    position: 'relative',
  },
  depthRow: {
    '&::after': {
      content: "''",
      position: 'absolute',
      width: '4px',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'primary.main',
    },
  },
  firstColumn: {
    borderBottomWidth: 'none',
    textAlign: 'left',
    borderRightWidth: '2px',
    position: 'sticky',
    left: 0,
    top: 0,
    backgroundColor: 'background.white',
    zIndex: 'mobileStepper',
  },
  lastColumn: {
    borderRight: 'none',
  },
  firstHeaderRow: {
    textAlign: 'center',
    borderRightWidth: '2px',
    borderBottom: 'none',
  },
  secondHeaderRow: {
    borderBottom: 'none',
    textAlign: 'center',
  },
  alignCenter: {
    textAlign: 'center',
  },
  totalHeaderRow: {
    backgroundColor: 'text.blackLight',
    color: 'text.whiteBlue',
    borderBottomWidth: '2px',
  },
  billableRow: {
    backgroundColor: 'background.lightGreen',
    padding: '5px 8px',
  },
  nonBillableRow: {
    backgroundColor: 'background.lightRed',
    padding: '5px 8px',
  },
  subRowHeaderCell: {
    paddingLeft: '64px',
  },
  totalHeaderColumn: {
    backgroundColor: 'text.blackLight',
  },
  billableStart: {
    borderRightWidth: '2px',
  },
  utilizationCell: {
    backgroundColor: 'background.lightGray',
    borderRightWidth: '2px',
  },
  boldRightBorder: {
    borderRightWidth: '2px',
  },
  utilizationCell: {
    backgroundColor: 'background.lightGray',
    borderRightWidth: '2px',
  },
  boldRightBorder: {
    borderRightWidth: '2px',
  },
  clickableCell: {
    '&:hover': {
      backgroundColor: 'background.lightBlueGray',
    },
  },
};

export default styles;
