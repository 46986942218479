import { FC } from 'react';

import Div from 'components/common/Div';

import { ITabPanelProps } from './types';

const TabPanel: FC<ITabPanelProps> = props => {
  const { children, value, name, sx, tabGroupName } = props;

  return (
    <Div
      role={`tabpanel${tabGroupName}`}
      hidden={value !== name}
      id={`tabpanel${tabGroupName}-${name}`}
      aria-labelledby={`tab${tabGroupName}-${name}`}
    >
      {value === name && <Div sx={sx}>{children}</Div>}
    </Div>
  );
};

export default TabPanel;
