import { SxStyles } from 'theme';

const styles: SxStyles = {
  moreHorizButton: {
    display: 'flex',
    marginRight: '24px',
    marginTop: '6px',
  },
  moreHorizIcon: {
    color: 'primary.main',
  },
  removeVacancyAction: {
    color: 'text.darkRed',
  },
};

export default styles;
