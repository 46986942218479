import { SxStyles } from 'theme';

const styles: SxStyles = {
  reportFiltersButton: {
    backgroundColor: 'background.default',
    marginLeft: '10px',
  },
  selectedItem: {
    backgroundColor: 'background.gray',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  cancel: {
    padding: 0,
    backgroundColor: 'transparent',
    color: 'text.blackLight',
    '&:hover': {
      backgroundColor: 'background.gray',
      transition: 'background-color 0.2s linear',
    },
  },
  icon: {
    width: '18px',
    heiht: '18px',
  },
  filterMenu: {
    minWidth: '150px',
  },
};

export default styles;
