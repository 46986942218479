import { SxStyles } from 'theme';

const styles: SxStyles = {
  columnHeader: {
    color: 'text.gray',
  },
  allocationZero: {
    color: 'text.lightGray',
  },
  allocationNegative: {
    color: 'text.orange',
  },
  resourcesAndVacanciesHeadCellRoot: {
    display: 'flex',
  },
  resourcesAndVacanciesHeadCell: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: '200px',
  },
  projectInfoRowTitle: {
    paddingLeft: '64px',
  },
  transparentButton: {
    background: 'transparent',
    border: 'none',
    '&:hover': {
      border: 'none',
      background: 'transparent',
    },
  },
  planUpdatedCell: {
    color: 'text.primary',
    minWidth: '30px',
    borderBottomWidth: '1.5px',
    borderBottomStyle: 'dashed',
    borderBottomColor: 'border.blue',
  },
  vacancy: {
    display: 'flex',
  },
  vacancyIcon: {
    marginLeft: '10px',
    fontSize: '24px',
  },
  fteButton: {
    width: '90px',
    marginLeft: '65px',
    textAlign: 'center',
  },
  addResourceUsageButton: {
    marginLeft: '65px',
    background: 'transparent',
    border: 'none',
    '&:hover': {
      border: 'none',
      background: 'transparent',
    },
  },
};

export default styles;
