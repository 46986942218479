import { FC } from 'react';

import { Popover } from '@mui/material';

import Div from 'components/common/Div';

import { IOptionsProps } from './types';

const Options: FC<IOptionsProps> = props => {
  const { id, open, anchorEl, onClose, children } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Popover
      id={`smart-filter-options-menu-${id}`}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: { minWidth: '340px', paddingTop: '6px' },
      }}
    >
      <Div>{children}</Div>
    </Popover>
  );
};

export default Options;
