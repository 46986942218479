import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    padding: '32px 16px 0 16px',
  },
  listTitle: {
    marginBottom: '24px',
  },
  accessLevelsList: {
    marginBottom: '32px',
  },
};

export default styles;
