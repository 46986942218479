import { SxStyles } from 'theme';

const styles: SxStyles = {
  item: {
    padding: '6px 8px',
    marginBottom: '6px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  activeItem: {
    backgroundColor: 'primary.main',
    borderRadius: '3px',
  },
  itemHidden: {
    display: 'none',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textDecoration: 'none',
  },
  title: {
    color: 'primary.contrastText',
    opacity: '0.75',
  },
  activeItemTitle: {
    opacity: 1,
  },
  iconBlock: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
    color: 'primary.main',
  },
  activeItemIconBlock: {
    color: 'primary.contrastText',
  },
  divider: {
    backgroundColor: 'border.whiteBlue',
    opacity: '0.25',
    margin: '16px 0',
  },
};

export default styles;
