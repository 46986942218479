import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    maxWidth: '100%',
    justifyContent: 'space-between',
  },
  searchInput: {
    width: '500px',
    marginRight: '24px',
    backgroundColor: 'background.white',
    borderRadius: '4px',
  },
};

export default styles;
