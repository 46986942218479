import { SxStyles } from 'theme';

const styles: SxStyles = {
  paginationWrapper: {
    alignItems: 'center',
    margin: '32px 0 58px',
  },
  pageNumbers: {
    flexGrow: 1,
  },
  perPage: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    marginRight: '9px',
  },
  selector: {
    width: '104px',
  },
  paginationButton: {
    alignItems: 'center',
    gap: '8px',
    color: 'text.500',
  },
};

export default styles;
