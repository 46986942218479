import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    margin: '42px 50px',
  },
  resourceAbility: {
    justifyContent: 'space-between',
    display: 'flex',
  },
};

export default styles;
