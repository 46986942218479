import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
  },
  content: {
    width: '100%',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: 'background.default',
    padding: '32px 24px 72px',
    width: '100%',
  },
};

export default styles;
