import { SxStyles } from 'theme';

const primaryMainColor = 'primary.main';

const styles: SxStyles = {
  headerRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'text.gray',
  },
  subheader: {
    color: 'text.gray',
  },
  calendarBlock: {
    display: 'flex',
    alignItems: 'center',
    color: 'text.green',
    marginLeft: '8px',
  },
  calendarIcon: {
    width: '16px',
    height: '16px',
    marginRight: '2px',
  },
  days: {
    display: 'flex',
  },
  cellTitleBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  cellTitle: {
    marginLeft: '16px',
  },
  cellTitleRed: {
    color: 'text.red',
  },
  cellTitleIconButton: {
    width: '24px',
    height: '24px',
  },
  cellTitleIcon: {
    color: 'button.main',
  },
  teamIcon: {
    color: 'text.lightGray',
    marginLeft: '16px',
    marginRight: '10px',
  },
  cellContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  allocationZero: {
    color: 'text.lightGray',
  },
  allocationNegative: {
    color: 'text.orange',
  },
  actionsIconButton: {
    width: '40px',
    height: '40px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: primaryMainColor,
    borderRadius: '8px',
  },
  addResourceButton: {
    width: '40px',
    height: '40px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: primaryMainColor,
    borderRadius: '8px',
    marginRight: '16px',
  },
  iconPlus: {
    width: '24px',
    height: '24px',
    color: 'primary.blue',
  },
  externalLinkIcon: {
    stroke: theme => theme.palette.primary.blue,
    width: '16px',
    height: '16px',
  },
  transparentButton: {
    background: 'transparent',
    border: 'none',
    '&:hover': {
      border: 'none',
      background: 'transparent',
    },
  },
  budgetedCell: {
    minWidth: '30px',
    borderBottomWidth: '1.5px',
    borderBottomStyle: 'dashed',
    borderBottomColor: 'border.blue',
    color: 'text.primary',
  },
  resourcesAndVacanciesHeadCellRoot: {
    display: 'flex',
  },
  resourcesAndVacanciesHeadCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  columnHeader: {
    color: 'text.gray',
  },
  vacancy: {
    display: 'flex',
  },
  vacancyIcon: {
    marginLeft: '10px',
    fontSize: '24px',
  },
  fteButton: {
    marginLeft: '115px',
  },
  resourceUsageCellHeader: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'text.primary',
  },
  resourceUsageCellHeaderNotClickable: {
    textDecoration: 'none',
    color: 'text.primary',
  },
  resourceUsageCellHeaderNonClickable: {
    textDecoration: 'none',
    color: 'text.primary',
  },
  hoursLinkCell: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
  },
  addResourceUsageButton: {
    marginLeft: '115px',
    background: 'transparent',
    border: 'none',
    '&:hover': {
      border: 'none',
      background: 'transparent',
    },
  },
};

export default styles;
