import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    marginBottom: '8px',
  },
};

export default styles;
