import _ from 'lodash';

import i18n from 'locales/i18n';

import { TableCellAlignment } from 'components/common/Table/enums';

import { checkAccessToProject } from 'domain/project/service';
import { ITechnology } from 'domain/technology/types';

import { Trackables } from 'enums/Trackables';

import { renderHelper } from './renderHelper';
import { ICountedColumnsOptions, IGetSubRowsOptions, ISubRowsCountedColumnsOptions } from './types';

const getCountedColumns = (options: ICountedColumnsOptions) => {
  const { data, dateRange, isDetailedInfo, resource, renderer } = options;

  const getDetailedInfoColumns = () => {
    if (isDetailedInfo) {
      return {
        technologies: renderer.renderExpandedCell({
          value: prepareTechnologiesCell(resource.technologies),
          withTruncate: true,
          textAlign: TableCellAlignment.left,
        }),
        physicalLocation: renderer.renderExpandedCell({
          value: resource.physicalLocation,
          withTruncate: true,
          textAlign: TableCellAlignment.left,
        }),
        hierarchy: renderer.renderExpandedCell({
          value: resource.hierarchy,
          withTruncate: true,
          textAlign: TableCellAlignment.left,
        }),
        calendar: renderer.renderExpandedCell({
          value: resource.calendar,
          withTruncate: true,
          textAlign: TableCellAlignment.left,
        }),
        startDate: renderer.renderExpandedCell({ value: resource.startDate }),
        billable: renderer.renderExpandedCell({ value: resource.billable }),
        billableStartDate: renderer.renderExpandedCell({
          value: resource.billableStartDate,
        }),
      };
    }

    return {};
  };

  const mainColumns = () => {
    const planHoursColumnAccessor = 'planned';
    const actualHoursColumnAccessor = 'actual';
    const availableHoursColumnAccessor = 'available';
    const expectedHoursColumnAccessor = 'expected';
    const actualUtilizationColumnAccessor = 'actualUtilization';

    const actualHoursColumnValue = data.actual.hours;
    const planHoursColumnValue = data.plan.hours;

    const availableHoursColumnValue = data.actual.availableHours;
    const expectedHoursColumnValue = data.plan.expectedHours;
    const actualUtilizationColumnValue = data.actual.utilization;
    return {
      [planHoursColumnAccessor]: renderer.renderHoursCell({
        value: planHoursColumnValue,
        isPlannedHoursCell: true,
        resource,
        dateRange,
      }),
      [actualHoursColumnAccessor]: renderer.renderHoursCell({
        value: actualHoursColumnValue,
        isActualHoursCell: true,
        planHours: planHoursColumnValue,
        resource,
        dateRange,
      }),
      [availableHoursColumnAccessor]: renderer.renderHoursCell({
        value: availableHoursColumnValue,
        isAvailableHoursCell: true,
      }),
      [expectedHoursColumnAccessor]: renderer.renderHoursCell({
        value: expectedHoursColumnValue,
        isExpectedHoursCell: true,
      }),
      [actualUtilizationColumnAccessor]: renderer.renderUtilizationHoursCell({
        value: actualUtilizationColumnValue,
        actualHours: data.actual.hours,
        availableHours: data.actual.availableHours,
      }),
    };
  };

  return { ...getDetailedInfoColumns(), ...mainColumns() };
};

const getSubRowsCountedColumns = (options: ISubRowsCountedColumnsOptions) => {
  const { columns, dateRange, initialColumnsAccumulator, resource, project, isBillableProject, renderer } = options;
  if (_.isEmpty(columns)) {
    return initialColumnsAccumulator;
  }

  const planColumnAccessor = 'planned';
  const actualColumnAccessor = 'actual';
  const availableColumnAccessor = 'available';
  const expectedColumnAccessor = 'expected';
  const actualUtilizationColumnAccessor = 'actualUtilization';

  const planUColumnValue = columns.plan;
  const actualColumnValue = columns.actual;

  const availableColumnValue = '';
  const expectedColumnValue = '';
  const actualUtilizationColumnValue = '';

  return {
    ...initialColumnsAccumulator,
    [planColumnAccessor]: renderer.renderHoursCell({ value: planUColumnValue }),
    [actualColumnAccessor]: renderer.renderHoursCell({
      value: actualColumnValue,
      isProjectActualHoursCell: true,
      resource,
      project,
      isBillableProject,
      planHours: planUColumnValue,
      dateRange,
    }),
    [availableColumnAccessor]: renderer.renderHoursCell({ value: availableColumnValue, isAvailableHoursCell: true }),
    [expectedColumnAccessor]: renderer.renderHoursCell({ value: expectedColumnValue, isExpectedHoursCell: true }),
    [actualUtilizationColumnAccessor]: renderer.renderHoursCell({ value: actualUtilizationColumnValue }),
  };
};

const getSubRows = (options: IGetSubRowsOptions) => {
  const { resource, dateRange, user, renderer } = options;
  const billableRowHeader = getSubRowsCountedColumns({
    renderer,
    dateRange,
    columns: resource.projects.billable.totals,
    initialColumnsAccumulator: {
      resourcesTotal: renderer.renderSubRowHeaderCell({
        header: i18n.t('reportHoursByResources:table.columnsHeaders.billable'),
        isHeader: true,
      }),
    },
  });

  const nonBillableRowHeader = getSubRowsCountedColumns({
    renderer,
    dateRange,
    columns: resource.projects.nonBillable.totals,
    initialColumnsAccumulator: {
      resourcesTotal: renderer.renderSubRowHeaderCell({
        header: i18n.t('reportHoursByResources:table.columnsHeaders.nonBillable'),
        isHeader: true,
      }),
    },
  });

  const billableProjects = resource.projects.billable.items;

  const billableProjectsHeaders = billableProjects.map(billableProject => {
    const { id, columns, name, workload, accessLevel, type } = billableProject;

    const hasAccessToProject = checkAccessToProject(accessLevel, user);
    const isClickable = type === Trackables.project && id !== null;

    const renderSubRowHeaderCellOptions = {
      header: name,
      workload,
      isBillableProject: true,
      project: billableProject,
      resource,
      isClickable,
      hasAccessToProject,
    };

    const subRowsCountedColumnsArgs = {
      renderer,
      dateRange,
      columns,
      isBillableProject: true,
      project: billableProject,
      resource,
      initialColumnsAccumulator: {
        resourcesTotal: renderer.renderSubRowHeaderCell(renderSubRowHeaderCellOptions),
      },
    };

    return getSubRowsCountedColumns(subRowsCountedColumnsArgs);
  });

  const nonBillableProjects = resource.projects.nonBillable.items;
  const nonBillableProjectsHeaders = nonBillableProjects.map(nonBillableProject => {
    const { id, columns, name, workload, accessLevel, type } = nonBillableProject;

    const hasAccessToProject = checkAccessToProject(accessLevel, user);
    const isClickable = type === Trackables.project && id !== null;

    const renderSubRowHeaderCellOptions = {
      header: name,
      workload,
      isNonBillableProject: true,
      project: nonBillableProject,
      resource,
      isClickable,
      hasAccessToProject,
    };

    const subRowsCountedColumnsArgs = {
      renderer,
      dateRange,
      columns,
      isBillableProject: false,
      project: nonBillableProject,
      resource,
      initialColumnsAccumulator: {
        resourcesTotal: renderer.renderSubRowHeaderCell(renderSubRowHeaderCellOptions),
      },
    };

    return getSubRowsCountedColumns(subRowsCountedColumnsArgs);
  });

  const billableRows = _.isEmpty(billableProjects) ? [] : [billableRowHeader, ...billableProjectsHeaders];
  const nonBillableRows = _.isEmpty(nonBillableProjects) ? [] : [nonBillableRowHeader, ...nonBillableProjectsHeaders];

  return [...billableRows, ...nonBillableRows];
};

const prepareTechnologiesCell = (technologies: Array<ITechnology>) => {
  return technologies
    .map(technology => technology.name)
    .sort()
    .join(', ');
};

const prepareTableData = ({
  data,
  isDetailedInfo,
  hoursType,
  currentUser,
  styles,
  buildPathHelpers,
  onAddAllocationClick,
  t,
}) => {
  const {
    report: { resources },
    dateRange,
  } = data;

  if (_.isEmpty(resources)) return [];
  const { user } = currentUser;

  const renderer = renderHelper({ styles, buildPathHelpers, onAddAllocationClick, t });

  return resources.map(resource => {
    return {
      resourcesTotal: renderer.renderResourceUsageHeaderCell(resource),
      subRows: getSubRows({ resource, dateRange, user, renderer }),
      ...getCountedColumns({
        renderer,
        data: resource.data,
        dateRange,
        initialColumnsAccumulator: {},
        isDetailedInfo,
        resource,
        hoursType,
      }),
    };
  });
};

const prepareTableColumns = ({ data, isDetailedInfo, styles, t, groupByPeriod }) => {
  const renderer = renderHelper({ styles, t });
  return renderer.renderColumns({ groupByPeriod, isDetailedInfo, data });
};

export { prepareTableData, prepareTableColumns };
