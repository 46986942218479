import { SxStyles } from 'theme';

const styles: SxStyles = {
  content: {
    width: '250px',
  },
  header: {
    padding: '12px',
  },
  project: {
    color: 'text.lightGray',
  },
  tasks: {
    color: 'text.gray',
    padding: '12px 16px',
    textTransform: 'uppercase',
    paddingBottom: 0,
  },
  tasksList: {
    padding: '16px 0',
  },
  tasksItem: {
    padding: '0 16px',
    '&:hover': {
      backgroundColor: 'background.blackLight',
    },
  },
  taskButton: {
    width: '100%',
    padding: '8px',
    justifyContent: 'start',
  },
  taskName: {
    color: 'text.primary',
  },
  leftArrowIcon: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
  backToProjectsButton: {
    width: '100%',
    justifyContent: 'start',
  },
};

export default styles;
