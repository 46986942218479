import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 8px',
    width: '16px',
  },
  divider: {
    width: '100px',
    height: '2px',
    backgroundColor: 'text.primary',
  },
};

export default styles;
