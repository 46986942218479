import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingTop: '8px',
  },
  input: {
    width: '400px',
  },
  buttons: {
    marginTop: '32px',
    display: 'flex',
    gap: '10px',
  },
};

export default styles;
