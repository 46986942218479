import { FC, useContext, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import GroupsProjectFormAutocomplete from 'components/common/GroupsProjectFormAutocomplete';
import Icon from 'components/common/Icon';
import Loader from 'components/common/Loader';
import TechnologiesAutocomplete from 'components/common/TechnologiesAutocomplete';

import { ModalContext } from 'contexts/ModalContext';

import { useGetGroupsQuery } from 'domain/group/apiSlice';
import { IGroup } from 'domain/group/types';
import { useCreateProjectMutation } from 'domain/project/apiSlice';
import { PeriodType } from 'domain/project/enums';
import {
  ICreateProjectFormData,
  formatAttributesToSubmit,
  initialValues,
  buildValidationSchema,
} from 'domain/project/schemas/create';
import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';
import { getSortParameter } from 'utils/runsack';

import styles from './styles';
const CreateProjectForm: FC = () => {
  const [groupName, setGroupName] = useState<string>('');
  const [technologyName, setTechnologyName] = useState<string>('');

  const { t } = useTranslation('reportHoursByProjects');
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [debouncedGroupName] = useDebounce(groupName, DEFAULT_DEBOUNCE_DELAY_MS);
  const [debouncedTechnologyName] = useDebounce(technologyName, DEFAULT_DEBOUNCE_DELAY_MS);

  const { closeModal } = useContext(ModalContext);

  const [createProject, { isLoading: isCreateProjectLoading }] = useCreateProjectMutation();

  const {
    data: technologiesData,
    isLoading: isTechnologiesLoading,
    isFetching: isTehcnologiesFetching,
  } = useGetTechnologiesQuery({
    nameCont: debouncedTechnologyName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const {
    data: groupsData,
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
  } = useGetGroupsQuery({
    nameCont: debouncedGroupName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateProjectFormData>({
    defaultValues: initialValues(),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: groups, onChange: onGroupsChange },
  } = useController({ name: 'groups', control });

  const {
    field: { value: periodType, onChange: onPeriodTypeChange },
  } = useController({ name: 'periodType', control });

  const {
    field: { value: technologies, onChange: onTechnologiesChange },
  } = useController({ name: 'technologies', control });

  const {
    field: { value: billable, onChange: onBillableChange },
  } = useController({ name: 'billable', control });

  const {
    field: { value: taskRequired, onChange: onTaskRequiredChange },
  } = useController({ name: 'taskRequired', control });

  const {
    field: { value: description, onChange: onDescriptionChange },
  } = useController({ name: 'description', control });

  const periodTypeValues = useMemo(() => {
    return {
      [PeriodType.ongoing]: t('createProjectForm.fields.periodType.values.onGoing'),
      [PeriodType.timebound]: t('createProjectForm.fields.periodType.values.timebound'),
    };
  }, []);

  const onSubmit = async (formData: ICreateProjectFormData) => {
    try {
      await createProject(formatAttributesToSubmit(formData)).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const handleCurrentGroupsChange = (groups: Array<IGroup>) => {
    if (_.isEmpty(groups)) {
      onGroupsChange([]);
      return;
    }
    onGroupsChange(groups);
  };

  const handleCurrentTechnologiesChange = (technologies: Array<ITechnology>) => {
    if (_.isEmpty(technologies)) {
      onTechnologiesChange([]);
      return;
    }
    onTechnologiesChange(technologies);
  };

  const isDataLoading = isGroupsLoading || isTechnologiesLoading || isCreateProjectLoading;

  if (isDataLoading) {
    return <Loader />;
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.root}>
        <Div sx={styles.topRow}>
          <TextField
            sx={styles.nameField}
            fullWidth
            type="text"
            {...register('name')}
            label={t('createProjectForm.fields.name.label')}
            inputProps={{ maxLength: 30 }}
            InputLabelProps={{ sx: styles.placeholder }}
            error={Boolean(errors.name)}
            helperText={buildErrorMessage(t, errors.name)}
          />

          <TextField
            sx={styles.periodTypeField}
            fullWidth
            select
            value={periodType}
            onChange={onPeriodTypeChange}
            label={t('createProjectForm.fields.periodType.label')}
            InputLabelProps={{ sx: styles.selectPlaceholder }}
            error={Boolean(errors.periodType)}
          >
            <MenuItem value={PeriodType.ongoing}>{periodTypeValues[PeriodType.ongoing]}</MenuItem>
            <MenuItem value={PeriodType.timebound}>{periodTypeValues[PeriodType.timebound]}</MenuItem>
          </TextField>

          <TextField
            type="text"
            fullWidth
            sx={styles.monthlyBudgetField}
            {...register('monthlyBudget')}
            label={t('createProjectForm.fields.monthlyBudget.label')}
            InputLabelProps={{ sx: styles.placeholder }}
            error={Boolean(errors.monthlyBudget)}
            helperText={buildErrorMessage(t, errors.monthlyBudget)}
            disabled={periodType === PeriodType.timebound}
          />
        </Div>

        <TextField
          sx={styles.descriptionField}
          fullWidth
          type="text"
          value={description}
          onChange={onDescriptionChange}
          label={t('createProjectForm.fields.description.label')}
          inputProps={{ maxLength: 300 }}
          InputLabelProps={{ sx: styles.placeholder }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!_.isEmpty(description) && (
                  <IconButton onClick={() => setValue('description', '')} sx={styles.clearButton}>
                    <Icon sx={styles.clearIcon} name="closeWithoutCircle" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          error={Boolean(errors.description)}
        />

        <Div sx={MUISx(styles.field, styles.groupsField)}>
          <GroupsProjectFormAutocomplete
            groups={groupsData.groups}
            currentGroups={groups}
            onCurrentGroupsChange={handleCurrentGroupsChange}
            setGroupName={setGroupName}
            isError={Boolean(errors.groups)}
            isGroupsFetching={isGroupsFetching}
            label={t('createProjectForm.fields.groups.label')}
          />
        </Div>

        <Div sx={MUISx(styles.field, styles.technologiesField)}>
          <TechnologiesAutocomplete
            technologies={technologiesData.technologies}
            currentTechnologies={technologies}
            onCurrentTechnologiesChange={handleCurrentTechnologiesChange}
            setTechnologyName={setTechnologyName}
            isError={Boolean(errors.technologies)}
            isTechnologiesFetching={isTehcnologiesFetching}
            label={t('createProjectForm.fields.technologies.label')}
          />
        </Div>

        <Div sx={styles.switches}>
          <FormControlLabel
            control={<Switch checked={billable} onChange={onBillableChange} />}
            label={<Typography variant="subtitle1">{t('createProjectForm.fields.billable.label')}</Typography>}
          />

          <FormControlLabel
            control={<Switch checked={taskRequired} onChange={onTaskRequiredChange} />}
            label={<Typography variant="subtitle1">{t('createProjectForm.fields.taskRequired.label')}</Typography>}
          />
        </Div>
      </Div>
      <Div sx={styles.buttons}>
        <Button sx={styles.createProjectButton} type="submit" disabled={isDataLoading}>
          {t('createProjectForm.buttons.createTheProject')}
        </Button>
        <Button type="reset" variant="outlined" onClick={closeModal} disabled={isDataLoading}>
          {t('createProjectForm.buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default CreateProjectForm;
