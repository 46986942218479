import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'hidden',
    backgroundColor: 'background.white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '6px',
    marginBottom: '16px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  group: {
    width: '100%',
  },
  headerWrapper: {
    width: '100%',
    backgroundColor: 'background.gray',
    padding: '12px 16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  totalHours: {
    marginRight: '81px',
    marginLeft: 'auto',
  },
  collapseIcon: {
    color: 'button.main',
    marginRight: '8px',
  },
  checkbox: {
    padding: '4px',
    marginRight: '8px',
  },
};

export default styles;
