import { parseJSON } from 'date-fns';
import _ from 'lodash';

import { IReportTrackedTimeEntry } from 'domain/report/trackedTimeEntry/types';

import { GroupingOption } from 'enums/GroupingOption';
interface IGetReportTrackedTimeEntriesGrouping {
  isNotGrouping: boolean;
  isGroupingByUser: boolean;
  isGroupingByProject: boolean;
}

export const getReportTrackedTimeEntriesGrouping = (group: GroupingOption): IGetReportTrackedTimeEntriesGrouping => ({
  isNotGrouping: group === GroupingOption.noGrouping,
  isGroupingByUser: group === GroupingOption.user,
  isGroupingByProject: group === GroupingOption.project,
});

export const updateSelectedUserIds = (previousValues: Array<number>, selectedId: number) =>
  previousValues.includes(selectedId)
    ? previousValues.filter(id => id !== selectedId)
    : [...previousValues, selectedId];

export const getSelectionInfo = (
  reportTrackedTimeEntries: Array<IReportTrackedTimeEntry>,
  selectedEntryIds: Array<number>,
) => {
  const allEntriesIds = reportTrackedTimeEntries.map(entry => Number(Object.keys(entry).at(0)));
  const isAllEntriesSelected = selectedEntryIds.length === allEntriesIds.length && !_.isEmpty(allEntriesIds);
  const selectedEntriesNotEmpty = !_.isEmpty(selectedEntryIds);

  return {
    allEntriesIds,
    isAllEntriesSelected,
    selectedEntriesNotEmpty,
  };
};

export const parseDate = (date: string): Date => {
  return parseJSON(date);
};
