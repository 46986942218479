import { SxStyles } from 'theme';

const styles: SxStyles = {
  innerListItem: {
    marginBottom: '16px',
  },
  textContainer: {
    cursor: 'pointer',
    padding: '0 8px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'background.gray',
      transition: 'background-color 0.2s linear',
    },
    '&:hover div[data-actions=item-actions]': {
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0.2s linear, opacity 0.2s linear',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    visibility: 'hidden',
  },
  listPointer: {
    width: '5px',
    height: '5px',
    marginRight: '2px',
    color: 'text.lightGray',
  },
  archiveButton: {
    color: 'text.red',
  },
  activateButton: {
    color: 'text.green',
  },
  archivedTitle: {
    display: 'inline-block',
    backgroundColor: 'background.lightGray',
    color: 'text.lightGray',
    padding: '0 8px',
    borderRadius: '4px',
  },
  archived: {
    opacity: '0.7',
  },
};

export default styles;
