import { SxStyles } from 'theme';

const styles: SxStyles = {
  checkboxInput: {
    paddingLeft: '10px',
  },
  label: {
    color: 'text.blackLight',
  },
};

export default styles;
