import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    marginBottom: '8px',
  },
  cell: {
    width: '24px',
    height: '24px',
    textAlign: 'center',
    color: 'text.blackLight',
  },
  weekend: {
    color: 'primary.red',
    fontWeight: 600,
  },
};

export default styles;
