import { FC } from 'react';

import i18n from 'locales/i18n';

import Div from 'components/common/Div';

import TableLegendItem from './components/TableLegendItem';
import styles from './styles';

const TABLE_LEGEND_ITEMS = [
  {
    title: i18n.t('reportVacations:info.tableLegend.tableLegendItems.completed'),
  },
  {
    title: i18n.t('reportVacations:info.tableLegend.tableLegendItems.approved'),
  },
  {
    title: i18n.t('reportVacations:info.tableLegend.tableLegendItems.requested'),
  },
];

const TableLegend: FC = () => {
  return (
    <Div sx={styles.root}>
      {TABLE_LEGEND_ITEMS.map(tableLegendItem => {
        return <TableLegendItem key={tableLegendItem.title} title={tableLegendItem.title} />;
      })}
    </Div>
  );
};

export default TableLegend;
