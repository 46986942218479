import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    border: theme => `1px solid ${theme.palette.border.main}`,
    borderRadius: '6px',
    backgroundColor: 'background.white',
    marginBottom: '32px',
  },
};

export default styles;
