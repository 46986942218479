import { SxStyles } from 'theme';

const styles: SxStyles = {
  mainListItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '16px',
  },
  mainListItemTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  listPointer: {
    width: '5px',
    height: '5px',
    marginRight: '10px',
    color: 'text.lightGray',
  },
  innerList: {
    paddingLeft: '18px',
    paddingTop: '8px',
    paddingBottom: '8px',
    marginLeft: '2px',
    borderLeft: theme => `1px solid ${theme.palette.border.slateBlueGray}`,
  },
  addNewCategoryButton: {
    borderLeft: theme => `1px solid ${theme.palette.border.slateBlueGray}`,
    marginLeft: '2px',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    paddingLeft: '19px',
    paddingBottom: '8px',
  },
};

export default styles;
