import { FC, useEffect, useState } from 'react';

import { Alert, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import {
  loadingGetResourceTrackedTimeEntriesParameters,
  useLazyGetResourceTrackedTimeEntriesQuery,
} from 'domain/resource/trackedTimeEntry/apiSlice';

import { getSortParameter } from 'utils/runsack';

import { ICopiedTimeTrackerSettings } from './components/CreateResourceTrackedEntityField/types';
import TrackedTimeEntriesGroupList from './components/ResourceTrackedTimeEntriesGroupList';
import TrackedTimeEntriesHeader from './components/TrackedTimeEntriesHeader';
import styles from './styles';

const START_NUMBER_OF_DAYS = 30;
const FULL_NUMBER_OF_DAYS = 90;

const TimeTracker: FC = () => {
  const { t } = useTranslation(['common', 'timeTracker']);

  const [numberOfDays, setNumberOfDays] = useState<number>(START_NUMBER_OF_DAYS);

  const [copiedTimeTrackerSettings, setCopiedTimeTrackerSettings] = useState<ICopiedTimeTrackerSettings | null>(null);
  const { data: currentUser, isLoading: isCurrentUserLoading, isError: isCurrentUserError } = useGetCurrentUserQuery();

  const [
    getResourceTrackedTimeEntries,
    {
      data: resourceTrackedTimeEntries,
      isSuccess: isSuccessResourceTrackedTimeEntriesLoading,
      isLoading: isResourceTrackedTimeEntriesLoading,
      isFetching: isResourceTrackedTimeEntriesFetching,
      isError: isResourceTrackedTimeEntriesLoadingError,
    },
  ] = useLazyGetResourceTrackedTimeEntriesQuery();

  useEffect(() => {
    if (currentUser?.user.resource.id) {
      const resourceTrackedTimeEntriesQueryparameters = loadingGetResourceTrackedTimeEntriesParameters({
        resourceId: currentUser.user.resource.id,
        s: getSortParameter('date', 'desc'),
        numberOfDays,
      });

      void getResourceTrackedTimeEntries(resourceTrackedTimeEntriesQueryparameters);
    }
  }, [currentUser, numberOfDays]);

  const isDataLoading = isResourceTrackedTimeEntriesLoading || isCurrentUserLoading;
  const isDateError = isResourceTrackedTimeEntriesLoadingError || isCurrentUserError;

  const handleLoadMore = () => {
    setNumberOfDays(FULL_NUMBER_OF_DAYS);
  };

  if (isDataLoading) {
    return <Loader />;
  }

  if (isDateError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCopyTimeTrackerSettings = formData => {
    setCopiedTimeTrackerSettings(formData);
  };

  return (
    <Div sx={styles.root}>
      <Div sx={styles.header}>
        <Typography sx={styles.title} variant="h1">
          {t('timeTracker:title')}
        </Typography>
        {isSuccessResourceTrackedTimeEntriesLoading && (
          <TrackedTimeEntriesHeader
            trackedTimeEntries={resourceTrackedTimeEntries?.trackedTimeEntries}
            resourceId={currentUser?.user.resource.id}
            todayTotalHours={resourceTrackedTimeEntries?.todayTotalHours}
            currentWeekTotalHours={resourceTrackedTimeEntries?.currentWeekTotalHours}
            pastWeekTotalHours={resourceTrackedTimeEntries?.pastWeekTotalHours}
            copiedTimeTrackerSettings={copiedTimeTrackerSettings}
          />
        )}
      </Div>

      {isSuccessResourceTrackedTimeEntriesLoading && (
        <TrackedTimeEntriesGroupList
          resourceId={currentUser?.user.resource.id}
          handleLoadMore={handleLoadMore}
          onCopyTimeTrackerSettings={handleCopyTimeTrackerSettings}
          isFetching={isResourceTrackedTimeEntriesFetching}
          resourceTrackedTimeEntries={resourceTrackedTimeEntries?.trackedTimeEntries}
          editLockDate={resourceTrackedTimeEntries?.trackedTimeEditLockDate}
        />
      )}
    </Div>
  );
};

export default TimeTracker;
