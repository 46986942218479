import { SxStyles } from 'theme';

const styles: SxStyles = {
  header: {
    marginTop: '44px',
    marginBottom: '16px',
  },
};

export default styles;
