import { FC, useContext } from 'react';

import { Button, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import UpdateUserForm from 'components/common/UpdateUserForm';

import { ModalContext } from 'contexts/ModalContext';

import { useCreateCurrentUserImpersonationMutation } from 'domain/currentUser/impersonation/apiSlice';
import { useDeleteUserMutation, useActivateUserMutation } from 'domain/user/apiSlice';
import { isActive } from 'domain/user/service';

import { useNotifications } from 'hooks/useNotifications';
import { usePermissions } from 'hooks/usePermissions';

import { apiContainer, store } from 'store';

import { generateBackendErrorMessages } from 'utils/error';

import styles from './styles';
import { IProfileHeaderProps } from './types';

const ProfileHeader: FC<IProfileHeaderProps> = props => {
  const { user } = props;

  const { t } = useTranslation('userProfile');

  const { isRoleFeatureAllowed } = usePermissions();

  const [deleteUser, { isLoading: isDeleteUserLoading }] = useDeleteUserMutation();
  const [activateUser, { isLoading: isActivateUserLoading }] = useActivateUserMutation();

  const { openModal } = useContext(ModalContext);
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const isDataLoading = isActivateUserLoading || isDeleteUserLoading;
  const isUserActive = isActive(user);

  const [userCreateImpersonation] = useCreateCurrentUserImpersonationMutation();

  const handleDeleteCurrentUser = async () => {
    try {
      await deleteUser(user.id).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleActivateCurrentUser = async () => {
    try {
      await activateUser(user.id).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleUserUpdateModalOpen = () => {
    openModal({
      title: t('users:userActions.editModal.title'),
      fullScreen: false,
      content: <UpdateUserForm user={user} />,
    });
  };

  const handleUserImpersonate = async (userId: number) => {
    const impersonation = {
      subjectId: userId,
    };
    try {
      await userCreateImpersonation({ impersonation }).unwrap();
      store.dispatch(apiContainer.util.resetApiState());
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const { canEditUser, canImpersonateUser, canArchiveUser } = user.permissions;

  return (
    <Div sx={styles.container}>
      <Typography variant="h1">{t('title')}</Typography>
      <Div sx={styles.buttonsContainer}>
        {canImpersonateUser && (
          <Button
            sx={MUISx(styles.editButton, styles.button)}
            onClick={() => handleUserImpersonate(user.id)}
            variant="outlined"
          >
            {t('buttons.impersonate')}
          </Button>
        )}

        {isRoleFeatureAllowed('canProfileEdit') && canEditUser && (
          <Button sx={MUISx(styles.editButton, styles.button)} onClick={handleUserUpdateModalOpen} variant="outlined">
            {t('buttons.edit')}
          </Button>
        )}
        {isRoleFeatureAllowed('canToggleProfileState') && canArchiveUser && (
          <Button
            sx={MUISx(
              styles.button,
              { condition: isUserActive, sx: styles.deleteButton },
              { condition: !isUserActive, sx: styles.activateButton },
            )}
            variant="outlined"
            disabled={isDataLoading}
            onClick={isUserActive ? handleDeleteCurrentUser : handleActivateCurrentUser}
          >
            {isUserActive ? t('buttons.delete') : t('buttons.activate')}
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default ProfileHeader;
