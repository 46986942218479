import { FC } from 'react';

import { ChevronLeftOutlined } from '@mui/icons-material';
import { Alert, Button, Divider, List, ListItem, Popover, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import { ConditionState } from 'components/common/SmartFilter/enums';

import { loadingGetProjectTasksParameters, useGetProjectTasksQuery } from 'domain/project/task/apiSlice';
import { ITask } from 'domain/project/task/types';

import styles from './styles';
import { IProjectTasksMenuProps } from './types';

const ProjectTasksMenu: FC<IProjectTasksMenuProps> = props => {
  const { projectTasksMenuId, menuElementRef, isOpen, onClose, currentValue, onSetTask, onFullClose } = props;

  const { t } = useTranslation(['common', 'timeTracker']);

  const projectTasksParameters = loadingGetProjectTasksParameters({
    projectId: currentValue.trackable.id,
    stateEq: ConditionState.active,
  });
  const {
    data: projectTasksData,
    isLoading: isProjectTasksDataLoading,
    isError: isProjectTasksDataError,
  } = useGetProjectTasksQuery(projectTasksParameters);

  if (isProjectTasksDataError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleSetTask = (task: ITask) => {
    onSetTask({ ...currentValue, task });
    onClose();
    onFullClose();
  };

  const handleBackClick = () => {
    onClose();
  };

  return (
    <Popover
      id={projectTasksMenuId}
      open={isOpen}
      onClose={onClose}
      anchorEl={menuElementRef?.current}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          borderRadius: '6px',
          marginLeft: '8px',
        },
      }}
    >
      {!_.isNil(currentValue) && (
        <Div sx={styles.content}>
          <Div sx={styles.header}>
            <Typography variant="subtitle3" sx={styles.project}>
              {t('timeTracker:createProjectAndTaskButton.autocomplete.projectTasksMenu.project')}
            </Typography>
            <Typography variant="h5">{currentValue.trackable.name}</Typography>
          </Div>

          <Divider />

          <Typography sx={styles.tasks} variant="heading4" component="h3">
            {t('timeTracker:createProjectAndTaskButton.autocomplete.projectTasksMenu.tasks')}
          </Typography>
          <List sx={styles.tasksList}>
            {isProjectTasksDataLoading ? (
              <Loader />
            ) : (
              projectTasksData.tasks.map(task => {
                return (
                  <ListItem sx={styles.tasksItem} key={task.id}>
                    <Button sx={styles.taskButton} type="button" variant="text" onClick={() => handleSetTask(task)}>
                      <Typography sx={styles.taskName} variant="subtitle1" align="left">
                        {task.name}
                      </Typography>
                    </Button>
                  </ListItem>
                );
              })
            )}
          </List>

          <Divider />

          <Button sx={styles.backToProjectsButton} type="button" onClick={handleBackClick} variant="text">
            <ChevronLeftOutlined sx={styles.leftArrowIcon} />
            {t('timeTracker:createProjectAndTaskButton.autocomplete.projectTasksMenu.backToProjectsButton')}
          </Button>
        </Div>
      )}
    </Popover>
  );
};

export default ProjectTasksMenu;
