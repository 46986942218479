import { FC, useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { TextField, Button, MenuItem, Typography, Alert } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Autocomplete from 'components/common/Autocomplete';
import Div from 'components/common/Div';
import GroupsAutocomplete from 'components/common/GroupsAutocomplete';
import TechnologiesAutocomplete from 'components/common/TechnologiesAutocomplete';
import UserFormSkeleton from 'components/common/UserFormSkeleton';

import { ModalContext } from 'contexts/ModalContext';

import { useGetGroupsQuery } from 'domain/group/apiSlice';
import { IGroup } from 'domain/group/types';
import { loadingGetHolidayCalendarsParameters, useGetHolidayCalendarsQuery } from 'domain/holiday/calendar/apiSlice';
import { IHolidayCalendar } from 'domain/holiday/calendar/types';
import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';
import { useUpdateUserMutation } from 'domain/user/apiSlice';
import {
  buildValidationSchema,
  initialValues,
  IUpdateUserFormData,
  formatAttributesToSubmit,
} from 'domain/user/schemas/update';
import { USER_TYPE_OPTIONS, USER_STATE_OPTIONS } from 'domain/user/service';

import { useNotifications } from 'hooks/useNotifications';

import { INamedItem } from 'types/common';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';
import { getSortParameter } from 'utils/runsack';

import styles from './styles';
import { IUpdateUserFormProps } from './types';

const renderSelectItems = (items: Array<INamedItem>) => {
  return items.map((item: INamedItem) => {
    const { id, name } = item;

    return (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    );
  });
};

const UpdateUserForm: FC<IUpdateUserFormProps> = props => {
  const { t } = useTranslation(['common', 'users']);

  const { user } = props;

  const [groupName, setGroupName] = useState<string>('');
  const [debouncedGroupName] = useDebounce(groupName, DEFAULT_DEBOUNCE_DELAY_MS);

  const [holidayCalendarName, setHolidayCalendarName] = useState<string>('');
  const [debouncedHolidayCalendarName] = useDebounce(holidayCalendarName, DEFAULT_DEBOUNCE_DELAY_MS);

  const [technologyName, setTechnologyName] = useState<string>('');
  const [debouncedTechnologyName] = useDebounce(technologyName, DEFAULT_DEBOUNCE_DELAY_MS);

  const [updateUser, { isLoading: isUserUpdateLoading }] = useUpdateUserMutation();

  const holidayCalendarsQueryParameters = loadingGetHolidayCalendarsParameters({
    nameCont: debouncedHolidayCalendarName,
    s: getSortParameter('name', 'asc'),
  });
  const {
    data: holidayCalendarsData,
    isLoading: isHolidayCalendarsDataLoading,
    isFetching: isHolidayCalendarsDataFetching,
    isError: isHolidayCalendarsDataLoadingError,
  } = useGetHolidayCalendarsQuery(holidayCalendarsQueryParameters);

  const {
    data: groupsData,
    isLoading: isGroupsDataLoading,
    isFetching: isGroupsDataFetching,
    isError: isGroupsDataLoadingError,
  } = useGetGroupsQuery({
    nameCont: debouncedGroupName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const {
    data: technologiesData,
    isLoading: isTechnologiesDataLoading,
    isFetching: isTechnologiesDataFetching,
    isError: isTechnologiesLoadingError,
  } = useGetTechnologiesQuery({
    nameCont: debouncedTechnologyName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { closeModal } = useContext(ModalContext);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateUserFormData>({
    defaultValues: initialValues(user),
    resolver: yupResolver(buildValidationSchema()),
  });

  const isError = isHolidayCalendarsDataLoadingError || isGroupsDataLoadingError || isTechnologiesLoadingError;

  const {
    field: { value: dateOfBirth, onChange: dateOfBirthChange },
  } = useController({ name: 'dateOfBirth', control });

  const {
    field: { value: userType, onChange: onUserTypeChange },
  } = useController({ name: 'userType', control });

  const {
    field: { value: group, onChange: onGroupChange },
  } = useController({ name: 'group', control });

  const {
    field: { value: calendar, onChange: onCalendarChange },
  } = useController({ name: 'calendar', control });

  const {
    field: { value: state, onChange: onUserStateChange },
  } = useController({ name: 'state', control });

  const {
    field: { value: employmentStartDate, onChange: employmentStartDateChange },
  } = useController({ name: 'employmentStartDate', control });

  const {
    field: { value: billableStartDate, onChange: billableStartDateChange },
  } = useController({ name: 'billableStartDate', control });

  const {
    field: { value: employmentEndDate, onChange: employmentEndDateChange },
  } = useController({ name: 'employmentEndDate', control });

  const {
    field: { value: technologies, onChange: onTechnologiesChange },
  } = useController({ name: 'technologies', control });

  const onSubmit = async (formData: IUpdateUserFormData) => {
    try {
      await updateUser(formatAttributesToSubmit(user.id, formData)).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const isDataLoading =
    isUserUpdateLoading || isHolidayCalendarsDataLoading || isGroupsDataLoading || isTechnologiesDataLoading;

  const handleCurrentGroupChange = (currentGroup: IGroup) => {
    if (_.isNil(currentGroup)) {
      onGroupChange(null);
      return;
    }
    onGroupChange(currentGroup);
    if (_.isNil(calendar)) {
      const calendarInGroup = holidayCalendarsData?.calendars.find(
        holidayCalendar => holidayCalendar.id === currentGroup.id,
      );
      onCalendarChange(calendarInGroup);
    }
  };

  const handleCurrentCalendarChange = (currentCalendar: IHolidayCalendar) => {
    if (_.isNil(currentCalendar)) {
      onCalendarChange(null);
      return;
    }
    onCalendarChange(currentCalendar);
  };

  const handleCurrentTechnologiesChange = (technologies: Array<ITechnology>) => {
    if (_.isEmpty(technologies)) {
      onTechnologiesChange([]);
      return;
    }
    onTechnologiesChange(technologies);
  };

  if (isDataLoading) {
    return <UserFormSkeleton />;
  }

  if (isError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div>
        <Div sx={styles.personalInformation}>
          <Typography sx={styles.groupFieldsTitle} variant="h4">
            {t('users:form.sectionTitles.personalInformation')}
          </Typography>
          <Div sx={styles.inputsLine}>
            <TextField
              required
              sx={MUISx(styles.input, styles.leftSideInput)}
              {...register('firstName')}
              label={t('users:form.firstNameLabel')}
              InputLabelProps={{ sx: styles.placeholder }}
              error={Boolean(errors.firstName)}
              helperText={buildErrorMessage(t, errors.firstName)}
              disabled={isDataLoading}
            />

            <TextField
              required
              sx={styles.input}
              {...register('lastName')}
              label={t('users:form.lastNameLabel')}
              InputLabelProps={{ sx: styles.placeholder }}
              error={Boolean(errors.lastName)}
              helperText={buildErrorMessage(t, errors.lastName)}
              disabled={isDataLoading}
            />
          </Div>

          <Div sx={styles.inputsLine}>
            <TextField
              required
              sx={MUISx(styles.input, styles.leftSideInput)}
              label={t('users:form.emailLabel')}
              InputLabelProps={{ sx: styles.placeholder }}
              {...register('email')}
              error={Boolean(errors.email)}
              helperText={buildErrorMessage(t, errors.email)}
              disabled={isDataLoading}
            />

            <TextField
              sx={styles.input}
              label={t('users:form.phoneNumberLabel')}
              type="tel"
              InputLabelProps={{ sx: styles.placeholder }}
              {...register('phoneNumber')}
              error={Boolean(errors.phoneNumber)}
              helperText={buildErrorMessage(t, errors.phoneNumber)}
              disabled={isDataLoading}
            />
          </Div>

          <Div sx={styles.inputsLine}>
            <DatePicker
              label={t('users:form.birthLabel')}
              value={dateOfBirth}
              onChange={dateOfBirthChange}
              renderInput={parameters => (
                <TextField
                  InputLabelProps={{ sx: styles.placeholder }}
                  {...parameters}
                  error={Boolean(errors.dateOfBirth)}
                  helperText={buildErrorMessage(t, errors.dateOfBirth)}
                  sx={MUISx(styles.input, styles.leftSideInput)}
                />
              )}
            />

            <TextField
              sx={styles.input}
              {...register('location')}
              label={t('users:form.physicalLocationLabel')}
              InputLabelProps={{ sx: styles.placeholder }}
              error={Boolean(errors.location)}
              helperText={buildErrorMessage(t, errors.location)}
              disabled={isDataLoading}
              inputProps={{ maxLength: 100 }}
            />
          </Div>
        </Div>

        <Div sx={styles.userSettings}>
          <Typography sx={styles.groupFieldsTitle} variant="h4">
            {t('users:form.sectionTitles.userSettings')}
          </Typography>

          <Div sx={styles.inputsLine}>
            <TextField
              required
              select
              sx={MUISx(styles.input, styles.leftSideInput)}
              error={Boolean(errors.userType)}
              helperText={buildErrorMessage(t, errors.userType)}
              label={t('users:form.typeLabel')}
              InputLabelProps={{ sx: styles.selectPlaceholder }}
              value={userType}
              onChange={onUserTypeChange}
              SelectProps={{ IconComponent: ExpandMoreOutlined }}
              disabled={isDataLoading}
            >
              {renderSelectItems(USER_TYPE_OPTIONS)}
            </TextField>

            <Div sx={styles.input}>
              <GroupsAutocomplete
                isRequired
                groups={groupsData.groups}
                currentGroup={group}
                onCurrentGroupChange={handleCurrentGroupChange}
                setGroupName={setGroupName}
                isError={Boolean(errors.technologies)}
                isGroupsFetching={isGroupsDataFetching}
                label={t('users:form.groupLabel')}
              />
            </Div>
          </Div>

          <Div sx={styles.inputsLine}>
            <DatePicker
              label={t('users:form.employmentStartDateLabel')}
              value={employmentStartDate}
              onChange={employmentStartDateChange}
              renderInput={parameters => (
                <TextField
                  required
                  InputLabelProps={{ sx: styles.placeholder }}
                  {...parameters}
                  error={Boolean(errors.employmentStartDate)}
                  helperText={buildErrorMessage(t, errors.employmentStartDate)}
                  sx={MUISx(styles.input, styles.leftSideInput)}
                />
              )}
            />

            <Div sx={styles.input}>
              <Autocomplete
                isRequired
                options={holidayCalendarsData.calendars}
                isFetching={isHolidayCalendarsDataFetching}
                isError={Boolean(errors.calendar)}
                helperText={buildErrorMessage(t, errors.calendar)}
                setName={setHolidayCalendarName}
                name={holidayCalendarName}
                onCurrentValueChange={handleCurrentCalendarChange}
                currentValue={calendar}
                label={t('users:form.workingDaysCalendar')}
              />
            </Div>
          </Div>

          <Div sx={styles.inputsLine}>
            <DatePicker
              label={t('users:form.billableStartDateLabel')}
              value={billableStartDate}
              onChange={billableStartDateChange}
              renderInput={parameters => (
                <TextField
                  required
                  InputLabelProps={{ sx: styles.placeholder }}
                  {...parameters}
                  error={Boolean(errors.billableStartDate)}
                  helperText={buildErrorMessage(t, errors.billableStartDate)}
                  sx={MUISx(styles.input, styles.leftSideInput)}
                />
              )}
            />
            <TextField
              select
              sx={styles.input}
              error={Boolean(errors.state)}
              helperText={buildErrorMessage(t, errors.state)}
              label={t('users:form.stateLabel')}
              InputLabelProps={{ sx: styles.selectPlaceholder }}
              value={state}
              onChange={onUserStateChange}
              SelectProps={{ IconComponent: ExpandMoreOutlined }}
              disabled={isDataLoading}
            >
              {renderSelectItems(USER_STATE_OPTIONS)}
            </TextField>
          </Div>

          <Div sx={styles.inputsLine}>
            <DatePicker
              label={t('users:form.endDateLabel')}
              value={employmentEndDate}
              onChange={employmentEndDateChange}
              renderInput={parameters => (
                <TextField
                  InputLabelProps={{ sx: styles.placeholder }}
                  {...parameters}
                  error={Boolean(errors.employmentEndDate)}
                  helperText={buildErrorMessage(t, errors.employmentEndDate)}
                  sx={MUISx(styles.input, styles.leftSideInput)}
                />
              )}
            />

            <Div sx={styles.input}>
              <TechnologiesAutocomplete
                technologies={technologiesData.technologies}
                currentTechnologies={technologies}
                onCurrentTechnologiesChange={handleCurrentTechnologiesChange}
                setTechnologyName={setTechnologyName}
                isError={Boolean(errors.technologies)}
                isTechnologiesFetching={isTechnologiesDataFetching}
                label={t('users:form.specializationLabel')}
              />
            </Div>
          </Div>
        </Div>
        <Div sx={styles.buttons}>
          <Button sx={MUISx(styles.button, styles.addUserButton)} fullWidth type="submit" disabled={isDataLoading}>
            {t('users:form.buttons.save')}
          </Button>
          <Button variant="text" onClick={closeModal} sx={styles.button} fullWidth type="button">
            {t('users:form.buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default UpdateUserForm;
