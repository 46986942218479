import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    paddingBottom: '24px',
  },
  resourceHeaderTitle: {
    color: 'text.gray',
  },
  dateHeaderTitle: {
    color: 'text.gray',
  },
  currentDateHeader: {
    color: 'text.blue',
  },
};

export default styles;
