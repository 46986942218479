import { FC, useRef, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import MUISx from 'mui-sx';
import { SubmitHandler, useForm, useController } from 'react-hook-form';

import { parseDurationFromStringToObject, formatDurationFromObjectToString } from 'utils/durationFormat';
import { isCreateMode, isUpdateMode } from 'utils/entityActionType';

import Div from '../Div';

import { ITimeTrackerTrackableDurationFormData, buildValidationSchema } from './schema';
import styles from './styles';
import { ITimeTrackerTrackableDurationProps } from './types';

const onSubmit: SubmitHandler<ITimeTrackerTrackableDurationFormData> = () => {};

const TimeTrackerTrackableDuration: FC<ITimeTrackerTrackableDurationProps> = props => {
  const { duration, setDuration, isDisabled, type } = props;

  const durationInputElement = useRef(null);

  const { handleSubmit, control, setValue } = useForm<ITimeTrackerTrackableDurationFormData>({
    mode: 'onChange',
    defaultValues: { durationValue: formatDurationFromObjectToString(duration) },
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: durationValue, onChange: onDurationChange },
  } = useController({ name: 'durationValue', control });

  useEffect(() => {
    const { hours, minutes } = parseDurationFromStringToObject(durationValue);
    setDuration({ ...duration, hours, minutes });
  }, [durationValue]);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [previewsValue, setPreviewsValue] = useState<string>('');

  const getParsedValue = () => {
    if (!durationValue) {
      return formatDurationFromObjectToString({ hours: 0, minutes: 0 });
    }

    const duration = parseDurationFromStringToObject(durationValue);
    return formatDurationFromObjectToString(duration);
  };

  useEffect(() => {
    const { hours, minutes } = parseDurationFromStringToObject(durationValue);
    if (!(duration.hours === hours) || !(duration.minutes === minutes)) {
      onDurationChange(formatDurationFromObjectToString(duration));
    }
  }, [duration.hours, duration.minutes]);

  const isCreate = isCreateMode(type);
  const isUpdate = isUpdateMode(type);

  const refreshField = () => {
    setIsEditing(false);
    setValue('durationValue', getParsedValue());
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      refreshField();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Div
        sx={MUISx(
          styles.root,
          { condition: isUpdate, sx: styles.rootUpdate },
          { condition: isCreate, sx: styles.rootCreate },
        )}
      >
        <TextField
          onKeyDown={handleKeyDown}
          ref={durationInputElement}
          onChange={onDurationChange}
          value={isEditing ? durationValue : getParsedValue()}
          onFocus={event => {
            setPreviewsValue(event.target.value);
            setIsEditing(true);
            setValue('durationValue', '');
          }}
          inputProps={{ maxLength: 7 }}
          onBlur={() => {
            refreshField();
            if (durationValue === '00 : 00') {
              setValue('durationValue', previewsValue);
            }
          }}
          disabled={isDisabled}
          sx={MUISx(
            { condition: isCreate, sx: styles.inputHoursCreate },
            { condition: isUpdate, sx: styles.inputUpdate },
            { condition: isUpdate, sx: styles.inputHoursUpdate },
          )}
        />
      </Div>
    </form>
  );
};

export default TimeTrackerTrackableDuration;
