import { FC, useRef, useState } from 'react';

import { Button, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';

import Div from 'components/common/Div';
import { SlotVariant } from 'components/common/SmartFilter/enums';

import ActualHours from './components/ActualHours';
import Allocation from './components/Allocation';
import ApprovalStatus from './components/ApprovalStatus';
import Billable from './components/Billable';
import Calendars from './components/Calendars';
import Description from './components/Description';
import EndDate from './components/EndDate';
import Groups from './components/Groups';
import Options from './components/Options';
import PlannedHours from './components/PlannedHours';
import Projects from './components/Projects';
import Resources from './components/Resources';
import Status from './components/Status';
import Tags from './components/Tags';
import Tasks from './components/Tasks';
import Technologies from './components/Technologies';
import Trackables from './components/Trackables';
import TrackablesWithBlank from './components/TrackablesWithBlank';
import Users from './components/Users';
import Utilization from './components/Utilization';
import VacationRequestStatus from './components/VacationRequestStatus';
import Workload from './components/Workload';
import styles from './styles';
import { ISlotProps } from './types';

const mapSlotNameToComponent = {
  [SlotVariant.project]: Projects,
  [SlotVariant.resource]: Resources,
  [SlotVariant.group]: Groups,
  [SlotVariant.utilization]: Utilization,
  [SlotVariant.workload]: Workload,
  [SlotVariant.allocation]: Allocation,
  [SlotVariant.state]: Status,
  [SlotVariant.actualHours]: ActualHours,
  [SlotVariant.plannedHours]: PlannedHours,
  [SlotVariant.description]: Description,
  [SlotVariant.trackable]: Trackables,
  [SlotVariant.technology]: Technologies,
  [SlotVariant.billable]: Billable,
  [SlotVariant.approvalState]: ApprovalStatus,
  [SlotVariant.user]: Users,
  [SlotVariant.calendar]: Calendars,
  [SlotVariant.endDate]: EndDate,
  [SlotVariant.task]: Tasks,
  [SlotVariant.tag]: Tags,
  [SlotVariant.vacationRequestState]: VacationRequestStatus,
};

const mapSlotNameToComponentWithBlank = {
  [SlotVariant.trackable]: TrackablesWithBlank,
};

const Slot: FC<ISlotProps> = props => {
  const { slot, onSlotPatch, onFilterUpdate } = props;

  const referenceButton = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const handleOptionsClose = () => {
    onFilterUpdate();
    setOpenPopover(false);
  };

  const isActiveSlot = !_.isNil(slot.value) && !_.isEmpty(slot.value);
  const isCountedSlot = Array.isArray(slot.value);
  const SlotComponent = slot.useBlank ? mapSlotNameToComponentWithBlank[slot.name] : mapSlotNameToComponent[slot.name];

  return (
    <Div sx={styles.root}>
      <Button
        sx={MUISx(styles.chipButton, { condition: isActiveSlot, sx: styles.activeChipButton })}
        variant="text"
        aria-describedby={`smart-filter-options-menu-${slot.id}`}
        onClick={() => setOpenPopover(true)}
        startIcon={slot.renderIcon()}
        ref={referenceButton}
      >
        {slot.label}
        {isCountedSlot && isActiveSlot && (
          <Div sx={styles.countFilterSelected}>
            <Typography variant="subtitle4" component="p">
              {(slot.value as []).length}
            </Typography>
          </Div>
        )}
      </Button>
      <Options id={slot.id} anchorEl={referenceButton.current} open={openPopover} onClose={handleOptionsClose}>
        <SlotComponent slot={slot} onSlotPatch={onSlotPatch} setOpenPopover={setOpenPopover} />
      </Options>
    </Div>
  );
};

export default Slot;
