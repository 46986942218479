import { FC, useMemo } from 'react';

import { Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { useGetReportVacationsQuery } from 'domain/report/vacation/apiSlice';

import { getCurrentDate } from 'utils/calendar';
import { getMonthEnName } from 'utils/dateTime';
import { getFullName } from 'utils/person';
import { getSortParameter } from 'utils/runsack';

import Info from './components/Info';
import Table from './components/Table';
import { getColoredAccessors } from './components/Table/service';
import TableBodyTotalCell from './components/TableBodyTotalCell';
import TableHeaderMonth from './components/TableHeaderMonth';
import TableHeaderTotalCell from './components/TableHeaderTotalCell';
import TableNameCell from './components/TableNameCell';
import { ReportVacationsCalendarTableColumnAccessor } from './enums';
import { getMonthsHeadersData } from './service';
import styles from './styles';
import { IVacationsCalendarProps } from './types';

const Calendar: FC<IVacationsCalendarProps> = props => {
  const { year, setYear, smartFilter } = props;
  const { t } = useTranslation('reportVacations');

  const {
    data: reportVacationsData,
    isLoading: isReportVacationsDataLoading,
    isFetching: isReportVacationsDataFetching,
    isError: isReportVacationsDataLoadingError,
  } = useGetReportVacationsQuery({ forYear: year, sort: getSortParameter('name', 'asc'), ...smartFilter });

  const YEARS = useMemo(
    () => ({
      previousYear: year - 1,
      currentYear: year,
      nextYear: year + 1,
    }),
    [year],
  );

  const resources = reportVacationsData?.resources ?? [];

  const monthsData = useMemo(() => {
    return getMonthsHeadersData(year);
  }, [year]);

  const weekendsAccessors = useMemo(() => monthsData.flatMap(monthData => monthData.weekendAccessors), [year]);
  const lastDayOfMonthAccessors = useMemo(() => monthsData.map(monthsData => monthsData.lastDayAccessor), [year]);

  const RESOURCES_LIST_COLUMNS = useMemo(() => {
    const stickyHeaders = [
      {
        Header: '',
        accessor: ReportVacationsCalendarTableColumnAccessor.resourcesEmpty,
        columns: [
          {
            Header: (
              <Typography sx={styles.resourceHeaderTitle} variant="body2">
                {t('calendar.tableHeaders.resource')}
              </Typography>
            ),
            accessor: ReportVacationsCalendarTableColumnAccessor.resourcesEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportVacationsCalendarTableColumnAccessor.resources,
                minWidth: '250px',
              },
            ],
          },
        ],
      },
      {
        Header: '',
        accessor: ReportVacationsCalendarTableColumnAccessor.carryoverPreviousEmpty,
        columns: [
          {
            Header: (
              <TableHeaderTotalCell
                title={t('calendar.tableHeaders.carryoverPrevious', { count: YEARS.previousYear })}
              />
            ),
            accessor: ReportVacationsCalendarTableColumnAccessor.carryoverPreviousEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportVacationsCalendarTableColumnAccessor.carryoverPrevious,
                width: '35px',
                minWidth: '35px',
              },
            ],
          },
        ],
      },
      {
        Header: '',
        accessor: ReportVacationsCalendarTableColumnAccessor.availableEmpty,
        columns: [
          {
            Header: <TableHeaderTotalCell title={t('calendar.tableHeaders.available', { count: YEARS.currentYear })} />,
            accessor: ReportVacationsCalendarTableColumnAccessor.availableEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportVacationsCalendarTableColumnAccessor.available,
                width: '35px',
                minWidth: '35px',
              },
            ],
          },
        ],
      },
      {
        Header: '',
        accessor: ReportVacationsCalendarTableColumnAccessor.scheduledEmpty,
        columns: [
          {
            Header: <TableHeaderTotalCell title={t('calendar.tableHeaders.scheduled', { count: YEARS.currentYear })} />,
            accessor: ReportVacationsCalendarTableColumnAccessor.scheduledEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportVacationsCalendarTableColumnAccessor.scheduled,
                width: '35px',
                minWidth: '35px',
              },
            ],
          },
        ],
      },
      {
        Header: '',
        accessor: ReportVacationsCalendarTableColumnAccessor.carryoverNextEmpty,
        columns: [
          {
            Header: (
              <TableHeaderTotalCell title={t('calendar.tableHeaders.carryoverNext', { count: YEARS.nextYear })} />
            ),
            accessor: ReportVacationsCalendarTableColumnAccessor.carryoverNextEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportVacationsCalendarTableColumnAccessor.carryoverNext,
                width: '35px',
                minWidth: '35px',
              },
            ],
          },
        ],
      },
    ];

    const monthHeaders = monthsData.map(monthItem => {
      return {
        Header: '',
        accessor: `${monthItem.title}-empty`,
        columns: [
          {
            Header: <TableHeaderMonth title={monthItem.title} lastDayOfMonth={monthItem.daysCount} year={year} />,
            accessor: monthItem.title,
            columns: Array.from({ length: monthItem.daysCount })
              .fill(0)
              .map((_, index) => {
                const date = index + 1;
                const currentDate = getCurrentDate();

                const isToday = date === currentDate && monthItem.title === getMonthEnName(new Date()).toLowerCase();

                return {
                  Header: (
                    <Typography
                      sx={MUISx(styles.dateHeaderTitle, {
                        condition: isToday,
                        sx: styles.currentDateHeader,
                      })}
                      variant="h6"
                    >
                      {date}
                    </Typography>
                  ),
                  accessor: `${monthItem.title}-${date}`,
                  minWidth: '35px',
                  width: '35px',
                };
              }),
          },
        ],
      };
    });

    return [...stickyHeaders, ...monthHeaders];
  }, [year]);

  const tableData = useMemo(() => {
    return resources.map(resource => {
      const fullName = getFullName(resource);

      return {
        [ReportVacationsCalendarTableColumnAccessor.resources]: <TableNameCell fullName={fullName} />,
        [ReportVacationsCalendarTableColumnAccessor.carryoverPrevious]: (
          <TableBodyTotalCell total={resource.vacationTotals?.carryoverPrev ?? 0} />
        ),
        [ReportVacationsCalendarTableColumnAccessor.available]: (
          <TableBodyTotalCell total={resource.vacationTotals?.available ?? 0} />
        ),
        [ReportVacationsCalendarTableColumnAccessor.scheduled]: (
          <TableBodyTotalCell total={resource.vacationTotals?.scheduled ?? 0} />
        ),
        [ReportVacationsCalendarTableColumnAccessor.carryoverNext]: (
          <TableBodyTotalCell total={resource.vacationTotals?.carryoverNext ?? 0} />
        ),
      };
    });
  }, [resources]);

  const coloredAccessors = getColoredAccessors(resources);

  if (isReportVacationsDataLoading) {
    return <Loader />;
  }

  return (
    <Div sx={styles.root}>
      <Info year={year} setYear={setYear} />
      <Table
        isLoading={isReportVacationsDataFetching}
        isError={isReportVacationsDataLoadingError}
        data={tableData}
        columns={RESOURCES_LIST_COLUMNS}
        coloredAccessors={coloredAccessors}
        weekendsAccessors={weekendsAccessors}
        lastDayOfMonthAccessors={lastDayOfMonthAccessors}
      />
    </Div>
  );
};

export default Calendar;
