import { FC, useMemo, useState } from 'react';

import { ExpandMoreOutlined, ExpandLessOutlined, CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { List, ListItem, Typography, Checkbox } from '@mui/material';

import Div from 'components/common/Div';
import TrackedTimeEntry from 'components/common/TrackedTimeEntry';
import TrackedTimeEntryProjectChip from 'components/common/TrackedTimeEntry/components/TrackedTimeEntryProjectChip';
import TrackedTimeEntryUserChip from 'components/common/TrackedTimeEntry/components/TrackedTimeEntryUserChip';
import { getReportTrackedTimeEntriesGrouping } from 'components/pages/Report/TrackedTimeEntries/service';

import { getReportTotalTrackedTimeEntriesGroupDuration } from 'domain/report/trackedTimeEntry/service';

import { usePermissions } from 'hooks/usePermissions';

import { getHoursAndMinutesStringFromMinutes } from 'utils/dateTime';

import { getEntryId } from './service';
import styles from './styles';
import { IReportTrackedTimeEntriesGroupProps } from './types';

const ReportTrackedTimeEntriesGroup: FC<IReportTrackedTimeEntriesGroupProps> = props => {
  const { title, group, reportTrackedTimeEntriesGroup, onEntrySelect, selectedEntryIds } = props;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const { isRoleFeatureAllowed } = usePermissions();

  const totalEntriesDuration = useMemo(
    () => getReportTotalTrackedTimeEntriesGroupDuration(reportTrackedTimeEntriesGroup),
    [reportTrackedTimeEntriesGroup],
  );

  const totalEntriesHoursView = useMemo(
    () => getHoursAndMinutesStringFromMinutes(totalEntriesDuration),
    [totalEntriesDuration],
  );

  const { isGroupingByUser, isGroupingByProject } = getReportTrackedTimeEntriesGrouping(group);

  const basicTrackedTimeEntry = reportTrackedTimeEntriesGroup.at(0);

  const handleListItemHeaderClick = () => setIsCollapsed(previousValue => !previousValue);

  const entryId = getEntryId(reportTrackedTimeEntriesGroup, group);
  const canSelectUsers = isGroupingByUser && isRoleFeatureAllowed('canSelectUsers');

  return (
    <ListItem sx={styles.root}>
      <Div sx={styles.headerWrapper}>
        {canSelectUsers && (
          <Checkbox
            sx={styles.checkbox}
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            checked={selectedEntryIds.includes(entryId)}
            onClick={event => event.stopPropagation()}
            onChange={() => onEntrySelect(entryId)}
          />
        )}
        <Div sx={styles.header} onClick={handleListItemHeaderClick}>
          {isCollapsed ? (
            <ExpandMoreOutlined sx={styles.collapseIcon} />
          ) : (
            <ExpandLessOutlined sx={styles.collapseIcon} />
          )}
          {isGroupingByUser && (
            <TrackedTimeEntryUserChip fullName={title} avatarSrc={basicTrackedTimeEntry?.user.avatar ?? ''} />
          )}
          {isGroupingByProject && (
            <TrackedTimeEntryProjectChip
              isBillable={basicTrackedTimeEntry.billable}
              projectName={basicTrackedTimeEntry.trackableName}
            />
          )}
          <Typography sx={styles.totalHours} variant="h4">
            {totalEntriesHoursView}
          </Typography>
        </Div>
      </Div>

      {!isCollapsed && (
        <List sx={styles.group}>
          {reportTrackedTimeEntriesGroup.map(reportTrackedTimeEntry => {
            return (
              <TrackedTimeEntry
                key={reportTrackedTimeEntry.id}
                trackedTimeEntry={reportTrackedTimeEntry}
                isGroupingByProject={isGroupingByProject}
                isGroupingByUser={isGroupingByUser}
              />
            );
          })}
        </List>
      )}
    </ListItem>
  );
};

export default ReportTrackedTimeEntriesGroup;
