import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '15px',
    height: '64px',
    padding: '12px',
    backgroundColor: 'background.white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    flexGrow: 1,
  },
  twoPeopleIconBlock: {
    width: '40px',
    height: '40px',
    marginRight: '12px',
  },
  twoPeopleIcon: {
    width: '40px',
    height: '40px',
    color: 'primary.main',
  },
  infoBlockWithoutIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
};

export default styles;
