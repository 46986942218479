import { SxStyles } from 'theme';

const styles: SxStyles = {
  chipButton: {
    padding: 0,
    color: 'text.gray',
    stroke: 'text.gray',
    marginRight: '34px',
    margin: '9px',
  },
  activeChipButton: {
    color: 'button.main',
    stroke: 'button.main',
  },
  countFilterSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    marginLeft: '8px',
    backgroundColor: 'background.lightGray',
    borderRadius: '50%',
  },
};

export default styles;
