import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    gap: '40px',
    minHeight: '100%',
  },
  treeTitle: {
    marginTop: '24px',
    marginBottom: '8px',
  },
};

export default styles;
