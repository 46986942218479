import { SxStyles } from 'theme';

const styles: SxStyles = {
  title: {
    marginBottom: '8px',
  },
  subtitle: {
    marginBottom: '40px',
  },
};

export default styles;
