import { SxStyles } from 'theme';

const styles: SxStyles = {
  userChip: {
    marginRight: '8px',
    marginBottom: '8px',
    backgroundColor: 'background.frostBlue',

    '& .MuiChip-label': {
      paddingLeft: '6px',
      paddingRight: '12px',
    },
  },
  closeChipIcon: {
    width: '16px',
    height: '16px',
  },
  chipUserName: {
    display: 'flex',
    alignItems: 'center',
    color: 'text.blue',
  },
  userAvatar: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
};

export default styles;
