import { SxStyles } from 'theme';

const styles: SxStyles = {
  title: {
    paddingLeft: '8px',
  },
  titleText: {
    textTransform: 'uppercase',
  },
  checkboxInput: {
    paddingLeft: '10px',
  },
  label: {
    color: 'text.blackLight',
  },
};

export default styles;
