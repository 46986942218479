import { SxStyles } from 'theme';

const styles: SxStyles = {
  input: {
    paddingTop: '5px',
    marginBottom: '16px',
    width: '380px',
  },
  buttonsContainer: {
    marginTop: '32px',
    display: 'flex',
    gap: '12px',
  },
  datePickerField: {
    width: '215px',
  },
  datePicker: {
    marginRight: '11px',
    margin: '5px',
    marginBottom: '16px',
  },
  inputField: {
    width: '160Ppx',
  },
};

export default styles;
