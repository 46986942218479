import { SxStyles } from 'theme';

const styles: SxStyles = {
  optionText: {
    color: 'text.primary',
    textAlign: 'left',
  },
  checkbox: {
    padding: '0',
  },
  optionButton: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '8px',
  },
  optionsBox: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflowY: 'auto',
    maxHeight: '250px',
    textTransform: 'uppercase',
  },
  tagsTitle: {
    color: 'text.gray',
    width: '100%',
    textAlign: 'left',
    paddingX: '8px',
  },
};

export default styles;
