import { FC } from 'react';

import { Typography } from '@mui/material';
import MUISx from 'mui-sx';

import Div from 'components/common/Div';

import { getReportVacationsCalendarTableLegendState } from './service';
import styles from './styles';
import { ITableLegendItem } from './types';

const TableLegendItem: FC<ITableLegendItem> = props => {
  const { title } = props;

  const { isCompleted, isApproved, isRequested } = getReportVacationsCalendarTableLegendState(title);

  return (
    <Div sx={styles.root}>
      <Div
        sx={MUISx(
          styles.colorBlock,
          { condition: isCompleted, sx: styles.completed },
          { condition: isApproved, sx: styles.approved },
          { condition: isRequested, sx: styles.requested },
        )}
      />
      <Typography variant="subtitle5">{title}</Typography>
    </Div>
  );
};

export default TableLegendItem;
