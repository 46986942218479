import { SxStyles } from 'theme';

const styles: SxStyles = {
  wrapper: {
    margin: '65px 0 16px',
  },
  rowWrapper: {
    height: '69px',
    padding: '20px 10px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'border.main',
  },
  row: {
    height: '32px',
    borderRadius: '8px',
  },
};

export default styles;
