import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px',
  },
};

export default styles;
