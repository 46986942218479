import _ from 'lodash';

import { IResourceTrackedTimeEntry } from './types';

export interface IResourceTrackedTimeEntriesGroupedByDate {
  [date: string]: Array<IResourceTrackedTimeEntry>;
}

export interface IResourceTrackedTimeEntriesGroupedByWeek {
  weekNumber: number;
  weekRange: string;
  resourceTrackedTimeEntries: Array<IResourceTrackedTimeEntry>;
}

export const getResourceTrackedTimeEntriesGroupedByDate = (
  resourceTrackedTimeEntries: Array<IResourceTrackedTimeEntry> | undefined,
): IResourceTrackedTimeEntriesGroupedByDate => {
  return _.groupBy(resourceTrackedTimeEntries, resourceTrackedTimeEntry => {
    return resourceTrackedTimeEntry.date;
  });
};

export const getResourceTrackedTimeEntriesGroupByWeek = (
  resourceTrackedTimeEntries: Array<IResourceTrackedTimeEntry> | undefined,
) => {
  if (!resourceTrackedTimeEntries) {
    return {};
  }
  return _.groupBy(resourceTrackedTimeEntries, resourceTrackedTimeEntry => {
    return `${resourceTrackedTimeEntry.weekNumber}-${resourceTrackedTimeEntry.weekYear}`;
  });
};

export const getResourceTotalTrackedTimeEntriesGroupDuration = (
  resourceTrackedTimeEntries: Array<IResourceTrackedTimeEntry>,
): number =>
  resourceTrackedTimeEntries.reduce(
    (total, resourceTrackedTimeEntry) => (total += resourceTrackedTimeEntry.duration),
    0,
  );
