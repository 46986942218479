import { SxStyles } from 'theme';

const styles: SxStyles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  range: {
    color: 'text.blackLight',
  },
  days: {
    color: 'text.lightGray',
  },
  cancel: {
    padding: 0,
    color: 'primary.red',
  },
  icon: {
    width: '16px',
    heiht: '16px',
    marginRight: '8px',
  },
  divider: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  sendRequest: {
    display: 'flex',
    alignItems: 'center',
    color: 'text.green',
  },
  approve: {
    padding: 0,
    color: 'text.green',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default styles;
