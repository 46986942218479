import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    position: 'relative',
    '& .rdrInputRanges': {
      display: 'none',
    },
  },
  button: {
    position: 'absolute',
    width: '206px',
    left: '10px',
    bottom: '20px',
  },
  dateRangePickerBlock: {
    marginRight: '32px',
  },
  dateRangePickerElementButton: {
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  titleContainer: {
    display: 'flex',
    color: 'button.main',
  },
  iconBlock: {
    marginRight: '4px',
    width: '24px',
    height: '24px',
    color: 'button.main',
  },
  text: {
    textTransform: 'uppercase',
    color: 'button.main',
  },
};

export default styles;
