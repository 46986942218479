import { FC, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, Drawer, IconButton, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import { getSelectionInfo } from 'components/pages/Report/TrackedTimeEntries/service';

import { useGetReportTrackedTimeEntriesUsersListQuery } from 'domain/report/trackedTimeEntry/usersList/apiSlice';
import { ReportTrackedTimeEntriesCopyingType } from 'domain/report/trackedTimeEntry/usersList/enums';
import { IGetReportTrackedTimeEntriesUserListItem } from 'domain/report/trackedTimeEntry/usersList/types';

import UserList from './components/UserList';
import UserTypeRadioGroup from './components/UserTypeRadioGroup';
import styles from './styles';
import { IUsersCopyProps } from './types';

const UsersCopy: FC<IUsersCopyProps> = props => {
  const {
    isOpened,
    onClose,
    reportTrackedTimeEntriesParameters,
    hasMoreItems,
    selectedEntryIds,
    reportTrackedTimeEntries,
  } = props;

  const [copyingType, setCopyingType] = useState<ReportTrackedTimeEntriesCopyingType>(
    ReportTrackedTimeEntriesCopyingType.slackName,
  );
  const [usersToCopy, setUsersToCopy] = useState<Array<IGetReportTrackedTimeEntriesUserListItem>>([]);
  const { t } = useTranslation('reportTrackedTimeEntries');

  const { isAllEntriesSelected } = getSelectionInfo(reportTrackedTimeEntries, selectedEntryIds);

  const reportTrackedTimeEntriesUsersListParameters = {
    ...reportTrackedTimeEntriesParameters,
    listType: copyingType,
    resourceIdsIn: isAllEntriesSelected && hasMoreItems ? [] : selectedEntryIds,
  };

  const {
    data: usersListData,
    isLoading: isReportTrackedTimeEntriesUsersListDataLoading,
    isError: isReportTrackedTimeEntriesUsersListDataLoadingError,
    isFetching: isReportTrackedTimeEntriesUsersListDataFetching,
  } = useGetReportTrackedTimeEntriesUsersListQuery(reportTrackedTimeEntriesUsersListParameters, { skip: !isOpened });

  useEffect(() => {
    setUsersToCopy(usersListData?.users ?? []);
  }, [usersListData]);

  if (isReportTrackedTimeEntriesUsersListDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCopyButtonClick = () => {
    copy(usersToCopy.map(user => user.value).join(', '));
  };

  const handleRemoveUserFromList = (user: IGetReportTrackedTimeEntriesUserListItem) => {
    setUsersToCopy(previousUserNames => previousUserNames.filter(previousUser => previousUser.value !== user.value));
  };

  const isLoading = isReportTrackedTimeEntriesUsersListDataLoading || isReportTrackedTimeEntriesUsersListDataFetching;

  return (
    <Drawer
      PaperProps={{
        sx: styles.paper,
      }}
      anchor="right"
      open={isOpened}
      onClose={onClose}
    >
      <Div sx={styles.header}>
        <Typography variant="h2">{t('copyEntryDrawer.title')}</Typography>
        <IconButton onClick={onClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
      </Div>
      <Div sx={styles.copyingType}>
        <Typography sx={styles.copyingTypeTitle}>{t('copyEntryDrawer.copyingType')}</Typography>
        <UserTypeRadioGroup type={copyingType} onTypeChange={setCopyingType} />
      </Div>
      <Typography sx={styles.usersTitle}>{t('copyEntryDrawer.users')}</Typography>
      <Div sx={styles.users}>
        {isLoading ? <Loader /> : <UserList users={usersToCopy} onRemove={handleRemoveUserFromList} />}
      </Div>
      <Div sx={styles.buttons}>
        <Button sx={styles.copyButton} onClick={handleCopyButtonClick}>
          {t('copyEntryDrawer.copy')}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          {t('copyEntryDrawer.cancel')}
        </Button>
      </Div>
    </Drawer>
  );
};

export default UsersCopy;
