import { SxStyles } from 'theme';

const styles: SxStyles = {
  or: {
    color: 'text.gray',
    margin: '0 8px',
  },
  dividerBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  divider: {
    backgroundColor: 'border.main',
    width: '84px',
    height: '1px',
  },
  googleButton: {
    width: '360px',
    height: '56px',
    textTransform: 'none',
    marginBottom: '20px',
    backgroundColor: 'background.white',
    border: '1px solid rgba(0,0,0,0.23)',
    '&:hover': {
      borderColor: 'background.black',
    },
    color: 'text.blackLight',
  },
  googleIcon: {
    width: '20px',
    height: '20px',
    marginRight: '12px',
    backgroundImage: 'url("/images/googleIcon.png")',
  },
};

export default styles;
