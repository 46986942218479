import { SxStyles } from 'theme';

const styles: SxStyles = {
  hoursAndUtiliz: {
    color: 'text.gray',
  },
  cellTitleBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  cellTitle: {
    marginLeft: '16px',
  },
  cellTitleIconButton: {
    width: '24px',
    height: '24px',
  },
  cellTitleIcon: {
    color: 'button.main',
  },
  resourceUsageCellHeader: {
    marginLeft: '24px',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'text.blue',
  },
  resourceUsageCellHeaderNotClickable: {
    marginLeft: '24px',
    textDecoration: 'none',
    color: 'text.blue',
  },
  projectHeader: {
    textDecoration: 'none',
  },
  billableProjectHeader: {
    color: 'text.blue',
  },
  nonBillableProjectHeader: {
    color: 'text.blackLight',
  },
  isProjectClickable: {
    cursor: 'pointer',
  },
  allocationCellNotZero: {
    color: 'text.orange',
  },
  actualNotBalanced: {
    color: 'text.darkRed',
  },
  utilization: {
    cursor: 'pointer',
  },
  withWorkload: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resourceFullName: {
    width: '250px',
    marginRight: '15px',
  },
  projectName: {
    width: '200px',
    marginRight: '15px',
  },
  hoursLinkCell: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
  },
  truncate: {
    width: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  addAllocationButton: {
    width: '40px',
    height: '40px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    marginRight: '16px',
  },
  iconPlus: {
    width: '24px',
    height: '24px',
    color: 'primary.blue',
  },
  addResourceUsageButton: {
    marginLeft: '115px',
    background: 'transparent',
    border: 'none',
    '&:hover': {
      border: 'none',
      background: 'transparent',
    },
  },
};

export default styles;
