import { FC, useMemo, useState } from 'react';

import { Lock } from '@mui/icons-material';
import { Button, List } from '@mui/material';
import { isBefore } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import {
  getResourceTrackedTimeEntriesGroupedByDate,
  getResourceTrackedTimeEntriesGroupByWeek,
} from 'domain/resource/trackedTimeEntry/service';
import { IResourceTrackedTimeEntry } from 'domain/resource/trackedTimeEntry/types';

import { getCurrentWeekNumber } from 'utils/calendar';

import TrackedTimeEntriesGroup from './components/ResourceTrackedTimeEntriesGroup';
import styles from './styles';
import { IResourceTrackedTimeEntriesGroupListProps } from './types';

const ResourceTrackedTimeEntriesGroupList: FC<IResourceTrackedTimeEntriesGroupListProps> = props => {
  const {
    resourceTrackedTimeEntries,
    handleLoadMore,
    isFetching,
    resourceId,
    onCopyTimeTrackerSettings,
    editLockDate,
  } = props;
  const [hasLoadMore, setHasLoadMore] = useState<boolean>(true);
  let entriesBeforeLockDate = 0;

  const { t } = useTranslation('timeTracker');

  const resourceTrackedTimeEntriesGroupByWeek = useMemo(
    () => getResourceTrackedTimeEntriesGroupByWeek(resourceTrackedTimeEntries),
    [resourceTrackedTimeEntries],
  );

  const resourceTrackedTimeEntriesGroupedByDate = useMemo(
    () => getResourceTrackedTimeEntriesGroupedByDate(resourceTrackedTimeEntries),
    [resourceTrackedTimeEntries],
  );

  const weeks = Object.keys(resourceTrackedTimeEntriesGroupByWeek);

  const handleLoadMoreClick = () => {
    setHasLoadMore(false);
    handleLoadMore();
  };

  const getTrackedTimeEntriesTotalHoursByWeek = (week: string) => {
    const trackedTimeEntries = resourceTrackedTimeEntriesGroupByWeek[week];
    const totalMinutes = trackedTimeEntries.reduce((total: number, trackedTimeEntry: IResourceTrackedTimeEntry) => {
      return total + trackedTimeEntry.duration;
    }, 0);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const renderTrackedTimesEntriesGroupByDate = (trackedTimeEntries: IResourceTrackedTimeEntry[]) => {
    // Remove entries with duplicated trackedTimeEntries[n].date, date is string -> YYYY-MM-DD
    const trackedTimeEntriesByDate = trackedTimeEntries.reduce((accumulator, trackedTimeEntry) => {
      if (!accumulator[trackedTimeEntry.date]) {
        accumulator[trackedTimeEntry.date] = trackedTimeEntry;
      }
      return accumulator;
    }, {} as Record<string, IResourceTrackedTimeEntry>);

    trackedTimeEntries = Object.values(trackedTimeEntriesByDate);
    return trackedTimeEntries.map(trackedTimeEntry => {
      const isBeforeLockDate = isBefore(new Date(trackedTimeEntry.date), new Date(editLockDate));
      entriesBeforeLockDate = isBeforeLockDate ? (entriesBeforeLockDate += 1) : 0;
      return (
        <>
          {isBeforeLockDate && entriesBeforeLockDate === 1 && (
            <Div sx={styles.lockDateRoot}>
              <Lock />
              <Div>{t('trackedTimeEntriesGroupList.lockedPeriod')}</Div>
            </Div>
          )}
          <TrackedTimeEntriesGroup
            key={trackedTimeEntry.date}
            groupDate={trackedTimeEntry.date}
            onCopyTimeTrackerSettings={onCopyTimeTrackerSettings}
            resourceTrackedTimeEntriesGroupByDate={resourceTrackedTimeEntriesGroupedByDate[trackedTimeEntry.date]}
            resourceId={resourceId}
            editLockDate={editLockDate}
          />
        </>
      );
    });
  };

  return (
    <>
      <List sx={styles.groups}>
        {weeks.map((week, index) => {
          return (
            <Div sx={styles.timeTrackerWeekAndDateContainer} key={`${week}_${index}`}>
              <Div sx={styles.weekTimeTrackerCount}>
                <Div sx={styles.weekContainer}>
                  <Div sx={styles.weekLabel}>
                    {t('trackedTimeEntriesGroupList.week')} {week?.split('-')[0]}
                    {week.split('-')[0] === getCurrentWeekNumber() && '(current)'}:
                  </Div>{' '}
                  <Div sx={styles.weekDateRange}>{resourceTrackedTimeEntriesGroupByWeek[week][0].weekRange}</Div>
                </Div>
                <Div sx={styles.weekContainer}>
                  <Div sx={styles.weekLabel}>
                    {`${t('trackedTimeEntriesGroupList.totalWeek')} ${week.split('-')[0]}:`}{' '}
                  </Div>
                  <Div sx={styles.weekTotal}>{getTrackedTimeEntriesTotalHoursByWeek(week)}</Div>
                </Div>
              </Div>
              {renderTrackedTimesEntriesGroupByDate(resourceTrackedTimeEntriesGroupByWeek[week])}
            </Div>
          );
        })}
      </List>

      {hasLoadMore && (
        <Div sx={styles.loadMoreButtonBlock}>
          {isFetching ? (
            <Loader />
          ) : (
            <Button onClick={handleLoadMoreClick} type="button">
              {t('trackedTimeEntriesGroupList.loadMoreButton')}
            </Button>
          )}
        </Div>
      )}
    </>
  );
};

export default ResourceTrackedTimeEntriesGroupList;
