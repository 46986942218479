import { TagTypes, provideTags } from 'shared/api/types';

import { ConditionState } from 'components/common/SmartFilter/enums';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { ICreateProjectTaskFormDataToSubmit } from './schemas/create';
import { IUpdateProjectTaskFormDataToSubmit } from './schemas/update';
import { ITask } from './types';

interface IGetProjectTasksResponse {
  tasks: Array<ITask>;
  meta: IMeta;
}

interface IProjectTaskResponse {
  task: ITask;
}

interface IGetProjectTasksQueryParameters {
  nameCont?: string;
  stateEq?: ConditionState;
  sort?: ISortParameter;
}

interface IGetProjectTasksPlainParameters extends IGetProjectTasksQueryParameters {
  page?: number;
  perPage?: number;
  projectId: number;
}

interface IGetProjectTasksParameters {
  page?: number;
  perPage?: number;
  q?: IGetProjectTasksQueryParameters;
  projectId: number;
}

interface IGetTrackedTimeEntriesTasksParameters {
  page?: number;
  perPage?: number;
  q?: IGetProjectTasksQueryParameters;
  sort?: ISortParameter;
  nameCont?: string;
  paginated?: boolean;
}

interface IProjectTaskParameters {
  projectId: number;
  id: number;
}

export const loadingGetProjectTasksParameters = (
  parameters: IGetProjectTasksPlainParameters,
): IGetProjectTasksParameters => {
  const { perPage, page, projectId, ...queryParameters } = parameters;
  return {
    perPage,
    page,
    projectId,
    q: { ...queryParameters },
  };
};

export const projectTasksApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getProjectTasks: builder.query<IGetProjectTasksResponse, IGetProjectTasksParameters>({
      query: ({ projectId, ...parameters }) => ({
        url: apiRoutes.apiV1ProjectTasksPath(projectId),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
      providesTags: response => provideTags(TagTypes.ProjectTask, response?.tasks),
    }),
    createProjectTask: builder.mutation<IProjectTaskResponse, ICreateProjectTaskFormDataToSubmit>({
      query: ({ projectId, formData }) => ({
        url: apiRoutes.apiV1ProjectTasksPath(projectId),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: ({ task }) => [{ type: TagTypes.ProjectTask, id: task.id }, TagTypes.ProjectTask],
    }),
    updateProjectTask: builder.mutation<IProjectTaskResponse, IUpdateProjectTaskFormDataToSubmit>({
      query: ({ projectId, id, formData }) => ({
        url: apiRoutes.apiV1ProjectTaskPath(projectId, id),
        method: 'PUT',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: ({ task }) => [{ type: TagTypes.ProjectTask, id: task.id }],
    }),
    deleteProjectTask: builder.mutation<void, IProjectTaskParameters>({
      query: ({ projectId, id }) => ({
        url: apiRoutes.apiV1ProjectTaskPath(projectId, id),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.ProjectTask],
    }),
    activateProjectTask: builder.mutation<IProjectTaskResponse, IProjectTaskParameters>({
      query: ({ projectId, id }) => ({
        url: apiRoutes.activateApiV1ProjectTaskPath(projectId, id),
        method: 'PUT',
      }),
      invalidatesTags: ({ task }) => [{ type: TagTypes.ProjectTask, id: task.id }],
    }),
    getTrackedTimeEntriesTasks: builder.query<IGetProjectTasksResponse, IGetTrackedTimeEntriesTasksParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ReportsTrackedTimeEntriesTasksPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: ({ tasks }) =>
        tasks
          ? [...tasks.map(({ id }) => ({ type: TagTypes.ProjectTask as const, id })), TagTypes.ProjectTask]
          : [TagTypes.ProjectTask],
    }),
  }),
});

export const {
  useActivateProjectTaskMutation,
  useCreateProjectTaskMutation,
  useDeleteProjectTaskMutation,
  useGetProjectTasksQuery,
  useGetTrackedTimeEntriesTasksQuery,
  useUpdateProjectTaskMutation,
} = projectTasksApi;
