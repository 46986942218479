import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    alignItems: 'center',
  },
  input: {
    marginTop: '5px',
    width: '430px',
    '& input:focus': {
      outline: 'none',
    },
  },
  actions: {
    marginTop: '20px',
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    height: '56px',
    minWidth: '430px',
  },
  action: {
    marginRight: '16px',
    '& .MuiTypography-root': {
      maxWidth: '100px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  descriptionErrors: {
    border: theme => `1px solid ${theme.palette.border.red}`,
    '& input::placeholder': {
      color: 'text.red',
      opacity: 1,
    },
  },
};

export default styles;
