export default {
  title: 'Hours by Projects',
  exportButton: 'Export',
  createProjectButton: 'Create Project',
  createProjectModalTitle: 'Create Project',
  createProjectForm: {
    buttons: {
      createTheProject: 'Create the Project',
      cancel: 'Cancel',
    },
    fields: {
      name: {
        label: 'Project Name',
      },
      description: {
        label: 'Project Description',
      },
      periodType: {
        label: 'Project Type',
        values: {
          onGoing: 'Ongoing',
          timebound: 'Timebound',
        },
      },
      monthlyBudget: {
        label: 'Monthly Budget',
      },
      billable: {
        label: 'Billable',
      },
      taskRequired: {
        label: 'Task Required',
      },
      groups: {
        label: 'Hierarchy',
      },
      technologies: {
        label: 'Project Stack',
      },
    },
  },
  table: {
    header: {
      calendarIconText: 'previous',
      plan: 'Plan',
      actual: 'Actual',
      allocation: 'Allocation',
      budgeted: 'Budgeted',
      titles: {
        monthToDate: 'Month-to-Date',
        monthTotal: 'Month Total',
        total: 'Projects/Total',
        resources: 'resources',
      },
      days_one: '{{ count }} day',
      days_other: '{{ count }} days',
    },
    addResourceUsageModalTitle: 'Add Resource - {{ projectName }}',
    addResourceForm: {
      selects: {
        resourceType: {
          label: 'Resource Type',
        },
        name: {
          label: 'Name',
        },
        technologies: {
          label: 'Resource Stack',
        },
      },
      buttons: {
        addResource: 'Add resource',
        cancel: 'Cancel',
      },
    },
    updateBudgetedModalTitle: 'Change Budgeted',
    updateBudgetedForm: {
      input: {
        label: 'Monthly amount',
      },
      name: {
        label: 'Project Name',
      },
      buttons: {
        changeAmount: 'Change Amount',
        cancel: 'Cancel',
      },
      description: {
        label: 'Project Description',
      },
      periodType: {
        label: 'Project Type',
        values: {
          onGoing: 'Ongoing',
          timebound: 'Timebound',
        },
      },
      billable: {
        label: 'Billable',
      },
      taskRequired: {
        label: 'Task Required',
      },
      groups: {
        label: 'Hierarchy',
      },
      technologies: {
        label: 'Project Stack',
      },
    },
    teamInfoDropDown: {
      header: 'Team',
      fields: {
        titles: {
          productDirector: 'Product Director',
          teamLead: 'Team Lead',
          projectManager: 'Project Manager',
        },
      },
    },
  },
  filters: {
    searchString: {
      placeholder: 'Search by project name, manager, user',
    },
    stateSelect: {
      active: 'Active',
      archived: 'Archived',
      all: 'All',
    },
    allocationsSelect: {
      defaultValue: 'All allocations',
    },
    projects_one: '{{ count }} Project',
    projects_other: '{{ count }} Projects',
    monthRangePlaceholder: {
      title: 'This month',
    },
  },
  vacancy: 'Vacancy',
  exportTitle: 'Hours by Projects report',
  tooltips: {
    managementTeam: 'Management Team',
    addResource: 'Add Resource',
    projectDetails: 'Project Details',
  },
  createResourceUsageAndVacancyButton: 'Allocate',
};
