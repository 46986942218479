import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '18px 24px',
    border: theme => `1px solid ${theme.palette.border.main}`,
    borderRadius: '6px',
    backgroundColor: 'background.white',
  },
  title: {
    marginRight: '24px',
  },
  buttons: {
    display: 'flex',
    position: 'relative',
  },
  clearButton: {
    marginLeft: 'auto',
  },
  createReportFilterButton: {
    left: '85%',
  },
};

export default styles;
