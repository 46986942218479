import { SxStyles } from 'theme';

const styles: SxStyles = {
  monthPicker: {
    marginBottom: '32px',
  },
  monthPickerPlaceholderButton: {
    backgroundColor: 'transparent',
  },
};

export default styles;
