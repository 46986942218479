import { FC, SyntheticEvent, useEffect, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { SubmitHandler, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/Icon';
import { INTERVAL_COMPARISONS } from 'components/common/SmartFilter/relations';
import { IMultipleSlot } from 'components/common/SmartFilter/types';

import { FilterPredicate } from 'enums/FilterPredicate';

import { buildErrorMessage } from 'utils/form';

import { initialValues, buildValidationSchema, IUtilizationFormData } from './schema';
import styles from './styles';
import { IUtilizationProps } from './types';

const onSubmit: SubmitHandler<IUtilizationFormData> = () => {};

const Utilization: FC<IUtilizationProps> = props => {
  const { slot, onSlotPatch } = props;

  const initialUtilization = _.isNil(slot.value) ? null : ((slot.value as IMultipleSlot).name as string);

  const { t } = useTranslation('smartFilter');

  const utilizationInputElement = useRef(null);

  useEffect(() => {
    utilizationInputElement.current.select();
  }, []);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<IUtilizationFormData>({
    mode: 'onChange',
    defaultValues: initialValues({ utilization: initialUtilization, comparison: slot.comparison }),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: comparison, onChange: onComparisonChange },
  } = useController({ name: 'comparison', control });

  const {
    field: { value: utilization, onChange: onUtilizationChange },
  } = useController({ name: 'utilization', control });

  useEffect(() => {
    handleSlotPatch();
  }, [comparison, utilization]);

  const handleComparisonChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    const comparison = INTERVAL_COMPARISONS.find(comparison => comparison.textSymbol === value);

    onComparisonChange(comparison);
  };

  const handleSlotPatch = () => {
    const isUtilizationValueError = Boolean(errors.utilization);
    const isUtilizationComparisonValueError = Boolean(errors.comparison);

    const utilization = getValues('utilization');

    const initialComparison = INTERVAL_COMPARISONS.find(comparison => comparison.predicate === FilterPredicate.eq);

    const utilizationValue = isUtilizationValueError ? null : utilization;
    const comparisonValue = isUtilizationComparisonValueError ? initialComparison : comparison;

    const currentUtilization =
      _.isNil(utilizationValue) || _.isEmpty(utilizationValue) ? null : { id: null, name: utilizationValue };
    onSlotPatch(slot.id, { value: currentUtilization, comparison: comparisonValue });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="smart-filter-utilization-form">
      <TextField
        fullWidth
        inputRef={utilizationInputElement}
        autoFocus
        placeholder={`${t('utilization.placeholder')}...`}
        value={utilization ?? ''}
        onChange={onUtilizationChange}
        onFocus={event => {
          event.target.select();
        }}
        error={Boolean(errors.utilization)}
        helperText={buildErrorMessage(t, errors.utilization)}
        label={t('utilization.label')}
        InputProps={{
          endAdornment: !_.isEmpty(getValues('utilization')) && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setValue('utilization', null);
                  clearErrors('utilization');
                }}
              >
                <Icon name="closeWithDarkCircle" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FormControl>
        <RadioGroup
          aria-labelledby="utilization-comparison-radio-buttons-group"
          name="utilization-comparison"
          value={comparison.textSymbol}
          onChange={handleComparisonChange}
        >
          {INTERVAL_COMPARISONS.map(comparison => {
            const label = (
              <Typography
                sx={styles.label}
                variant="subtitle1"
              >{`${comparison.textSymbol} (${comparison.description})`}</Typography>
            );

            return (
              <FormControlLabel
                sx={styles.checkboxInput}
                key={comparison.predicate}
                value={comparison.textSymbol}
                control={<Radio size="small" />}
                label={label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </form>
  );
};

export default Utilization;
