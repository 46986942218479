import { FC, useEffect, useMemo, useState } from 'react';

import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';

import Div from 'components/common/Div';
import LinkTab from 'components/common/LinkTab';
import TabPanel from 'components/common/TabPanel';

import { UserType } from 'domain/user/enums';

import AccessLevel from './components/AccessLevel';
import Migrations from './components/Migrations';
import Projects from './components/Projects';
import Schedule from './components/Schedule';
import Specialization from './components/Specialization';
import Vacation from './components/Vacation';
import { UserProfileTabName } from './enums';
import styles from './styles';
import { IProfileDetailedInfoProps, IUserProfileTabs } from './types';

const createProfileTab = (condition, labelKey, value, component, accessUserTypes) =>
  condition
    ? [
        {
          label: labelKey,
          value: value,
          accessUserTypes: accessUserTypes,
          component: component,
        },
      ]
    : [];

const ProfileDetailedInfo: FC<IProfileDetailedInfoProps> = props => {
  const { user, currentUser } = props;

  const { t } = useTranslation('userProfile');

  const [currentTabName, setCurrentTabName] = useState<UserProfileTabName>(UserProfileTabName.projects);

  const { pathname, location } = useRouter();

  useEffect(() => {
    if (location.hash) {
      setCurrentTabName(UserProfileTabName[location.hash.slice(1)]);
    }
  }, []);

  const {
    canViewProjects,
    canViewVacations,
    canViewSpecializationAndStack,
    canViewAccessLevels,
    canViewSchedule,
    canViewMigrations,
  } = user.permissions;

  const USER_PROFILE_TABS: Array<IUserProfileTabs> = useMemo(() => {
    return [
      ...createProfileTab(
        canViewProjects,
        t('control.projects'),
        UserProfileTabName.projects,
        <Projects user={user} />,
        [UserType.superAdmin, UserType.admin, UserType.user],
      ),
      ...createProfileTab(
        canViewVacations,
        t('control.vacation'),
        UserProfileTabName.vacation,
        <Vacation user={user} />,
        [UserType.superAdmin, UserType.admin, UserType.user],
      ),
      ...createProfileTab(
        canViewSpecializationAndStack,
        t('control.specializationAndStack'),
        UserProfileTabName.specialization,
        <Specialization user={user} />,
        [UserType.superAdmin, UserType.admin, UserType.user],
      ),
      ...createProfileTab(
        canViewAccessLevels,
        t('control.accessLevel'),
        UserProfileTabName.accessLevel,
        <AccessLevel user={user} />,
        [UserType.superAdmin, UserType.admin, UserType.user],
      ),
      ...createProfileTab(
        canViewSchedule,
        t('control.schedule'),
        UserProfileTabName.schedule,
        <Schedule user={user} />,
        [UserType.superAdmin, UserType.admin, UserType.user],
      ),
      ...createProfileTab(
        canViewMigrations,
        t('control.migrations'),
        UserProfileTabName.migrations,
        <Migrations user={user} />,
        [UserType.superAdmin, UserType.admin, UserType.user],
      ),
    ];
  }, [user]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: UserProfileTabName) => {
    setCurrentTabName(newValue);
  };

  const tabPanelLabel = 'detailedUserInfo';

  const availableUserProfileTabs = USER_PROFILE_TABS.filter(userProfileTab =>
    userProfileTab.accessUserTypes.includes(currentUser.userType),
  );

  return (
    <Div sx={styles.container}>
      <Div sx={styles.tabHeadersContainer}>
        <Tabs value={currentTabName} onChange={handleTabChange} aria-label="tabs">
          {availableUserProfileTabs.map(userProfileTab => {
            return (
              <LinkTab
                key={userProfileTab.value}
                label={userProfileTab.label}
                value={userProfileTab.value}
                blockLabel={tabPanelLabel}
                to={{ path: pathname, hash: userProfileTab.value }}
              />
            );
          })}
        </Tabs>
      </Div>
      {availableUserProfileTabs.map(userProfileTab => {
        return (
          <TabPanel
            key={userProfileTab.value}
            value={currentTabName}
            name={userProfileTab.value}
            tabGroupName={tabPanelLabel}
          >
            {userProfileTab.component}
          </TabPanel>
        );
      })}
    </Div>
  );
};

export default ProfileDetailedInfo;
