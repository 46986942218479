import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru, enUS } from 'date-fns/locale';
import { createStaticRanges } from 'react-date-range';

import i18n from 'locales/i18n';

import { DateRange } from 'components/common/DateRangePicker/types';

import { IWeekStartsOn } from 'types/dates';

import { getTimeRanges } from './dateTime';
import { getCurrentUserLocaleString, isRULocale } from './userLangLocale';

export const getDateRange = (startDate: Date, endDate: Date, rangeType = RangeType.custom): DateRange => {
  return {
    startDate,
    endDate,
    key: 'selection',
    rangeType,
  };
};

const currentUserLocale = getCurrentUserLocaleString();
export const getDatePickerLocale = () => (isRULocale(currentUserLocale) ? ru : enUS);

export const getCustomStaticRanges = (weekStartsOn: IWeekStartsOn) => {
  const timeRanges = getTimeRanges(weekStartsOn);

  return [
    {
      label: i18n.t('dateRangePicker:staticRangesLables.today'),
      range: () => ({
        startDate: timeRanges.startOfToday,
        endDate: timeRanges.endOfToday,
        rangeType: RangeType.day,
      }),
    },
    {
      label: i18n.t('dateRangePicker:staticRangesLables.yesterday'),
      range: () => ({
        startDate: timeRanges.startOfYesterday,
        endDate: timeRanges.endOfYesterday,
        rangeType: RangeType.day,
      }),
    },

    {
      label: i18n.t('dateRangePicker:staticRangesLables.thisWeek'),
      range: () => ({
        startDate: timeRanges.startOfWeek,
        endDate: timeRanges.endOfWeek,
        rangeType: RangeType.week,
      }),
    },
    {
      label: i18n.t('dateRangePicker:staticRangesLables.lastWeek'),
      range: () => ({
        startDate: timeRanges.startOfLastWeek,
        endDate: timeRanges.endOfLastWeek,
        rangeType: RangeType.week,
      }),
    },
    {
      label: i18n.t('dateRangePicker:staticRangesLables.thisMonth'),
      range: () => ({
        startDate: timeRanges.startOfMonth,
        endDate: timeRanges.endOfMonth,
        rangeType: RangeType.month,
      }),
    },
    {
      label: i18n.t('dateRangePicker:staticRangesLables.lastMonth'),
      range: () => ({
        startDate: timeRanges.startOfLastMonth,
        endDate: timeRanges.endOfLastMonth,
        rangeType: RangeType.month,
      }),
    },
    {
      label: i18n.t('dateRangePicker:staticRangesLables.thisYear'),
      range: () => ({
        startDate: timeRanges.startOfYear,
        endDate: timeRanges.endOfYear,
        rangeType: RangeType.year,
      }),
    },
    {
      label: i18n.t('dateRangePicker:staticRangesLables.lastYear'),
      range: () => ({
        startDate: timeRanges.startOfLastYear,
        endDate: timeRanges.endOfLastYear,
        rangeType: RangeType.year,
      }),
    },
  ];
};

export const getStaticRanges = (weekStartsOn: IWeekStartsOn): Array<StaticRange> => {
  const customStaticRanges = getCustomStaticRanges(weekStartsOn);
  return [...createStaticRanges(customStaticRanges)];
};

export const getDateAdapterWithCustomStartsOfWeek = (weekStartsOn: IWeekStartsOn = 1) => {
  return class DateAdapter extends AdapterDateFns {
    getWeekdays = (): string[] => {
      const startDay = this.startOfWeek(new Date());
      const weekdays = [];
      for (let index = 0; index < 7; index++) {
        const day = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate() + index);
        const formatter = new Intl.DateTimeFormat(this.getCurrentLocaleCode(), { weekday: 'short' });
        weekdays.push(formatter.format(day));
      }
      return weekdays;
    };

    startOfWeek = (date: Date): Date => {
      const dayOfWeek = date.getDay();
      const diff = (dayOfWeek - weekStartsOn + 7) % 7;
      return new Date(date.getFullYear(), date.getMonth(), date.getDate() - diff);
    };

    getWeekArray = (date: Date): Date[][] => {
      const firstDayOfMonth = this.startOfMonth(date).getDay();
      const diff = ((firstDayOfMonth - weekStartsOn + 7) % 7) - 1;
      const weekArray = [];
      let week = [];
      for (let weekIndex = 0; weekIndex < 6; weekIndex++) {
        for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
          const day = new Date(date.getFullYear(), date.getMonth(), weekIndex * 7 + dayIndex - diff);
          week.push(day);
        }
        weekArray.push(week);
        week = [];
      }
      return weekArray;
    };
  };
};

export enum RangeType {
  day,
  week,
  month,
  year,
  custom,
}
