import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    marginBottom: '22px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  label: {
    color: 'text.blackLight',
  },
  value: {
    width: '32px',
    marginLeft: 'auto',
    color: 'text.primary',
    textAlign: 'right',
  },
};

export default styles;
