import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IGroup } from 'domain/group/types';

const requiredValidation = 'forms:validation:required';

const dateFormatValidation = 'forms:validation:dateFormat';

export interface ICreateNewLocationFormData {
  group: IGroup;
  receiptDate: string;
  eliminationDate?: string;
}

export interface ICreateNewLocationPreparedFormData extends Omit<ICreateNewLocationFormData, 'group'> {
  groupId: number;
  userId: number;
}

export interface ICreateNewLocationFormDataToSubmit {
  userMigration: ICreateNewLocationPreparedFormData;
}

export const buildValidationFields = () => ({
  group: yup.mixed<IGroup>().required(i18n.t(requiredValidation)).nullable().default(null),
  receiptDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .required(i18n.t(requiredValidation))
    .nullable()
    .default(new Date()),
  eliminationDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .min(yup.ref('receiptDate'), i18n.t('forms:validation:startDateBeforeEndDate'))
    .nullable()
    .default(null),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (
  formData: ICreateNewLocationFormDataToSubmit,
): ICreateNewLocationFormDataToSubmit => {
  const { userMigration } = formData;
  return {
    userMigration: userMigration,
  };
};
