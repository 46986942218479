import _ from 'lodash';

import { VacationsTab } from 'enums/reportVacations';

export const isCalendarTabActive = (currentTabName: VacationsTab) => {
  return currentTabName === VacationsTab.calendar;
};

export const isVacationRequestsTabActive = (currentTabName: VacationsTab) => {
  return currentTabName === VacationsTab.vacationRequests;
};

export const getDefaultTabName = (hash: string) => {
  if (_.isEmpty(hash)) {
    return VacationsTab.calendar;
  }

  return VacationsTab[location.hash.slice(1)];
};
