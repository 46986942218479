import { TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { ICreateResourceTrackedTimeEntryFormDataToSubmit } from './schemas/create';
import { IUpdateResourceTrackedTimeEntryFormDataToSubmit } from './schemas/update';
import { IResourceTrackedTimeEntry } from './types';

interface IResourceTrackedTimeEntryResponse {
  trackedTimeEntry: IResourceTrackedTimeEntry;
}

interface IResourceTrackedTimeEntriesResponse {
  trackedTimeEntries: Array<IResourceTrackedTimeEntry>;
  todayTotalHours: number;
  currentWeekTotalHours: number;
  pastWeekTotalHours: number;
  trackedTimeEditLockDate: string | undefined;
  meta: IMeta;
}

interface IGetResourceTrackedTimeEntriesQueryParameters {
  afterDate?: string;
  beforeDate?: string;
  descriptionCont?: string;
  s?: ISortParameter;
}

interface IGetResourceTrackedTimeEntriesPlainParameters extends IGetResourceTrackedTimeEntriesQueryParameters {
  page?: number;
  perPage?: number;
  numberOfDays?: number;
  resourceId: number;
}

interface IGetResourceTrackedTimeEntriesParameters {
  page?: number;
  perPage?: number;
  numberOfDays?: number;
  q?: IGetResourceTrackedTimeEntriesQueryParameters;
  resourceId: number;
}

interface IGetResourceTrackedTimeEntryParameters {
  resourceId: number;
  id: number;
}

export const loadingGetResourceTrackedTimeEntriesParameters = (
  parameters: IGetResourceTrackedTimeEntriesPlainParameters,
): IGetResourceTrackedTimeEntriesParameters => {
  const { page, perPage, resourceId, numberOfDays, ...queryParameters } = parameters;
  return {
    perPage,
    page,
    numberOfDays,
    resourceId,
    q: { ...queryParameters },
  };
};

interface IDeleteResourceTrackedTimeEntryQueryParameters {
  id: number;
  resourceId: number;
}

export const resourceTrackedTimeEntryApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getResourceTrackedTimeEntries: builder.query<
      IResourceTrackedTimeEntriesResponse,
      IGetResourceTrackedTimeEntriesParameters
    >({
      query: ({ resourceId, ...parameters }) => {
        return {
          url: apiRoutes.apiV1ResourceTrackedTimeEntriesPath(resourceId),
          method: 'GET',
          params: parameters,
        };
      },
      providesTags: response => provideTags(TagTypes.ResourceTrackedTimeEntry, response?.trackedTimeEntries),
    }),
    getResourceTrackedTimeEntry: builder.query<
      IResourceTrackedTimeEntryResponse,
      IGetResourceTrackedTimeEntryParameters
    >({
      query: ({ id, resourceId }) => ({
        url: apiRoutes.apiV1ResourceTrackedTimeEntryPath(resourceId, id),
        method: 'GET',
      }),
      providesTags: resource => [{ type: TagTypes.ResourceTrackedTimeEntry, id: resource?.trackedTimeEntry.id }],
    }),
    createResourceTrackedTimeEntry: builder.mutation<
      IResourceTrackedTimeEntry,
      ICreateResourceTrackedTimeEntryFormDataToSubmit
    >({
      query: ({ resourceId, formData }) => ({
        url: apiRoutes.apiV1ResourceTrackedTimeEntriesPath(resourceId),
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: [TagTypes.ResourceTrackedTimeEntry],
    }),
    updateResourceTrackedTimeEntry: builder.mutation<
      IResourceTrackedTimeEntryResponse,
      IUpdateResourceTrackedTimeEntryFormDataToSubmit
    >({
      query: ({ id, resourceId, formData }) => ({
        url: apiRoutes.apiV1ResourceTrackedTimeEntryPath(resourceId, id),
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: (result, error) => {
        if (error) {
          return [TagTypes.ResourceTrackedTimeEntry];
        }

        return [{ type: TagTypes.ResourceTrackedTimeEntry, id: result?.trackedTimeEntry.id }];
      },
    }),
    deleteResourceTrackedTimeEntry: builder.mutation<void, IDeleteResourceTrackedTimeEntryQueryParameters>({
      query: ({ id, resourceId }) => ({
        url: apiRoutes.apiV1ResourceTrackedTimeEntryPath(resourceId, id),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.ResourceTrackedTimeEntry],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetResourceTrackedTimeEntriesQuery,
  useLazyGetResourceTrackedTimeEntriesQuery,
  useCreateResourceTrackedTimeEntryMutation,
  useUpdateResourceTrackedTimeEntryMutation,
  useDeleteResourceTrackedTimeEntryMutation,
} = resourceTrackedTimeEntryApi;
