import { ScalarType } from 'routes/apiRoutes';
import { provideTags, TagTypes } from 'shared/api/types';

import { ResourseVacationState } from 'domain/resource/vacation/enums';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { IReportVacationRequestItem, IVacationApprovements } from './types';

interface IGetReportVacationRequestsResponse {
  vacationApprovements: Array<IVacationApprovements>;
  meta: IMeta;
}

export interface IReportVacationRequestsSmartFilters {
  groupIdsIn?: Array<number>;
  projectIdsIn?: Array<number>;
  resourceIdsIn?: Array<number>;
  stateEq?: ResourseVacationState;
  technologyIdsIn?: Array<number>;
}

interface IGetReportVacationRequestsParameters extends IReportVacationRequestsSmartFilters {
  page?: number;
  perPage?: number;
  sort?: ISortParameter;
}

interface IGetReportVacationRequestsProjectsResponse {
  projects: Array<IReportVacationRequestItem>;
  meta: IMeta;
}

interface IGetReportVacationRequestsSpecializationsResponse {
  practices: Array<IReportVacationRequestItem>;
  meta: IMeta;
}

interface IGetReportVacationRequestsProjectsParameters {
  vacationsRequestId: ScalarType;
  parameters?: { page?: number; perPage?: number };
}
interface IGetReportVacationRequestsSpecializationsParameters {
  vacationsRequestId: ScalarType;
  parameters?: { page?: number; perPage?: number };
}

export const reportVacationRequestsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getReportVacationRequests: builder.query<IGetReportVacationRequestsResponse, IGetReportVacationRequestsParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ReportsVacationsRequestsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportVacationRequest, response?.vacationApprovements),
    }),
    getReportVacationRequestsProjects: builder.query<
      IGetReportVacationRequestsProjectsResponse,
      IGetReportVacationRequestsProjectsParameters
    >({
      query: ({ vacationsRequestId, parameters }) => {
        return {
          url: apiRoutes.apiV1ReportsVacationsRequestProjectsPath(vacationsRequestId),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportVacationRequestProjects, response?.projects),
    }),
    getReportVacationRequestsSpecializations: builder.query<
      IGetReportVacationRequestsSpecializationsResponse,
      IGetReportVacationRequestsSpecializationsParameters
    >({
      query: ({ vacationsRequestId, parameters }) => {
        return {
          url: apiRoutes.apiV1ReportsVacationsRequestSpecializationsPath(vacationsRequestId),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportVacationRequestSpecializations, response?.practices),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportVacationRequestsQuery,
  useGetReportVacationRequestsProjectsQuery,
  useGetReportVacationRequestsSpecializationsQuery,
} = reportVacationRequestsApi;
