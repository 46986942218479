import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    overflow: 'hidden',
  },
  tagsNames: {
    backgroundColor: 'border.whiteBlue',
    borderColor: 'border.whiteBlue',
    borderRadius: '32px',
    color: 'text.blue',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    padding: '1px 10px',
  },
};

export default styles;
