import { SxStyles } from 'theme';

const styles: SxStyles = {
  popover: {
    padding: '5px',
    width: '280px',
  },
  buttonContainer: {
    marginTop: '10px',
  },
  closeButton: {
    width: '100%',
    padding: '12px 16px',
    backgroundColor: 'white',
  },
};

export default styles;
