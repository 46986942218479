import { SxStyles } from 'theme';

const styles: SxStyles = {
  input: {
    width: '400px',
    marginBottom: '16px',
    textTransform: 'capitalize',
  },
  root: {
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  updateExpectedBillableTimePercentButton: {
    marginRight: '10px',
  },
  buttons: {
    marginTop: '16px',
  },
  placeholder: {
    color: 'text.gray',
  },
  helperText: {
    textTransform: 'none',
  },
};

export default styles;
