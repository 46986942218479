import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: theme => `1px solid ${theme.palette.border.main}`,
    padding: '12px 16px',
    '&:last-of-type': {
      borderBottom: 'none',
    },
    justifyContent: 'space-between',
  },
  projectInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',
  },
  projectWithTaskBlock: {
    marginRight: '10px',
    maxWidth: '30%',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '30%',
  },
  userInfoWithoutName: {
    justifyContent: 'end',
  },
  description: {
    marginRight: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '70%',
    whiteSpace: 'nowrap',
  },
  descriptionTooltip: {
    maxWidth: '800px',
    marginLeft: '30px',
  },
  billableBlock: {
    width: '24px',
    marginRight: '16px',
  },
  billableProjectSign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'text.green',
  },
  durationDateBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  duration: {
    width: '50px',
    marginRight: '16px',
  },
  tagsBlock: {
    marginLeft: 'auto',
    maxWidth: '25%',
    marginRight: '16px',
  },
};

export default styles;
