import { SxStyles } from 'theme';

const styles: SxStyles = {
  input: {
    width: '360px',
    marginBottom: '40px',
  },
  button: {
    width: '240px',
  },
  submitButton: {
    marginBottom: '15px',
  },
};

export default styles;
