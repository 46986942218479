import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    color: 'text.gray',
  },
};

export default styles;
